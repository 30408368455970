import { ChangeDetectionStrategy, Component, Injector } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GenericObject } from '@utils/models/Types';
import { TablePageEvent } from 'primeng/table';

@Component({
  selector: 'filter-base',
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilterBaseComponent {
  filterParams:any = {};
  hasExtraFilter = false;

  protected _router: Router;
  protected _activatedRoute;

  constructor(injector: Injector) {
    this._router = injector.get(Router);
    this._activatedRoute = injector.get(ActivatedRoute);
  }

  handleFilterChange(filterValues: GenericObject) {
    this._updateQueryParams(filterValues).then(() => {
      this._updateFilterParams();
    });
  }

  handlePaginationChange(event: TablePageEvent) {
    const params = Object.assign({}, this.filterParams, event);
    this._updateQueryParams(params).then(() => {
      this._updateFilterParams();
    });
  }

  applyFilter() {
    this._updateFilterParams();
  }

  private _updateQueryParams(params: GenericObject): Promise<boolean> {
    const queryParams = { ...params };
    return this._router.navigate([], { queryParams });
  }

  private _updateFilterParams(): void {
    const params = this._activatedRoute.snapshot.queryParams;
    this.filterParams = Object.assign({}, params);
    this._hasExtraFilter();
  }
  private _hasExtraFilter() {
    const keys = Object.keys(this.filterParams);
    this.hasExtraFilter = keys.some((k: any) => this.filterParams[k].length && k!== 'nameSearch' && k!== 'status' && k!== 'taskStatus');
  }
}
