import { SecurityEntity, SecurityStatus } from '@pages/distributor/components/securities-form/securities.enum';

export class SecuritiesConstant {
  static guarantorTypeList = [
    SecurityEntity.BANK_GUARANTEE,
    SecurityEntity.STANDBY_LETTER_CREDIT,
    SecurityEntity.CORPORATE_GUARANTEE,
    SecurityEntity.PERSONAL_GUARANTEE,
    SecurityEntity.CROSS_COMPANY_GUARANTEE,
    SecurityEntity.PAYMENT_GUARANTEE
  ];

  static insurerTypeList = [SecurityEntity.FDI];

  static securityEntityLabelMapping = {
    [SecurityEntity.BANK_GUARANTEE]: 'SECURITIES.BANK_GUARANTEE',
    [SecurityEntity.STANDBY_LETTER_CREDIT]: 'SECURITIES.STANDBY_LETTER_CREDIT',
    [SecurityEntity.CORPORATE_GUARANTEE]: 'SECURITIES.CORPORATE_GUARANTEE',
    [SecurityEntity.PERSONAL_GUARANTEE]: 'SECURITIES.PERSONAL_GUARANTEE',
    [SecurityEntity.CROSS_COMPANY_GUARANTEE]: 'SECURITIES.CROSS_COMPANY_GUARANTEE',
    [SecurityEntity.PAYMENT_GUARANTEE]: 'SECURITIES.PAYMENT_GUARANTEE',
    [SecurityEntity.FDI]: 'SECURITIES.FDI'
  };

  static securityStatusLabelMapping = {
    [SecurityStatus.CLOSED]: 'SECURITIES.STATUS.CLOSED',
    [SecurityStatus.ACTIVE]: 'SECURITIES.STATUS.ACTIVE',
    [SecurityStatus.AMENDED]: 'SECURITIES.STATUS.AMENDED'
  };
}
