export enum SecurityEntity {
  BANK_GUARANTEE = 'BANK_GUARANTEE',
  STANDBY_LETTER_CREDIT = 'STANDBY_LETTER_CREDIT',
  CORPORATE_GUARANTEE = 'CORPORATE_GUARANTEE',
  PERSONAL_GUARANTEE = 'PERSONAL_GUARANTEE',
  CROSS_COMPANY_GUARANTEE = 'CROSS_COMPANY_GUARANTEE',
  PAYMENT_GUARANTEE = 'PAYMENT_GUARANTEE',
  FDI = 'FDI'
}

export enum SecurityStatus {
  CLOSED = 'CLOSED',
  ACTIVE = 'ACTIVE',
  AMENDED = 'AMENDED'
}
