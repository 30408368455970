import { FilterMatchMode } from 'primeng/api';
import { NextFilterType } from '@components/organisms/next-table/enums/next-table.enum';

export class NextTableConstant {
  static readonly defaultRows = 10;
  static readonly rowsPerPage = [5, 10, 20, 30];
  static readonly defaultFilterMatchMode = FilterMatchMode.CONTAINS;
  static readonly matchModeFromFilterType = {
    [NextFilterType.MULTI_SELECTION]: FilterMatchMode.IN,
    [NextFilterType.MULTI_FIELD]: FilterMatchMode.CONTAINS
  };
  static readonly defaultColspanExpansion = 12;
}
