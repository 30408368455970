<div class="general-information">
  <next-card [hasBorder]="hasBorder">
    <data-card
      [dataObject]="data"
      [dataObjectRef]="dataRef"
      [configList]="isGroup ? DistributorGroupConstant.generalInformationConfigMapperI : DistributorConstant.generalInformationConfigPartI"
    ></data-card>
  </next-card>
  <next-card [hasBorder]="hasBorder">
    <data-card
      [dataObject]="data"
      [dataObjectRef]="dataRef"
      [configList]="isGroup ? DistributorGroupConstant.generalInformationConfigMapperII : DistributorConstant.generalInformationConfigPartII"
    ></data-card>
  </next-card>
  <next-card [hasBorder]="hasBorder">
    <data-card
      *ngIf="!isGroup"
      [dataObject]="data"
      [dataObjectRef]="dataRef"
      [configList]="isGroup ? [] : DistributorConstant.generalInformationConfigPartIII"
    ></data-card>
  </next-card>
</div>
