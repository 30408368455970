import { ChangeDetectionStrategy, Component, Injectable, Input } from '@angular/core';
import { DistributorProps } from '@pages/distributor/enums/distributor-manager.enum';
import { DGeneralInformation } from '@pages/distributor/types/distributor.type';
import { DistributorConstant } from '@pages/distributor/constant/distributor.constant';
import { DistributorGroupConstant } from '@pages/distributor/constant/distributor-group.constant';

@Component({
  selector: 'distributor-details-general-information',
  templateUrl: './distributor-details-general-information.component.html',
  styleUrl: './distributor-details-general-information.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Injectable()
export class DistributorDetailsGeneralInformationComponent {
  @Input() data: DGeneralInformation;
  @Input() dataRef: DGeneralInformation;
  @Input() isGroup: boolean = false;

  hasBorder: boolean = true;

  protected readonly DistributorProps = DistributorProps;
  protected readonly DistributorConstant = DistributorConstant;
  protected readonly DistributorGroupConstant = DistributorGroupConstant;
}
