import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { DialogSize } from '@components/organisms/next-dialog/next-dialog.enum';

@Component({
  selector: 'next-dialog',
  templateUrl: './next-dialog.component.html',
  styleUrl: './next-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NextDialogComponent {
  @Input() visible: boolean;
  @Input() titleKey: string;
  @Input() submitLabelKey: string;
  @Input() cancelLabelKey: string = 'BUTTONS.CANCEL';
  @Input() dialogSize: DialogSize = DialogSize.BIG;

  @Input() draggable = false;
  @Input() resizable = false;
  @Input() modal = true;
  @Input() hideDismiss = false;
  @Input() hideSubmitButton = false;
  @Input() hideCancelButton = false;
  @Input() isSubmitDisabled?: boolean;

  @Output() visibleChange: EventEmitter<boolean> = new EventEmitter();
  @Output() submitClick: EventEmitter<any> = new EventEmitter();
  @Output() cancelClick: EventEmitter<any> = new EventEmitter();
  @Output() dismissClick = new EventEmitter();
}
