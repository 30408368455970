import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DialogSize } from '@components/organisms/next-dialog/next-dialog.enum';
import { DistributorManagerConstant } from '@pages/distributor/constant/distributor-manager.constant';
import {
  DistributorDetailsSecuritiesSection,
  DistributorProps
} from '@pages/distributor/enums/distributor-manager.enum';
import { DSecuritiesCreditLimits } from '@pages/distributor/types/distributor.type';
import { DistributorConstant } from '@pages/distributor/constant/distributor.constant';
import { DistributorGroupConstant } from '@pages/distributor/constant/distributor-group.constant';
import { SecuritiesCreditLimitConstant } from '@pages/distributor/constant/securities-credit-limit.constant';
import { GuarantorProps, InsurerProps, SecurityType } from '@pages/distributor/enums/securities.enum';
import { DistributorGroupProps } from '@pages/distributor/enums/distributor-group.enum';
import { SCLProps } from '@pages/distributor/constant/securities-credit-limits.enum';

@Component({
  selector: 'distributor-details-securities-credit-limits',
  templateUrl: './distributor-details-securities-credit-limits.component.html',
  styleUrl: './distributor-details-securities-credit-limits.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DistributorDetailsSecuritiesCreditLimitsComponent {
  @Input() data: DSecuritiesCreditLimits;
  @Input() dataRef: DSecuritiesCreditLimits;
  @Input() isGroup: boolean = false;

  hasBackgroundColor: boolean = false;
  isHorizontalContent: boolean = true;
  hideDismiss: boolean = true;
  hideCancelButton: boolean = true;
  selectedSecurities: any;
  dialogSize: DialogSize = DialogSize.MEDIUM;
  activePopup: string = '';

  protected readonly DistributorManagerConstant = DistributorManagerConstant;
  protected readonly DistributorDetailsSecuritiesSection = DistributorDetailsSecuritiesSection;
  protected readonly DistributorProps = DistributorProps;
  protected readonly TITLE_SECURITIES_PREFIX = SecuritiesCreditLimitConstant.securitiesCreditLimitsKeyPrefix;

  viewPopup(popupName: string, object: any) {
    this.activePopup = popupName;
    this.selectedSecurities = object;
  }

  dismissModals() {
    this.activePopup = '';
  }

  protected readonly DistributorConstant = DistributorConstant;
  protected readonly DistributorGroupConstant = DistributorGroupConstant;
  protected readonly SecuritiesProps = SecurityType;
  protected readonly SecuritiesCreditLimitConstant = SecuritiesCreditLimitConstant;
  protected readonly DistributorGroupProps = DistributorGroupProps;
  protected readonly InsurerProps = InsurerProps;
  protected readonly GuarantorProps = GuarantorProps;
  protected readonly SCLProps = SCLProps;
}
