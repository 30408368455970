import { EndpointsConstant } from 'app/core/constants/endpoint.constant';

export class CountryEndpoints {
    static readonly countriesBase = 'countries';
    static readonly config = {
        LIST: {
            url: CountryEndpoints.countriesBase
        },
        RISK_RATING: {
            url: `${CountryEndpoints.countriesBase}/risk-ratings`
        },
        EDIT_COUNTRY: {
            url: `${CountryEndpoints.countriesBase}/${EndpointsConstant.genericIdParamSelector}/task`,
        },
        EDIT_COUNTRY_TASK: {
            url: `${CountryEndpoints.countriesBase}/${EndpointsConstant.genericIdParamSelector}/task/${EndpointsConstant.genericIdTaskParamSelector}`
        },
        DETAILS: {
            url: `${CountryEndpoints.countriesBase}/${EndpointsConstant.genericIdParamSelector}`,
        }
    }
}
