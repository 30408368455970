import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Icon } from '../../../shared/enums/icons.enum';
import { IconColor, NextIcon } from '@components/atoms/visual/enums/icon.enum';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'options-menu',
  templateUrl: './options-menu.component.html',
  styleUrl: './options-menu.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OptionsMenuComponent {
  @Input() options: MenuItem[];
  protected readonly NextIcon = NextIcon;
  protected readonly Icon = Icon;
  protected readonly IconColor = IconColor;
}
