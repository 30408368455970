import { TaskBoardSection, TaskBoardStatus } from '@pages/task-board/enums/task-board.enum';
import { FormSectionProps } from '@components/organisms/layout/enum/form-section.enum';
import { StatusConfigProps } from 'app/core/enums/status.enum';
import { ColorType } from 'app/shared/enums/color.enum';
import { TaskBoardEndpoints } from './task-board.endpoints.constant';
import { CountryProps } from '@pages/general-maintenance/pages/country/enums/country.enum';

export class TaskBoardConstant {
  static readonly sectionPath = {
    [TaskBoardSection.GENERAL_MAINTENANCE]: 'general-maintenance',
    [TaskBoardSection.DISTRIBUTORS]: 'distributors',
    [TaskBoardSection.VEHICLE_ORDER]: 'vehicle-order'
  };

  static readonly endpointItemBySectionApprove: any = {
    [TaskBoardSection.GENERAL_MAINTENANCE]: {
      url: TaskBoardEndpoints.config.GENERAL_MAINTENANCE.APPROVE
    }
  }

  static readonly endpointItemBySectionReject: any = {
    [TaskBoardSection.GENERAL_MAINTENANCE]: {
      url: TaskBoardEndpoints.config.GENERAL_MAINTENANCE.REJECTED,
    }
  }

  static readonly endpointItemBySectionDelete: any = {
    [TaskBoardSection.GENERAL_MAINTENANCE]: {
      url: TaskBoardEndpoints.config.GENERAL_MAINTENANCE.DELETE,
    }
  }

  static readonly sectionList = [TaskBoardSection.GENERAL_MAINTENANCE,TaskBoardSection.DISTRIBUTORS];

  static readonly sectionConfig = {
    [TaskBoardSection.GENERAL_MAINTENANCE]: {
      [FormSectionProps.ID]: TaskBoardSection.GENERAL_MAINTENANCE,
      [FormSectionProps.LABEL_KEY]: 'TASK_BOARD.GENERAL_MAINTENANCE.TITLE',
      [FormSectionProps.PATH]: TaskBoardConstant.sectionPath[TaskBoardSection.GENERAL_MAINTENANCE]
    },
    [TaskBoardSection.DISTRIBUTORS]: {
      [FormSectionProps.ID]: TaskBoardSection.DISTRIBUTORS,
      [FormSectionProps.LABEL_KEY]: 'TASK_BOARD.DISTRIBUTORS.TITLE',
      [FormSectionProps.PATH]: TaskBoardConstant.sectionPath[TaskBoardSection.DISTRIBUTORS]
    },
    [TaskBoardSection.VEHICLE_ORDER]: {
      [FormSectionProps.ID]: TaskBoardSection.VEHICLE_ORDER,
      [FormSectionProps.LABEL_KEY]: 'TASK_BOARD.VEHICLE_ORDER.TITLE',
      [FormSectionProps.PATH]: TaskBoardConstant.sectionPath[TaskBoardSection.VEHICLE_ORDER]
    }
  };

  static readonly taskStatusConfig: any = {
    [TaskBoardStatus.WAITING_APPROVAL]: {
      [StatusConfigProps.KEY]: 'TASK_BOARD.STATUS.WAITING_APPROVAL',
      [StatusConfigProps.COLOR]: ColorType.CAUTION
    },
    [TaskBoardStatus.REJECTED]: {
      [StatusConfigProps.KEY]: 'TASK_BOARD.STATUS.REJECTED',
      [StatusConfigProps.COLOR]: ColorType.DANGER
    },
    [TaskBoardStatus.PENDING]: {
      [StatusConfigProps.KEY]: 'TASK_BOARD.STATUS.PENDING',
      [StatusConfigProps.COLOR]: ColorType.PURPLE
    }
  }

  static readonly formSectionList: any[] = [
    TaskBoardSection.GENERAL_MAINTENANCE,
    TaskBoardSection.DISTRIBUTORS,
    TaskBoardSection.VEHICLE_ORDER
  ];
}
