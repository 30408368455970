import { DistributorProps, DistributorSection } from '@pages/distributor/enums/distributor-manager.enum';
import { GenericObject } from '@utils/models/Types';
import { FormSection } from '@components/organisms/layout/types/form-section.type';
import { FormSectionProps } from '@components/organisms/layout/enum/form-section.enum';
import { AddressProps } from '../../../core/enums/address.enum';
import { ContactProps } from '../../../core/enums/contact.enum';
import { SecuritiesCreditLimitConstant } from '@pages/distributor/constant/securities-credit-limit.constant';
import { SecurityType } from '@pages/distributor/enums/securities.enum';
import { ProductLinesConstant } from '@pages/distributor/constant/product-lines.constant';
import { BlanketsConstant } from '@pages/distributor/constant/blankets.constant';
import { SourceLocationMappingConstant } from '@pages/distributor/constant/source-location-mapping.constant';
import { NextFormatDatePipe } from '@pipes/common/custom-date.pipe';
import { CommonBooleanLabelPipe } from '@pipes/boolean-label.pipe';
import { CountryProps } from '@pages/general-maintenance/pages/country/enums/country.enum';
import { OperationalStatus } from 'app/core/enums/status.enum';

export class DistributorConstant {
  static formPath = {
    [DistributorSection.GENERAL_INFORMATION]: 'general-information',
    [DistributorSection.SECURITIES_AND_CREDIT_LIMITS]: 'securities-and-credit-limits',
    [DistributorSection.PRODUCT_LINES_AND_BLANKETS]: 'product-lines-and-blankets',
    [DistributorSection.SOURCE_LOCATION_MAPPING]: 'source-location-mapping',
    [DistributorSection.OBSERVATIONS]: 'observations'
  };
  static formSectionConfig: GenericObject<FormSection> = {
    [DistributorSection.GENERAL_INFORMATION]: {
      [FormSectionProps.ID]: DistributorSection.GENERAL_INFORMATION,
      [FormSectionProps.LABEL_KEY]: 'DISTRIBUTOR.MANAGER.FORM.GENERAL_INFORMATION.TITLE',
      [FormSectionProps.PATH]: DistributorConstant.formPath[DistributorSection.GENERAL_INFORMATION]
    },
    [DistributorSection.SECURITIES_AND_CREDIT_LIMITS]: {
      [FormSectionProps.ID]: DistributorSection.SECURITIES_AND_CREDIT_LIMITS,
      [FormSectionProps.LABEL_KEY]: 'DISTRIBUTOR.MANAGER.SECURITIES_AND_CREDIT_LIMITS.FORM_TITLE',
      [FormSectionProps.PATH]: DistributorConstant.formPath[DistributorSection.SECURITIES_AND_CREDIT_LIMITS]
    },
    [DistributorSection.PRODUCT_LINES_AND_BLANKETS]: {
      [FormSectionProps.ID]: DistributorSection.PRODUCT_LINES_AND_BLANKETS,
      [FormSectionProps.LABEL_KEY]: 'DISTRIBUTOR.MANAGER.FORM.PRODUCT_LINES_AND_BLANKETS.TITLE',
      [FormSectionProps.PATH]: DistributorConstant.formPath[DistributorSection.PRODUCT_LINES_AND_BLANKETS]
    },
    [DistributorSection.SOURCE_LOCATION_MAPPING]: {
      [FormSectionProps.ID]: DistributorSection.SOURCE_LOCATION_MAPPING,
      [FormSectionProps.LABEL_KEY]: 'DISTRIBUTOR.MANAGER.FORM.SOURCE_LOCATION_MAPPING.TITLE',
      [FormSectionProps.PATH]: DistributorConstant.formPath[DistributorSection.SOURCE_LOCATION_MAPPING]
    },
    [DistributorSection.OBSERVATIONS]: {
      [FormSectionProps.ID]: DistributorSection.OBSERVATIONS,
      [FormSectionProps.LABEL_KEY]: 'DISTRIBUTOR.MANAGER.FORM.OBSERVATIONS.TITLE',
      [FormSectionProps.PATH]: DistributorConstant.formPath[DistributorSection.OBSERVATIONS]
    }
  };

  static formSectionList: any[] = [
    DistributorSection.GENERAL_INFORMATION,
    DistributorSection.SECURITIES_AND_CREDIT_LIMITS,
    DistributorSection.PRODUCT_LINES_AND_BLANKETS,
    DistributorSection.SOURCE_LOCATION_MAPPING,
    DistributorSection.OBSERVATIONS
  ];

  static formMaxLength = {
    [DistributorProps.NAME]: 75,
    [DistributorProps.ID_NUMBER]: 20,
    [DistributorProps.VAT_NUMBER]: 16,
    [DistributorProps.COMPANY_ADDRESS]: 75,
    [DistributorProps.POSTAL_CODE]: 10,
    [DistributorProps.TOWN]: 35,
    [DistributorProps.CONTACT_NAME]: 35
  };

  static DistributorGeneralInformationKeyPrefix = 'DISTRIBUTOR.MANAGER.DETAILS.TABS.GENERAL_INFORMATION';

  static generalInformationConfigPartI = [
    {
      titleKey: `${DistributorConstant.DistributorGeneralInformationKeyPrefix}.COUNTRY_CODE`,
      path: [DistributorProps.COUNTRY, CountryProps.CODE]
    },
    {
      titleKey: `${DistributorConstant.DistributorGeneralInformationKeyPrefix}.COUNTRY_MEMBERSHIP`,
      path: [DistributorProps.COUNTRY, CountryProps.MEMBERSHIP]
    },
    {
      titleKey: `${DistributorConstant.DistributorGeneralInformationKeyPrefix}.CREATION_DATE`,
      path: [DistributorProps.CREATION_DATE],
      pipeList: [{ pipe: NextFormatDatePipe }]
    },
    {
      titleKey: `${DistributorConstant.DistributorGeneralInformationKeyPrefix}.FISCAL_ID`,
      path: [DistributorProps.ID_NUMBER]
    },
    {
      titleKey: `${DistributorConstant.DistributorGeneralInformationKeyPrefix}.DELETION_SUSPENSION_ORDER`,
      path: [DistributorProps.DELETION_SUSPENSION_ORDER],
      pipeList: [{ pipe: CommonBooleanLabelPipe }]
    }
  ];
  static generalInformationConfigPartII = [
    {
      titleKey: `${DistributorConstant.DistributorGeneralInformationKeyPrefix}.COMPANY_ADDRESS`,
      path: [DistributorProps.ADDRESS, AddressProps.ADDRESS]
    },
    {
      titleKey: `${DistributorConstant.DistributorGeneralInformationKeyPrefix}.POSTAL_CODE`,
      path: [DistributorProps.ADDRESS, AddressProps.POSTAL_CODE]
    },
    {
      titleKey: `${DistributorConstant.DistributorGeneralInformationKeyPrefix}.TOWN`,
      path: [DistributorProps.ADDRESS, AddressProps.TOWN]
    },
    {
      titleKey: `${DistributorConstant.DistributorGeneralInformationKeyPrefix}.CONTACT_NAME`,
      path: [DistributorProps.CONTACT, ContactProps.NAME]
    },
    {
      titleKey: `${DistributorConstant.DistributorGeneralInformationKeyPrefix}.WORK_TELEPHONE`,
      path: [DistributorProps.CONTACT, ContactProps.PHONE]
    }
  ];
  static generalInformationConfigPartIII = [
    {
      titleKey: `${DistributorConstant.DistributorGeneralInformationKeyPrefix}.ACCOUNT_STATUS`,
      path: [DistributorProps.ACCOUNT_STATUS]
    },
    {
      titleKey: `${DistributorConstant.DistributorGeneralInformationKeyPrefix}.OPERATION_STATUS`,
      path: [DistributorProps.OPERATION_STATUS]
    },
    {
      titleKey: `${DistributorConstant.DistributorGeneralInformationKeyPrefix}.RISK_CLARIFICATION`,
      path: [DistributorProps.RISK_STATUS]
    }
  ];

  static compareDataMapper = [
    {
      titleKey: `${DistributorConstant.DistributorGeneralInformationKeyPrefix}.NAME`,
      childrenKey: [DistributorSection.GENERAL_INFORMATION],
      children: [
        ...DistributorConstant.generalInformationConfigPartI,
        ...DistributorConstant.generalInformationConfigPartII,
        ...DistributorConstant.generalInformationConfigPartIII
      ]
    },
    {
      titleKey: `${SecuritiesCreditLimitConstant.securitiesCreditLimitsKeyPrefix}.NAME`,
      childrenKey: [DistributorSection.SECURITIES_AND_CREDIT_LIMITS],
      children: [
        {
          titleKey: `${SecuritiesCreditLimitConstant.securitiesCreditLimitsKeyPrefix}.TITLES.CREDIT_LIMITS`,
          children: [
            SecuritiesCreditLimitConstant.SCLDistributorCreditLimitsAmount,
            SecuritiesCreditLimitConstant.SCLDistributorGroupCreditLimitAmount,
            SecuritiesCreditLimitConstant.SCLDistributorBankGuarantee,
            SecuritiesCreditLimitConstant.SCLDistributorStandbyLetter,
            SecuritiesCreditLimitConstant.SCLDistributorFDI,
            ...SecuritiesCreditLimitConstant.SCLDistributorCreditLimits
          ]
        },
        {
          titleKey: `${SecuritiesCreditLimitConstant.securitiesCreditLimitsKeyPrefix}.TITLES.FUNDING_BANK`,
          isArray: true,
          childrenKey: [DistributorProps.FUNDING_BANK],
          children: [...SecuritiesCreditLimitConstant.SCLDistributorFundingBanks]
        },
        {
          titleKey: SecuritiesCreditLimitConstant.SCLSecuritiesGuaranteeTitle,
          childrenKey: [DistributorProps.SECURITIES, SecurityType.GUARANTORS],
          children: SecuritiesCreditLimitConstant.SCLSecurities,
          isArray: true
        },
        {
          titleKey: SecuritiesCreditLimitConstant.SCLSecuritiesInsurerTitle,
          childrenKey: [DistributorProps.SECURITIES, SecurityType.INSURERS],
          children: SecuritiesCreditLimitConstant.SCLSecurities,
          isArray: true
        }
      ]
    },
    {
      titleKey: `${ProductLinesConstant.titleKeyPrefix}.NAME`,
      childrenKey: [DistributorSection.PRODUCT_LINES_AND_BLANKETS],
      children: [ProductLinesConstant.productLinesConfigMapper, BlanketsConstant.blanketsConfigMapper]
    },
    SourceLocationMappingConstant.sourceLocationConfigMapper,
    {
      titleKey: 'DISTRIBUTOR.MANAGER.DETAILS.TABS.OBSERVATIONS.NAME',
      path: [DistributorSection.OBSERVATIONS]
    }
  ];

  static distributorStatus: any = {
    [OperationalStatus.ACTIVE]: 'ACTIVE'
   }
}
