import { DistributorProps } from '@pages/distributor/enums/distributor-manager.enum';
import { AlignRow, ColumnSize, TableField, TemplateType } from '@components/organisms/next-table/next-table.type';
import { TableFieldProps } from '@components/organisms/next-table/enums/next-table.enum';
import { GuarantorProps, InsurerProps } from '@pages/distributor/enums/securities.enum';
import { DistributorGroupProps } from '@pages/distributor/enums/distributor-group.enum';
import { CreditProps } from '@pages/distributor/enums/credit.enum';
import { FundingBankProps } from '@pages/distributor/enums/funding-bank.enum';
import { NextFormatDatePipe } from '@pipes/common/custom-date.pipe';
import { NextFormatCurrencyPipe } from '@pipes/next-format-currency.pipe';

export class SecuritiesCreditLimitConstant {
  static securitiesCreditLimitsKeyPrefix = 'DISTRIBUTOR.MANAGER.DETAILS.TABS.SECURITIES_AND_CREDIT_LIMITS';

  static SCLBaseCreditLimitsAmount = {
    path: [DistributorProps.CREDIT, CreditProps.TOTAL_GROUP_CAPITAL]
  };

  static tableListSecuritiesGuaranteeConfig: TableField[] = [
    {
      [TableFieldProps.FIELD]: GuarantorProps.NAME,
      [TableFieldProps.HEADER]: 'DISTRIBUTOR.MANAGER.DETAILS.TABS.SECURITIES_AND_CREDIT_LIMITS.FIELDS.GUARANTOR_NAME',
      [TableFieldProps.IS_SORTABLE]: true
    },
    {
      [TableFieldProps.FIELD]: GuarantorProps.STATUS,
      [TableFieldProps.HEADER]: 'DISTRIBUTOR.MANAGER.DETAILS.TABS.SECURITIES_AND_CREDIT_LIMITS.FIELDS.SECURITY_STATUS',
      [TableFieldProps.IS_SORTABLE]: true
    },
    {
      [TableFieldProps.FIELD]: GuarantorProps.CURRENCY,
      [TableFieldProps.HEADER]:
        'DISTRIBUTOR.MANAGER.DETAILS.TABS.SECURITIES_AND_CREDIT_LIMITS.FIELDS.SECURITY_CURRENCY',
      [TableFieldProps.IS_SORTABLE]: true
    },
    {
      [TableFieldProps.FIELD]: GuarantorProps.AMOUNT,
      [TableFieldProps.HEADER]: 'DISTRIBUTOR.MANAGER.DETAILS.TABS.SECURITIES_AND_CREDIT_LIMITS.FIELDS.SECURITY_AMOUNT',
      [TableFieldProps.IS_SORTABLE]: true,
      [TableFieldProps.PIPE_LIST]: [{ pipe: NextFormatCurrencyPipe }]
    },
    {
      [TableFieldProps.FIELD]: GuarantorProps.EFFECTIVE_DATE,
      [TableFieldProps.HEADER]: 'DISTRIBUTOR.MANAGER.DETAILS.TABS.SECURITIES_AND_CREDIT_LIMITS.FIELDS.EFFECTIVE_DATE',
      [TableFieldProps.IS_SORTABLE]: true,
      [TableFieldProps.PIPE_LIST]: [{ pipe: NextFormatDatePipe }]
    },
    {
      [TableFieldProps.FIELD]: GuarantorProps.EXPIRY_DATE,
      [TableFieldProps.HEADER]: 'DISTRIBUTOR.MANAGER.DETAILS.TABS.SECURITIES_AND_CREDIT_LIMITS.FIELDS.EXPIRY_DATE',
      [TableFieldProps.IS_SORTABLE]: true,
      [TableFieldProps.PIPE_LIST]: [{ pipe: NextFormatDatePipe }]
    },
    {
      [TableFieldProps.FIELD]: DistributorProps.PARAMETERS,
      [TableFieldProps.HEADER]: 'DISTRIBUTOR.MANAGER.DETAILS.TABS.SECURITIES_AND_CREDIT_LIMITS.FIELDS.VIEW',
      [TableFieldProps.ACTION]: {},
      [TableFieldProps.TEMPLATE_TYPE]: TemplateType.ACTION,
      [TableFieldProps.ALIGN]: AlignRow.CENTER,
      [TableFieldProps.WIDTH]: ColumnSize.VERY_SMALL
    }
  ];

  static tableListSecuritiesInsurerConfig: TableField[] = [
    {
      [TableFieldProps.FIELD]: InsurerProps.NAME,
      [TableFieldProps.HEADER]: 'DISTRIBUTOR.MANAGER.DETAILS.TABS.SECURITIES_AND_CREDIT_LIMITS.FIELDS.INSURER_NAME',
      [TableFieldProps.IS_SORTABLE]: true
    },
    {
      [TableFieldProps.FIELD]: InsurerProps.STATUS,
      [TableFieldProps.HEADER]: 'DISTRIBUTOR.MANAGER.DETAILS.TABS.SECURITIES_AND_CREDIT_LIMITS.FIELDS.SECURITY_STATUS',
      [TableFieldProps.IS_SORTABLE]: true
    },
    {
      [TableFieldProps.FIELD]: InsurerProps.CURRENCY,
      [TableFieldProps.HEADER]:
        'DISTRIBUTOR.MANAGER.DETAILS.TABS.SECURITIES_AND_CREDIT_LIMITS.FIELDS.SECURITY_CURRENCY',
      [TableFieldProps.IS_SORTABLE]: true
    },
    {
      [TableFieldProps.FIELD]: InsurerProps.AMOUNT,
      [TableFieldProps.HEADER]: 'DISTRIBUTOR.MANAGER.DETAILS.TABS.SECURITIES_AND_CREDIT_LIMITS.FIELDS.SECURITY_AMOUNT',
      [TableFieldProps.IS_SORTABLE]: true,
      [TableFieldProps.PIPE_LIST]: [{ pipe: NextFormatCurrencyPipe }]
    },
    {
      [TableFieldProps.FIELD]: InsurerProps.EFFECTIVE_DATE,
      [TableFieldProps.HEADER]: 'DISTRIBUTOR.MANAGER.DETAILS.TABS.SECURITIES_AND_CREDIT_LIMITS.FIELDS.EFFECTIVE_DATE',
      [TableFieldProps.IS_SORTABLE]: true,
      [TableFieldProps.PIPE_LIST]: [{ pipe: NextFormatDatePipe }]
    },
    {
      [TableFieldProps.FIELD]: InsurerProps.EXPIRY_DATE,
      [TableFieldProps.HEADER]: 'DISTRIBUTOR.MANAGER.DETAILS.TABS.SECURITIES_AND_CREDIT_LIMITS.FIELDS.EXPIRY_DATE',
      [TableFieldProps.IS_SORTABLE]: true,
      [TableFieldProps.PIPE_LIST]: [{ pipe: NextFormatDatePipe }]
    },
    {
      [TableFieldProps.FIELD]: DistributorProps.PARAMETERS,
      [TableFieldProps.HEADER]: 'DISTRIBUTOR.MANAGER.DETAILS.TABS.SECURITIES_AND_CREDIT_LIMITS.FIELDS.VIEW',
      [TableFieldProps.ACTION]: {},
      [TableFieldProps.TEMPLATE_TYPE]: TemplateType.ACTION,
      [TableFieldProps.ALIGN]: AlignRow.CENTER,
      [TableFieldProps.WIDTH]: ColumnSize.VERY_SMALL
    }
  ];

  static SCLDistributorCreditLimitsAmount = {
    titleKey: `${SecuritiesCreditLimitConstant.securitiesCreditLimitsKeyPrefix}.FIELDS.DISTRIBUTOR_CREDIT_LIMIT`,
    path: [DistributorProps.CREDIT, CreditProps.AVAILABLE_CAPITAL],
    pipeList: [
      {
        pipe: NextFormatCurrencyPipe,
        args: [{ fromValue: [DistributorProps.CREDIT, CreditProps.CURRENCY] }]
      }
    ]
  };

  static SCLDistributorGroupCreditLimitAmount = {
    titleKey: `${SecuritiesCreditLimitConstant.securitiesCreditLimitsKeyPrefix}.FIELDS.TOTAL_GROUP_CREDIT_LIMIT`,
    path: [DistributorProps.GROUP_CREDIT_LIMITS, DistributorGroupProps.CREDIT_LIMIT_AMOUNT]
  };

  static SCLDistributorBankGuarantee = {
    titleKey: `${SecuritiesCreditLimitConstant.securitiesCreditLimitsKeyPrefix}.FIELDS.BANK_GUARANTEE`,
    path: [DistributorProps.CREDIT, CreditProps.BANK_GUARANTEED_CAPITAL],
    pipeList: [
      {
        pipe: NextFormatCurrencyPipe,
        args: [{ fromValue: [DistributorProps.CREDIT, CreditProps.CURRENCY] }]
      }
    ]
  };

  static SCLDistributorStandbyLetter = {
    titleKey: `${SecuritiesCreditLimitConstant.securitiesCreditLimitsKeyPrefix}.FIELDS.STANDBY_LETTER_OF_CREDIT`,
    path: [DistributorProps.CREDIT, CreditProps.STANDBY_LETTER],
    pipeList: [
      {
        pipe: NextFormatCurrencyPipe,
        args: [{ fromValue: [DistributorProps.CREDIT, CreditProps.CURRENCY] }]
      }
    ]
  };

  static SCLDistributorFDI = {
    titleKey: `${SecuritiesCreditLimitConstant.securitiesCreditLimitsKeyPrefix}.FIELDS.FDI`,
    path: [DistributorProps.CREDIT, CreditProps.FDI],
    pipeList: [
      {
        pipe: NextFormatCurrencyPipe,
        args: [{ fromValue: [DistributorProps.CREDIT, CreditProps.CURRENCY] }]
      }
    ]
  };

  static SCLDistributorCreditLimits = [
    {
      titleKey: `${SecuritiesCreditLimitConstant.securitiesCreditLimitsKeyPrefix}.FIELDS.CURRENCY`,
      path: [DistributorProps.CREDIT, CreditProps.CURRENCY]
    },
    {
      titleKey: `${SecuritiesCreditLimitConstant.securitiesCreditLimitsKeyPrefix}.FIELDS.STATUS`,
      path: [DistributorProps.CREDIT, CreditProps.STATUS]
    },
    {
      titleKey: `${SecuritiesCreditLimitConstant.securitiesCreditLimitsKeyPrefix}.FIELDS.EFFECTIVE_DATE`,
      path: [DistributorProps.CREDIT, CreditProps.EFFECTIVE_DATE],
      pipeList: [{ pipe: NextFormatDatePipe }]
    },
    {
      titleKey: `${SecuritiesCreditLimitConstant.securitiesCreditLimitsKeyPrefix}.FIELDS.EXPIRY_DATE`,
      path: [DistributorProps.CREDIT, CreditProps.EXPIRY_DATE],
      pipeList: [{ pipe: NextFormatDatePipe }]
    },
    {
      titleKey: `${SecuritiesCreditLimitConstant.securitiesCreditLimitsKeyPrefix}.FIELDS.PACK_APPROVAL_DATE`,
      path: [DistributorProps.CREDIT, CreditProps.PACK_APPROVAL_DATE],
      pipeList: [{ pipe: NextFormatDatePipe }]
    }
  ];

  static SCLDistributorFundingBanks = [
    {
      titleKey: `${SecuritiesCreditLimitConstant.securitiesCreditLimitsKeyPrefix}.FIELDS.BANK_NAME`,
      path: [FundingBankProps.NAME]
    },
    {
      titleKey: `${SecuritiesCreditLimitConstant.securitiesCreditLimitsKeyPrefix}.FIELDS.BANK_AMOUNT`,
      path: [FundingBankProps.FUNDING_BANK_AMOUNT],
      pipeList: [
        {
          pipe: NextFormatCurrencyPipe,
          args: [{ fromValue: [DistributorProps.CREDIT, CreditProps.CURRENCY] }]
        }
      ]
    },
    {
      titleKey: `${SecuritiesCreditLimitConstant.securitiesCreditLimitsKeyPrefix}.FIELDS.BANK_PERCENTAGE`,
      path: [FundingBankProps.FUNDING_BANK_PERCENTAGE]
    }
  ];

  static SCLSecuritiesGuaranteeTitle = `${SecuritiesCreditLimitConstant.securitiesCreditLimitsKeyPrefix}.TITLES.SECURITIES_GUARANTEE`;
  static SCLSecuritiesInsurerTitle = `${SecuritiesCreditLimitConstant.securitiesCreditLimitsKeyPrefix}.TITLES.SECURITIES_INSURER`;

  static SCLSecurities = [
    // TODO split in Guarantors and Insurers ?
    {
      titleKey: `${SecuritiesCreditLimitConstant.securitiesCreditLimitsKeyPrefix}.FIELDS.GUARANTOR`,
      path: [GuarantorProps.ID]
    },
    {
      titleKey: `${SecuritiesCreditLimitConstant.securitiesCreditLimitsKeyPrefix}.FIELDS.GUARANTOR_NAME`,
      path: [GuarantorProps.NAME]
    },
    {
      titleKey: `${SecuritiesCreditLimitConstant.securitiesCreditLimitsKeyPrefix}.FIELDS.SECURITY_STATUS`,
      path: [GuarantorProps.STATUS]
    },
    {
      titleKey: `${SecuritiesCreditLimitConstant.securitiesCreditLimitsKeyPrefix}.FIELDS.SECURITY_CURRENCY`,
      path: [GuarantorProps.CURRENCY]
    },
    {
      titleKey: `${SecuritiesCreditLimitConstant.securitiesCreditLimitsKeyPrefix}.FIELDS.SECURITY_AMOUNT`,
      path: [GuarantorProps.AMOUNT]
    },
    {
      titleKey: `${SecuritiesCreditLimitConstant.securitiesCreditLimitsKeyPrefix}.FIELDS.EFFECTIVE_DATE`,
      path: [GuarantorProps.EFFECTIVE_DATE]
    },
    {
      titleKey: `${SecuritiesCreditLimitConstant.securitiesCreditLimitsKeyPrefix}.FIELDS.EXPIRY_DATE`,
      path: [GuarantorProps.EXPIRY_DATE]
    }
  ];
}
