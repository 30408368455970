import {
  AfterContentInit,
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  QueryList,
  SimpleChange,
  SimpleChanges,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { AlignRow, TableField, TemplateType } from './next-table.type';
import { FormBuilder } from '@angular/forms';
import { Table, TableFilterEvent, TablePageEvent } from 'primeng/table';
import { GenericObject } from '@utils/models/Types';
import { NextTableConstant } from '@components/organisms/next-table/constants/next-table.constant';
import { NextValueHelper } from '@utils/core/next-value.helper';
import {
  NextFilterType,
  NextTableProps,
  TableFieldProps,
  TableTestId
} from '@components/organisms/next-table/enums/next-table.enum';
import { NextNumberHelper } from '@utils/core/next-number.helper';
import { NextTableFilterProps } from '@components/organisms/next-table-filter/enums/next-table.filter.enum';
import { FilterMatchMode, PrimeTemplate } from 'primeng/api';
import { IconSizeModifier, NextIcon, IconColor } from '@components/atoms/visual/enums/icon.enum';
import { LinkButtonPosition } from '@components/atoms/buttons/components/link-button/link-button.enum';
import { Nullable } from 'primeng/ts-helpers';
import { NextArrayHelper } from '@utils/core/next-array.helper';
import { NextObjectHelper } from '@utils/core/next-object.helper';
import { DownloadService } from '@services/download.service';
import { ButtonType } from '@components/atoms/buttons/components/button/enums/buttons.enum';

@Component({
  selector: 'next-table',
  templateUrl: './next-table.component.html',
  styleUrl: './next-table.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NextTableComponent implements OnInit, OnChanges, AfterContentInit, AfterViewInit {
  @Input() title?: string;
  @Input() dataKey = 'id';
  @Input() data: any[] = [];
  @Input() dataRef: any[];
  @Input() columns: TableField[];
  @Input() filterValues: GenericObject = {};
  @Input() parsedExpandedTemplate: TemplateRef<any> | null = null;
  @Input() isPaginatorEnabled = true;
  @Input() rows = NextTableConstant.defaultRows;
  @Input() rowsPerPage: number[] = NextTableConstant.rowsPerPage;
  @Input() selectedRows?: any = [];
  @Input() isBackEndControlled = false;
  @Input() globalFilterFields: any[] = [];
  @Input() filterColumns: any[] = [];
  @Input() sortField: string = '';
  @Input() withSelection: boolean = false;
  @Input() actionIconType: NextIcon = NextIcon.EYE_OPENED;
  @Input() showDownloadButton = false;
  @Input() totalRecords = this.data.length || 0;
  @Input() sortOrder: number;
  
  @Output() selectionChange: EventEmitter<any> = new EventEmitter<any[]>();
  @Output() pageChange: EventEmitter<TablePageEvent> = new EventEmitter<TablePageEvent>();
  @Output() sortPageChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() filteredValues: EventEmitter<any> = new EventEmitter<TableFilterEvent>();
  @Output() clickViewActionEvt: EventEmitter<any> = new EventEmitter<any>();
  
  @ViewChild('primeTable') primeTable: Table;

  columnTemplateRef: Nullable<TemplateRef<any>>;
  footerTemplateRef: Nullable<TemplateRef<any>>;
  @ContentChildren(PrimeTemplate) templates: Nullable<QueryList<PrimeTemplate>>;
  columnFilterMap: GenericObject<TableField>;
  paginatorRows: number;
  paginatorFirst: number;
  align = AlignRow;
  first = 0;
  tableReady: boolean;
  
  mappedRefByDataKey: any;
  diffData: boolean;
  protected readonly NextIcon = NextIcon;
  protected readonly IconSizeModifier = IconSizeModifier;
  protected readonly IconColor = IconColor;
  protected readonly TemplateType = TemplateType;
  protected readonly TableFieldProps = TableFieldProps;
  protected readonly TableTestId = TableTestId;
  protected readonly LinkButtonPosition = LinkButtonPosition;
  protected readonly ButtonType = ButtonType;

  constructor(private _fb: FormBuilder,
              private _downloadService: DownloadService) {}

  ngOnInit() {
    this._setTableConfig();
    this._initializeFilterConfig();
  }

  ngOnChanges(changes: SimpleChanges) {
    const filterValues = NextObjectHelper.getPropertyFromObject(changes, ['filterValues']);
    if (filterValues) {
      if (this.primeTable) {
        this._validateFilterChanges(filterValues);
      }
    }
  }

  ngAfterContentInit(): void {
    (this.templates as QueryList<PrimeTemplate>).forEach(item => {
      switch (item.getType()) {
        case 'columnTemplateRef':
          this.columnTemplateRef = item.template;
          break;
        case 'footerTemplateRef':
          this.footerTemplateRef = item.template;
          break;
      }
    });
  }

  ngAfterViewInit() {
    if (this.primeTable) {
      this._applyTableFilters();
      this.tableReady = true;
    }
  }

  handlePageChange(event: TablePageEvent) {
    this.pageChange.emit(event);
  }

  handleSelectionChange(event: any) {
    if (this.withSelection) {
      this.selectionChange.emit(event);
    }
  }

  /*
   * If there is a change in filterValues we update the table filter and reset the paginator
   * If the change is only from pagination we update only the paginator. This is because when we change the table filter
   * pagination normally will have a different configuration
   * */
  private _validateFilterChanges(filterValues: SimpleChange) {
    const previousValue = { ...filterValues.previousValue, first: undefined, rows: undefined };
    const currentValue = { ...filterValues.currentValue, first: undefined, rows: undefined };
    if (this.isPaginatorEnabled && NextObjectHelper.isEqual(previousValue, currentValue)) {
      this._applyPaginator();
    } else {
      this._applyTableFilters();
    }
  }

  private _applyFilterByColumns() {
    for (const columnFilterMapKey in this.columnFilterMap) {
      const columnConfig = this.columnFilterMap[columnFilterMapKey];
      switch (columnConfig[TableFieldProps.FILTER_TYPE]) {
        case NextFilterType.DATE:
          const filterDateFrom = this.filterValues[columnFilterMapKey + 'From'];
          const filterDateTo = this.filterValues[columnFilterMapKey + 'To'];      
          if (filterDateFrom && !filterDateTo) {
            this.primeTable.filter(filterDateFrom, columnFilterMapKey, FilterMatchMode.AFTER);
          } else if (filterDateTo && !filterDateFrom) {
            this.primeTable.filter(filterDateTo, columnFilterMapKey, FilterMatchMode.BEFORE);
          } else if (filterDateFrom && filterDateTo) {
            this.primeTable.filter([filterDateFrom, filterDateTo], columnFilterMapKey, FilterMatchMode.BETWEEN);
          } else {
            this._clearColumnFilter(columnFilterMapKey);
          }
          break;
        case NextFilterType.MULTI_FIELD:
        case NextFilterType.MULTI_SELECTION:
        case undefined:
        default:
          const filterValue = this.filterValues[columnFilterMapKey];
          if (filterValue) {
            const matchMode = NextTableConstant.matchModeFromFilterType[columnConfig[TableFieldProps.FILTER_TYPE]!];
            this.primeTable.filter(
              filterValue,
              columnFilterMapKey,
              NextValueHelper.defaultValue(matchMode, NextTableConstant.defaultFilterMatchMode)
            );
          } else {
            this._clearColumnFilter(columnFilterMapKey);
          }
          break;
      }
    }
  }

  private _applyTableFilters() {
    if (NextValueHelper.isValueDefined(this.filterValues)) {
      this._applyGlobalFilter();
      this._applyFilterByColumns();
      if (this.isPaginatorEnabled) {
        this._applyPaginator();
      }
    }
  }

  private _clearColumnFilter(columnFilterMapKey: string) {
    this.primeTable.filter([], columnFilterMapKey, FilterMatchMode.CONTAINS);
  }

  private _setTableConfig() {
    if (NextValueHelper.isValueDefined(this.dataRef)) {
      this.mappedRefByDataKey = NextArrayHelper.indexArrayByProperty(this.dataRef, this.dataKey);
      this.diffData = true;
    }
  }

  private _applyPaginator() {
    this.paginatorRows = NextValueHelper.defaultValue(
      NextNumberHelper.convertToNumber(this.filterValues[NextTableProps.PAGINATOR_ROWS]),
      this.rows
    );
    this.paginatorFirst = NextValueHelper.defaultValue(
      NextNumberHelper.convertToNumber(this.filterValues[NextTableProps.PAGINATOR_FIRST]),
      this.first
    );
  }

  private _initializeFilterConfig() {
    this.columnFilterMap = this.columns.reduce((acc: GenericObject, currentValue) => {
      if (currentValue.isFilter) {
        const field = currentValue.filterField ? currentValue.filterField : currentValue.field;
        acc[field] = currentValue;
      }
      return acc;
    }, {});
  }

  private _applyGlobalFilter() {
    const searchValue = this.filterValues[NextTableFilterProps.NAME_SEARCH];
    this.primeTable.filterGlobal(searchValue, 'contains');
  }

  onFilter(event: any) {
    this.filteredValues.emit(event);
    this._downloadService.setData(event.filteredValue);
  }

  downloadExcel() {
    this._downloadService.generateExcel();
  }

  goToTaskBoard(event: any) {
    console.log(' go taskboard', event);
  }
}
