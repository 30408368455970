import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DistributorProps } from '@pages/distributor/enums/distributor-manager.enum';
import placeholder from 'lodash/fp/placeholder';
import { DObservations } from '@pages/distributor/types/distributor.type';

@Component({
  selector: 'distributor-observations',
  templateUrl: './distributor-observations.component.html',
  styleUrl: './distributor-observations.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DistributorObservationsComponent implements OnInit {
  @Input() data: DObservations;
  @Input() dataRef: DObservations;
  @Input() formGroup?: FormGroup<any>;

  isEdition: boolean;
  observationData: any;
  hasBorder: boolean = true;
  hasNoneBorderRadius: boolean = true;
  commentsMaxLength = 1000;

  ngOnInit(): void {
    this._setConfigData();
  }

  private _setConfigData() {
    if (this.formGroup) {
      this.observationData = this.formGroup.getRawValue();
      this.isEdition = true;
    } else {
      this.observationData = this.data;
    }
  }

  protected readonly DistributorProps = DistributorProps;
  protected readonly placeholder = placeholder;
}
