import { CreditLimitStatus, OperationalStatus } from '../enums/status.enum';
import { GenericObject } from '@utils/models/Types';
import { BlanketStatus } from '@pages/distributor/enums/product-lines.enum';

export class StatusConstant {
  static readonly creditLimitStatusList = [
    CreditLimitStatus.ACTIVATED,
    CreditLimitStatus.DEACTIVATED,
    CreditLimitStatus.SUSPENDED
  ];
  static readonly creditLimitStatusLabelMapping: GenericObject = {
    [CreditLimitStatus.ACTIVATED]: 'STATUS.CREDIT_LIMIT.ACTIVATED',
    [CreditLimitStatus.DEACTIVATED]: 'STATUS.CREDIT_LIMIT.DEACTIVATED',
    [CreditLimitStatus.SUSPENDED]: 'STATUS.CREDIT_LIMIT.SUSPENDED'
  };

  static readonly OperationalStatusList = [
    OperationalStatus.ACTIVE,
    OperationalStatus.PENDING,
    OperationalStatus.CLOSED
  ];

  static readonly productLineStatusLabelMapping: GenericObject = {
    [OperationalStatus.ACTIVE]: 'STATUS.PRODUCT_LINE_STATUS.ACTIVE',
    [OperationalStatus.PENDING]: 'STATUS.PRODUCT_LINE_STATUS.PENDING',
    [OperationalStatus.CLOSED]: 'STATUS.PRODUCT_LINE_STATUS.CLOSED',
    [OperationalStatus.INCOMPLETE]: 'STATUS.PRODUCT_LINE_STATUS.INCOMPLETE'
  };

  static readonly financialBlanketsStatusLabelMapping = {
    [BlanketStatus.ACTIVE]: 'STATUS.BLANKETS.ACTIVE',
    [BlanketStatus.SUSPENDED]: 'STATUS.BLANKETS.SUSPENDED'
  };
}
