import { BlanketStatus } from '@pages/distributor/enums/product-lines.enum';
import { ColorType } from '../../../shared/enums/color.enum';
import { DistributorProps } from '@pages/distributor/enums/distributor-manager.enum';
import { AlignRow, ColumnSize, TableField, TemplateType } from '@components/organisms/next-table/next-table.type';
import { TableFieldProps } from '@components/organisms/next-table/enums/next-table.enum';
import { BlanketsProps } from '@pages/distributor/enums/blankets.enum';
import { NextFormatCurrencyPipe } from '@pipes/next-format-currency.pipe';
import { StatusConfigProps } from '../../../core/enums/status.enum';

export class BlanketsConstant {
  static titleKeyPrefix = 'DISTRIBUTOR.MANAGER.DETAILS.TABS.PRODUCT_LINES_AND_BLANKETS';

  static blanketStatusConfig: any = {
    [BlanketStatus.ACTIVE]: {
      [StatusConfigProps.KEY]: 'STATUS.BLANKETS.ACTIVE',
      [StatusConfigProps.COLOR]: ColorType.SUCCESS
    },
    [BlanketStatus.SUSPENDED]: {
      [StatusConfigProps.KEY]: 'STATUS.BLANKETS.SUSPENDED',
      [StatusConfigProps.COLOR]: ColorType.DANGER
    }
  };

  static blanketsTableConfig: TableField[] = [
    {
      [TableFieldProps.FIELD]: BlanketsProps.PRODUCT_LINE_ID,
      [TableFieldProps.HEADER]: `${BlanketsConstant.titleKeyPrefix}.FIELDS.PRODUCT_LINE`,
      [TableFieldProps.IS_SORTABLE]: true
    },
    {
      [TableFieldProps.FIELD]: BlanketsProps.CAPITAL,
      [TableFieldProps.HEADER]: `${BlanketsConstant.titleKeyPrefix}.FIELDS.BLANKET_AMOUNT`,
      [TableFieldProps.IS_SORTABLE]: true,
      [TableFieldProps.WIDTH]: ColumnSize.LARGE,
      [TableFieldProps.PIPE_LIST]: [{ pipe: NextFormatCurrencyPipe }]
    },
    {
      [TableFieldProps.FIELD]: BlanketsProps.STATUS,
      [TableFieldProps.HEADER]: `${BlanketsConstant.titleKeyPrefix}.FIELDS.STATUS`,
      [TableFieldProps.IS_SORTABLE]: true,
      [TableFieldProps.TEMPLATE_TYPE]: TemplateType.STATUS,
      [TableFieldProps.STATUS_MAP]: BlanketsConstant.blanketStatusConfig,
      [TableFieldProps.WIDTH]: ColumnSize.SMALL
    }
  ];

  static blanketsTableConfigWithView: TableField[] = [
    ...BlanketsConstant.blanketsTableConfig,
    {
      [TableFieldProps.FIELD]: DistributorProps.PARAMETERS,
      [TableFieldProps.HEADER]: `${BlanketsConstant.titleKeyPrefix}.FIELDS.VIEW`,
      [TableFieldProps.ACTION]: {},
      [TableFieldProps.TEMPLATE_TYPE]: TemplateType.ACTION,
      [TableFieldProps.ALIGN]: AlignRow.CENTER,
      [TableFieldProps.WIDTH]: ColumnSize.VERY_SMALL
    }
  ];

  static blanketsTableConfigWithActions: TableField[] = [
    ...BlanketsConstant.blanketsTableConfig,
    {
      [TableFieldProps.FIELD]: TableFieldProps.MENU_OPTION_LIST,
      [TableFieldProps.HEADER]: 'DISTRIBUTOR.MANAGER.FIELDS.OPTIONS',
      [TableFieldProps.TEMPLATE_TYPE]: TemplateType.MENU_ACTION,
      [TableFieldProps.ALIGN]: AlignRow.CENTER,
      [TableFieldProps.WIDTH]: ColumnSize.VERY_SMALL
    }
  ];

  static blanketsChildrenMapper = [
    { titleKey: `${BlanketsConstant.titleKeyPrefix}.FIELDS.NAME`, path: [BlanketsProps.PRODUCT_LINE_ID] },
    { titleKey: `${BlanketsConstant.titleKeyPrefix}.FIELDS.BLANKET_AMOUNT`, path: [BlanketsProps.CAPITAL] },
    {
      titleKey: `${BlanketsConstant.titleKeyPrefix}.FIELDS.BLANKET_STATUS`,
      path: [BlanketsProps.STATUS]
    }
  ];

  static blanketsConfigMapper = {
    titleKey: `${BlanketsConstant.titleKeyPrefix}.TITLES.BLANKETS`,
    childrenKey: [DistributorProps.BLANKETS],
    isArray: true,
    children: BlanketsConstant.blanketsChildrenMapper
  };
}
