import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NextDialogComponent } from '@components/organisms/next-dialog/next-dialog.component';
import { ButtonsMoleculeModule } from '@components/molecules/buttons-molecule/buttons-molecule.module';
import { DialogModule } from 'primeng/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonsModule } from '@components/atoms/buttons/buttons.module';

const exportedElements = [NextDialogComponent];

@NgModule({
  declarations: [...exportedElements],
  exports: [...exportedElements],
  imports: [CommonModule, ButtonsMoleculeModule, DialogModule, TranslateModule, ButtonsModule]
})
export class NextDialogModule {}
