<div class="source-location-mapping">
  <div *ngIf="isEdition" class="source-location-mapping__table-control">
    <next-button
      [labelKey]="LABEL_KEY_PREFIX + '.ADD_NEW_SOURCE'"
      (clickEvent)="enablePopup(DistributorSection.SOURCE_LOCATION_MAPPING, {})">
    </next-button>
  </div>
  <next-card [hasBorder]="!isEdition" [hasNoneBorderRadius]="hasNoneBorderRadius">
    <next-table
      [dataKey]="SourceLocationProps.SOURCE_LOCATION_CODE"
      [data]="entityList"
      [dataRef]="dataRef"
      [columns]="isEdition ? SourceLocationMappingConstant.tableListSourceLocationConfigWithActions : SourceLocationMappingConstant.tableListSourceLocationConfig"
    ></next-table>
  </next-card>
  <ng-container [ngSwitch]="activePopup">

    <source-location-mapping-popup
      *ngSwitchCase="DistributorSection.SOURCE_LOCATION_MAPPING"
      [isOpen]="true"
      [isEdition]="isEdition && !forceViewMode"
      [entity]="currentEntity"
      (submitEntity)="handleAdd($event)"
      (cancel)="dismissPopup()">
    </source-location-mapping-popup>
  </ng-container>
</div>
