import { Directive, ElementRef, Input, OnInit } from '@angular/core';

@Directive({
  selector: '[compareData]',
  standalone: true
})
export class CompareDataDirective implements OnInit {
  @Input() compareData: any;
  @Input() refData: any;

  constructor(private _el: ElementRef) {}

  ngOnInit() {
    if (this.compareData !== this.refData) {
      this._el.nativeElement.setAttribute('class', 'next-table__updated-column');
    }
  }
}
