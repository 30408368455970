export enum CreditProps {
  CURRENCY = 'currency',
  STATUS = 'status',
  CAPITAL = 'capital',
  AVAILABLE_CAPITAL = 'availableCapital',
  TOTAL_GROUP_CAPITAL = 'totalGroupCapital',
  BANK_GUARANTEED_CAPITAL = 'bankGuaranteedCapital',
  STANDBY_LETTER = 'standbyLetter',
  FDI = 'fdi',
  EFFECTIVE_DATE = 'effectiveDate',
  EXPIRY_DATE = 'expiryDate',
  PACK_APPROVAL_DATE = 'packApprovalDate'
}
