import { basicDropDownListItem } from '@testing/mocks/generic.mock';
import { DistributorManagerConstantMock } from '@pages/distributor/constant/distributor-manager.constant.mock';
import { EndpointsConstant } from '../../../core/constants/endpoint.constant';

export class DistributorManagerEndpoints {
  static readonly distributorsBase = 'distributors';
  static readonly distributorBase = 'distributor';
  static readonly distributorItemBase = `${DistributorManagerEndpoints.distributorBase}/${EndpointsConstant.genericIdParamSelector}`;
  static readonly distributorsItemBase = `${DistributorManagerEndpoints.distributorsBase}/${EndpointsConstant.genericIdParamSelector}`;
  static readonly distributorGroupBase = 'distributor-groups';
  static readonly distributorGroupItemBase = `distributorGroup/${EndpointsConstant.genericIdParamSelector}`;
  static config = {
    LIST: {
      url: 'distribution-bodies'
    },
    GROUP: {
      LIST: {
        url: `${DistributorManagerEndpoints.distributorGroupBase}`
      },
      MAIN: {
        url: `${DistributorManagerEndpoints.distributorGroupBase}/${EndpointsConstant.genericIdParamSelector}`
      },
      GENERAL_INFO: {
        url: `${DistributorManagerEndpoints.distributorGroupItemBase}/generalInfo`,
        mock: DistributorManagerConstantMock.distributorGroupById['1']
      },
      SECURITIES_AND_CREDIT_LIMITS: {
        url: `${DistributorManagerEndpoints.distributorGroupItemBase}/securitiesAndCreditLimits`,
        mock: DistributorManagerConstantMock.distributorGroupById['1']
      },
      DISTRIBUTOR_CREDIT_LIMITS: {
        url: `${DistributorManagerEndpoints.distributorGroupItemBase}/distributorCreditLimits`,
        mock: DistributorManagerConstantMock.distributorGroupById['1']
      }
    },
    DISTRIBUTOR: {
      LIST: {
        url: `${DistributorManagerEndpoints.distributorsBase}`
      },
      MAIN: {
        url: `${DistributorManagerEndpoints.distributorsBase}`
      },
      ITEM: {
        url: `${DistributorManagerEndpoints.distributorsBase}/${EndpointsConstant.genericIdParamSelector}`
      },
      GENERAL_INFO: {
        url: `${DistributorManagerEndpoints.distributorItemBase}/generalInfo`,
        mock: DistributorManagerConstantMock.distributorById['1']
      },
      SECURITIES_AND_CREDIT_LIMITS: {
        url: `${DistributorManagerEndpoints.distributorsItemBase}/securities-and-credit-limits`
      },
      PRODUCT_LINES_AND_BLANKETS: {
        url: `${DistributorManagerEndpoints.distributorItemBase}/productLinesAndBlankets`,
        mock: DistributorManagerConstantMock.distributorById['1']
      },
      SOURCE_LOCATION_MAPPING: {
        url: `${DistributorManagerEndpoints.distributorItemBase}/sourceLocationMapping`,
        mock: DistributorManagerConstantMock.distributorById['1']
      },
      OBSERVATIONS: {
        url: `${DistributorManagerEndpoints.distributorItemBase}/observations`,
        mock: DistributorManagerConstantMock.distributorById['1']
      }
    },
    DETAILS_DISTRIBUTOR: {
      url: 'DISTRIBUTOR_DETAILS',
      mock: DistributorManagerConstantMock.distributorById['1']
    },
    DETAILS_GROUP_DISTRIBUTOR: {
      url: 'DISTRIBUTOR_DETAILS',
      mock: DistributorManagerConstantMock.distributorById['1']
    },
    DISTRIBUTOR_GROUP: {
      url: 'DISTRIBUTOR_GROUP',
      mock: [basicDropDownListItem]
    },
    OPERATIONAL_STATUS: {
      url: `${DistributorManagerEndpoints.distributorsBase}/operational-statuses`
    },
    ACCOUNT_STATUS: {
      url: `${DistributorManagerEndpoints.distributorsBase}/account-statuses`
    },
    RISK_STATUS: {
      url: `${DistributorManagerEndpoints.distributorsBase}/risk-statuses`
    },
    COUNTRY_LIST: {
      url: 'COUNTRY_LIST',
      mock: [
        { value: 'Spain', label: 'Spain' },
        { value: 'Portugal', label: 'Portugal' }
      ]
    },
    TASK_BOARD_STATUS: {
      url: 'TASK_BOARD_STATUS',
      mock: [basicDropDownListItem]
    },
    CREDIT_LIMIT_STATUS: {
      url: `${DistributorManagerEndpoints.distributorsBase}/credit-limit-statuses`
    },
    FUNDING_BANK: {
      url: 'FUNDING_BANK',
      mock: [basicDropDownListItem]
    },
    PRODUCT_LINES_BY_DISTRIBUTOR: {
      url: `${DistributorManagerEndpoints.distributorsItemBase}/product-lines`
    },
    PRODUCT_LINES_TYPES: {
      url: `${DistributorManagerEndpoints.distributorsBase}/product-line-types`
    },
    PRODUCT_LINES_STATUSES: {
      url: `${DistributorManagerEndpoints.distributorsBase}/product-line-statuses`
    },
    SECURITY_TYPES: {
      url: `${DistributorManagerEndpoints.distributorsBase}/security-types`
    },
    SECURITY_STATUSES: {
      url: `${DistributorManagerEndpoints.distributorsBase}/security-statuses`
    },
    SECURITIES_GUARANTOR_INSURER_LIST: {
      url: '',
      mock: [
        { value: 'Bank Guarantee', label: 'Bank Guarantee' },
        { value: 'Standby Letter of Credit', label: 'Standby Letter of Credit' },
        { value: 'Corporate Guarantee', label: 'Corporate Guarantee' },
        { value: 'Cross Company Guarantee', label: 'Cross Company Guarantee' },
        { value: 'Payment Guarantee', label: 'Payment Guarantee' },
        { value: 'FDI', label: 'FDI' }
      ]
    },
    BILLING_TYPE: {
      url: `${DistributorManagerEndpoints.distributorsBase}/billing-types`
    },
    LATE_CHARGE_METHODS: {
      url: `${DistributorManagerEndpoints.distributorsBase}/late-charge-methods`
    },
    DOCUMENTATION: {
      url: `${DistributorManagerEndpoints.distributorsBase}/documentation`
    },
    PAYMENT_METHODS: {
      url: `${DistributorManagerEndpoints.distributorsBase}/payment-methods`
    },
    FINANCIAL_BLANKETS: {
      url: `${DistributorManagerEndpoints.distributorsBase}/financial-blanket-statuses`
    }
  };
}
