import { GenericTableColumnProps, NextFilterType, TableFieldProps } from '@components/organisms/next-table/enums/next-table.enum';
import { ColumnSize, FieldType, TableField, TemplateType } from '@components/organisms/next-table/next-table.type';
import { CountryFilterProps, CountryProps, TaskboardStatus } from '../enums/country.enum';
import { LinkButtonPosition } from '@components/atoms/buttons/components/link-button/link-button.enum';
import { NextFormatCurrencyPipe } from '@pipes/next-format-currency.pipe';
import { DataTitleConfig } from '@components/atoms/visual/components/data-title/data-title.config';
import { DataTitleProps } from '@components/atoms/visual/components/data-title/data-title.enum';
import { CommonBooleanLabelPipe } from '@pipes/boolean-label.pipe';
import { NextFormatDatePipe } from '@pipes/common/custom-date.pipe';
import { ColorType } from 'app/shared/enums/color.enum';
import { StatusConfigProps } from 'app/core/enums/status.enum';
import { NextBooleanHelper } from '@utils/core/next-boolean.helper';

export class CountryConstant {
  static readonly globalFilter = [CountryProps.NAME];

  static taskboardStatusConfig: any = {
    [TaskboardStatus.WAITING_APPROVAL]: {
      [StatusConfigProps.KEY]: 'COUNTRY.STATUS_TASK.WAITING_APPROVAL',
      [StatusConfigProps.COLOR]: ColorType.CAUTION
    },
    [TaskboardStatus.REJECTED]: {
      [StatusConfigProps.KEY]: 'COUNTRY.STATUS_TASK.REJECTED',
      [StatusConfigProps.COLOR]: ColorType.DANGER
    }
  };

  static readonly tableListConfig: TableField[] = [
    {
      [TableFieldProps.FIELD]: CountryProps.NAME,
      [TableFieldProps.HEADER]: 'COUNTRY.FIELDS.COUNTRY_NAME',
      [TableFieldProps.IS_SORTABLE]: true,
      [TableFieldProps.IS_FILTER]: true
    },
    {
      [TableFieldProps.FIELD]: CountryProps.CODE,
      [TableFieldProps.HEADER]: 'COUNTRY.FIELDS.COUNTRY_CODE',
      [TableFieldProps.IS_SORTABLE]: true,
      [TableFieldProps.IS_FILTER]: true
    },
    {
      [TableFieldProps.FIELD]: CountryProps.OPERATIONAL_REGION,
      [TableFieldProps.HEADER]: 'COUNTRY.FIELDS.REGION',
      [TableFieldProps.IS_SORTABLE]: true,
      [TableFieldProps.IS_FILTER]: true
    },
    {
      [TableFieldProps.FIELD]: CountryProps.EURO_INDICATOR,
      [TableFieldProps.HEADER]: 'COUNTRY.FIELDS.EU_MEMBER',
      [TableFieldProps.IS_SORTABLE]: true,
      [TableFieldProps.IS_FILTER]: true
    },
    {
      [TableFieldProps.FIELD]: CountryProps.FINISH_VEHICLE_ACCOUNTING,
      [TableFieldProps.HEADER]: 'COUNTRY.FIELDS.INSURED_FDI',
      [TableFieldProps.IS_SORTABLE]: true,
      [TableFieldProps.IS_FILTER]: true,
      [TableFieldProps.PIPE_LIST]: [
        { pipe: CommonBooleanLabelPipe }
      ]
    },
    {
      [TableFieldProps.FIELD]: CountryProps.RISK_CODE,
      [TableFieldProps.HEADER]: 'COUNTRY.FIELDS.RISK_RATING',
      [TableFieldProps.IS_SORTABLE]: true,
      [TableFieldProps.IS_FILTER]: true,
      [TableFieldProps.TOOLTIP]: CountryProps.RISK_RATING_DESCRIPTION
    },
    {
      [TableFieldProps.FIELD]: CountryProps.FDI_COUNTRY_LIMIT,
      [TableFieldProps.HEADER]: 'COUNTRY.FIELDS.FDI_COUNTRY_LIMIT',
      [TableFieldProps.IS_SORTABLE]: true,
      [TableFieldProps.IS_FILTER]: true,
      [TableFieldProps.PIPE_LIST]: [
        { pipe: NextFormatCurrencyPipe, args: [{ fromValue: CountryProps.FDI_COUNTRY_CURRENCY }] }
      ]
    },
    {
      [TableFieldProps.FIELD]: CountryProps.INSURANCE_EXCEPTION_AMOUNT,
      [TableFieldProps.HEADER]: 'COUNTRY.FIELDS.EXCEPTION_FDI_LIMITS',
      [TableFieldProps.IS_SORTABLE]: true,
      [TableFieldProps.PIPE_LIST]: [
        { pipe: NextFormatCurrencyPipe, args: [{ fromValue: CountryProps.INSURANCE_EXCEPTION_CURRENCY }] }
      ]
    },
    {
      [TableFieldProps.FIELD]: CountryProps.INSURANCE_EXCEPTION_EFFECTIVE_TIMESTAMP,
      [TableFieldProps.HEADER]: 'COUNTRY.FIELDS.EXCEPTION_EFFECTIVE_DATE',
      [TableFieldProps.IS_SORTABLE]: true,
      [TableFieldProps.IS_FILTER]: true,
      [TableFieldProps.FIELD_TYPE]: [FieldType.DATE],
      [TableFieldProps.FILTER_TYPE]: NextFilterType.DATE
    },
    {
      [TableFieldProps.FIELD]: CountryProps.INSURANCE_EXCEPTION_EXPIRY_TIMESTAMP,
      [TableFieldProps.HEADER]: 'COUNTRY.FIELDS.EXCEPTION_EXPIRY_DATE',
      [TableFieldProps.IS_SORTABLE]: true,
      [TableFieldProps.IS_FILTER]: true,
      [TableFieldProps.FIELD_TYPE]: [FieldType.DATE],
      [TableFieldProps.FILTER_TYPE]: NextFilterType.DATE
    },
    {
      [TableFieldProps.FIELD]: CountryProps.TASK,
      [TableFieldProps.HEADER]: 'COUNTRY.FIELDS.TASK',
      [TableFieldProps.TEMPLATE_TYPE]: TemplateType.TASK,
      [TableFieldProps.WIDTH]: ColumnSize.VERY_SMALL,
      [TableFieldProps.STATUS_MAP]: CountryConstant.taskboardStatusConfig
    },
    {
      [TableFieldProps.FIELD]: GenericTableColumnProps.MENU_LIST,
      [TableFieldProps.HEADER]: 'COUNTRY.FIELDS.OPTIONS',
      [TableFieldProps.TEMPLATE_TYPE]: TemplateType.MENU_ACTION,
      [TableFieldProps.ALIGN_BUTTON]: LinkButtonPosition.CENTER,
      [TableFieldProps.WIDTH]: ColumnSize.VERY_SMALL
    }
  ];

  static readonly extraFilterInitialValues = {
    [CountryFilterProps.CODE]: '',
    [CountryFilterProps.OPERATIONAL_REGION]: '',
    [CountryFilterProps.EURO_INDICATOR]: '',
    [CountryFilterProps.FINISH_VEHICLE_ACCOUNTING]: '',
    [CountryFilterProps.RISK_CODE]: '',
    [CountryFilterProps.INSURANCE_EXCEPTION_EFFECTIVE_TIMESTAMP_FROM]: '',
    [CountryFilterProps.INSURANCE_EXCEPTION_EFFECTIVE_TIMESTAMP_TO]: '',
    [CountryFilterProps.INSURANCE_EXCEPTION_EXPIRY_TIMESTAMP_FROM]: '',
    [CountryFilterProps.INSURANCE_EXCEPTION_EXPIRY_TIMESTAMP_TO]: ''
  };

  static readonly compareDataMapper = [
    {
      titleKey: 'COUNTRY.FIELDS.RISK_RATING',
      path: [CountryProps.RISK_CODE]
    },
    {
      titleKey: 'COUNTRY.FIELDS.FDI_COUNTRY_LIMIT',
      path: [CountryProps.FDI_COUNTRY_LIMIT],
      [TableFieldProps.PIPE_LIST]: [
        { pipe: NextFormatCurrencyPipe, args: [{ fromValue: CountryProps.FDI_COUNTRY_CURRENCY }] }
      ]
    },
    {
      titleKey: 'COUNTRY.FIELDS.EXCEPTION_FDI_LIMITS',
      path: [CountryProps.INSURANCE_EXCEPTION_AMOUNT],
      [TableFieldProps.PIPE_LIST]: [
        { pipe: NextFormatCurrencyPipe, args: [{ fromValue: CountryProps.INSURANCE_EXCEPTION_CURRENCY }] }
      ]
    },
    {
      titleKey: 'COUNTRY.FIELDS.EXCEPTION_EFFECTIVE_DATE',
      path: [CountryProps.INSURANCE_EXCEPTION_EFFECTIVE_TIMESTAMP],
      [TableFieldProps.PIPE_LIST]: [{ pipe: NextFormatDatePipe }],
    },
    {
      titleKey: 'COUNTRY.FIELDS.EXCEPTION_EXPIRY_DATE',
      path: [CountryProps.INSURANCE_EXCEPTION_EXPIRY_TIMESTAMP],
      [TableFieldProps.PIPE_LIST]: [{ pipe: NextFormatDatePipe }],
    }
  ]

  static readonly countryDetailsMap: DataTitleConfig[] = [
    {
      [DataTitleProps.PATH]: [CountryProps.OPERATIONAL_REGION],
      [DataTitleProps.TITLE_KEY]: 'COUNTRY.FIELDS.REGION'
    },
    {
      [DataTitleProps.PATH]: [CountryProps.EURO_INDICATOR],
      [DataTitleProps.TITLE_KEY]: 'COUNTRY.FIELDS.EU_MEMBER',
      [DataTitleProps.PIPE_LIST]: [{ pipe: CommonBooleanLabelPipe }]
    },
    {
      [DataTitleProps.PATH]: [CountryProps.FINISH_VEHICLE_ACCOUNTING],
      [DataTitleProps.TITLE_KEY]: 'COUNTRY.FIELDS.INSURED_FDI',
      [DataTitleProps.PIPE_LIST]: [{ pipe: CommonBooleanLabelPipe }]
    },
    {
      [DataTitleProps.PATH]: [CountryProps.RISK_CODE],
      [DataTitleProps.TITLE_KEY]: 'COUNTRY.FIELDS.RISK_RATING'
    },
    {
      [DataTitleProps.PATH]: [CountryProps.FDI_COUNTRY_LIMIT],
      [DataTitleProps.TITLE_KEY]: 'COUNTRY.FIELDS.FDI_COUNTRY_LIMIT',
      [TableFieldProps.PIPE_LIST]: [
        { pipe: NextFormatCurrencyPipe, args: [{ fromValue: CountryProps.FDI_COUNTRY_CURRENCY }] }
      ]
    },
    {
      [DataTitleProps.PATH]: [CountryProps.INSURANCE_EXCEPTION_AMOUNT],
      [DataTitleProps.TITLE_KEY]: 'COUNTRY.FIELDS.EXCEPTION_FDI_LIMITS',
      [TableFieldProps.PIPE_LIST]: [
        { pipe: NextFormatCurrencyPipe, args: [{ fromValue: CountryProps.INSURANCE_EXCEPTION_CURRENCY }] }
      ]
    },
    {
      [DataTitleProps.PATH]: [CountryProps.INSURANCE_EXCEPTION_EFFECTIVE_TIMESTAMP],
      [DataTitleProps.TITLE_KEY]: 'COUNTRY.FIELDS.EXCEPTION_EFFECTIVE_DATE',
      [DataTitleProps.PIPE_LIST]: [{ pipe: NextFormatDatePipe }]
    },
    {
      [DataTitleProps.PATH]: [CountryProps.INSURANCE_EXCEPTION_EXPIRY_TIMESTAMP],
      [DataTitleProps.TITLE_KEY]: 'COUNTRY.FIELDS.EXCEPTION_EXPIRY_DATE',
      [DataTitleProps.PIPE_LIST]: [{ pipe: NextFormatDatePipe }]
    }
  ];

  static readonly countryDownloadConfig: DataTitleConfig[] = [
    {
      [DataTitleProps.PATH]: [CountryProps.NAME],
      [DataTitleProps.TITLE_KEY]: 'COUNTRY.FIELDS.COUNTRY_NAME'
    },
    {
      [DataTitleProps.PATH]: [CountryProps.CODE],
      [DataTitleProps.TITLE_KEY]: 'COUNTRY.FIELDS.COUNTRY_CODE'
    },
    {
      [DataTitleProps.PATH]: [CountryProps.OPERATIONAL_REGION],
      [DataTitleProps.TITLE_KEY]: 'COUNTRY.FIELDS.REGION'
    },
    {
      [DataTitleProps.PATH]: [CountryProps.EURO_INDICATOR],
      [DataTitleProps.TITLE_KEY]: 'COUNTRY.FIELDS.EU_MEMBER'
    },
    {
      [DataTitleProps.PATH]: [CountryProps.FINISH_VEHICLE_ACCOUNTING],
      [DataTitleProps.TITLE_KEY]: 'COUNTRY.FIELDS.INSURED_FDI'
    },
    {
      [DataTitleProps.PATH]: [CountryProps.RISK_CODE],
      [DataTitleProps.TITLE_KEY]: 'COUNTRY.FIELDS.RISK_RATING'
    },
    {
      [DataTitleProps.PATH]: [CountryProps.FDI_COUNTRY_LIMIT],
      [DataTitleProps.TITLE_KEY]: 'COUNTRY.FIELDS.FDI_COUNTRY_LIMIT'
    },
    {
      [DataTitleProps.PATH]: [CountryProps.INSURANCE_EXCEPTION_AMOUNT],
      [DataTitleProps.TITLE_KEY]: 'COUNTRY.FIELDS.EXCEPTION_FDI_LIMITS'
    },
    {
      [DataTitleProps.PATH]: [CountryProps.INSURANCE_EXCEPTION_EFFECTIVE_TIMESTAMP],
      [DataTitleProps.TITLE_KEY]: 'COUNTRY.FIELDS.EXCEPTION_EFFECTIVE_DATE'
    },
    {
      [DataTitleProps.PATH]: [CountryProps.INSURANCE_EXCEPTION_EXPIRY_TIMESTAMP],
      [DataTitleProps.TITLE_KEY]: 'COUNTRY.FIELDS.EXCEPTION_EXPIRY_DATE'
    },
  ];

  static readonly fileExcelName = 'country';
  static fileType: string = 'country';

}
