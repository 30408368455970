import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { DistributorManagerConstant } from '@pages/distributor/constant/distributor-manager.constant';
import { DistributorProps, DistributorSection } from '@pages/distributor/enums/distributor-manager.enum';
import { SourceLocationMappingAdminService } from '@pages/distributor/services/source-location-mapping-admin.service';
import { FormArrayAdminAbstractComponent } from '@components/organisms/steps-form-module/form-array-admin-abstract/form-array-admin-abstract.component';
import { NextObjectHelper } from '@utils/core/next-object.helper';
import { TableFieldProps } from '@components/organisms/next-table/enums/next-table.enum';
import { MenuItemService } from '../../../../core/services/menu-item.service';
import {
  SourceLocationMappingActions,
  SourceLocationProps
} from '@pages/distributor/enums/source-location-mapping.enum';
import { NextIcon } from '@components/atoms/visual/enums/icon.enum';
import { SourceLocationMappingConstant } from '@pages/distributor/constant/source-location-mapping.constant';
import { DSourceLocationMapping } from '@pages/distributor/types/distributor.type';

@Component({
  selector: 'source-location-mapping',
  templateUrl: './source-location-mapping.component.html',
  styleUrl: './source-location-mapping.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SourceLocationMappingComponent
  extends FormArrayAdminAbstractComponent<any, DistributorSection.SOURCE_LOCATION_MAPPING>
  implements OnInit
{
  @Input() data: DSourceLocationMapping;
  @Input() dataRef: DSourceLocationMapping;

  hasBorder: boolean = true;
  hasNoneBorderRadius: boolean = true;

  protected readonly DistributorManagerConstant = DistributorManagerConstant;
  protected readonly DistributorProps = DistributorProps;
  protected readonly LABEL_KEY_PREFIX = 'DISTRIBUTOR.MANAGER.DETAILS.TABS.SOURCE_LOCATION_MAPPING';
  protected readonly SourceLocationMappingConstant = SourceLocationMappingConstant;
  protected readonly DistributorSection = DistributorSection;

  constructor(
    private _sourceLocationMappingAdminService: SourceLocationMappingAdminService,
    private _menuItemService: MenuItemService
  ) {
    super();
  }

  ngOnInit(): void {
    this._setConfigData();
  }

  override handleAdd(entityValues: any) {
    const formGroup = this._sourceLocationMappingAdminService.buildSourceLocationMappingFormGroup(entityValues, true);
    super.handleAdd(this.formArray!, formGroup);
    this._updateList();
  }

  removeItem(index: number): void {
    super.handleRemove(this.formArray!, index);
    this._updateList();
  }

  private _setConfigData() {
    if (this.formArray) {
      this.isEdition = true;
      this._updateList();
    } else {
      this.entityList = this.data;
    }
  }

  private _updateList() {
    this.entityList = this._mapList();
  }

  private _mapList() {
    const formList = this.formArray!.getRawValue();
    return NextObjectHelper.getPropertyFromObject(formList).map((baseEntity: any, index: number) => {
      const entityWithIndex = { ...baseEntity, index };
      const optionList = this._createTableActions(entityWithIndex);
      return { ...entityWithIndex, [TableFieldProps.MENU_OPTION_LIST]: optionList };
    });
  }

  private _createTableActions(entity: any) {
    return this._menuItemService.buildMenuItemList(
      [SourceLocationMappingActions.VIEW, SourceLocationMappingActions.MODIFY, SourceLocationMappingActions.DELETE],
      {
        [SourceLocationMappingActions.VIEW]: {
          label: this.LABEL_KEY_PREFIX + '.ACTIONS.VIEW',
          icon: NextIcon.EYE_OPENED,
          callback: () => this.viewDetails(DistributorSection.SOURCE_LOCATION_MAPPING, entity)
        },
        [SourceLocationMappingActions.MODIFY]: {
          label: this.LABEL_KEY_PREFIX + '.ACTIONS.MODIFY',
          icon: NextIcon.EDIT,
          callback: () => this.enablePopup(DistributorSection.SOURCE_LOCATION_MAPPING, entity)
        },
        [SourceLocationMappingActions.DELETE]: {
          label: this.LABEL_KEY_PREFIX + '.ACTIONS.DELETE',
          icon: NextIcon.DELETE,
          callback: () => this.removeItem(entity.index)
        }
      }
    );
  }

  protected readonly SourceLocationProps = SourceLocationProps;
}
