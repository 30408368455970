import { TaskBoardRequestType } from '@pages/task-board/enums/task-board.enum';
import { EndpointsConstant } from '../../../core/constants/endpoint.constant';
import { CountryEndpoints } from '@pages/general-maintenance/pages/country/constant/country.endpoints.constant';

export class TaskBoardEndpoints {
  static readonly maintenanceTaskBase = 'maintenance-tasks';
  static readonly taskboardPath = 'taskboard';
  static readonly ordersPath = 'orders';

  static readonly config = {
    GENERAL_MAINTENANCE: {
      LIST: {
        url: `${TaskBoardEndpoints.maintenanceTaskBase}`
      },
      APPROVE: {
        url: `${CountryEndpoints.countriesBase}/${EndpointsConstant.genericIdParamSelector}/task/${EndpointsConstant.genericIdTask}`
      },
      REJECTED: {
        url: `${CountryEndpoints.countriesBase}/${EndpointsConstant.genericIdParamSelector}/task/${EndpointsConstant.genericIdTask}/rejected`
      },
      DELETE: {
        url: `${CountryEndpoints.countriesBase}/${EndpointsConstant.genericIdParamSelector}/task/${EndpointsConstant.genericIdTask}`
      }
    },
    TASK: {
      url: `${TaskBoardEndpoints.maintenanceTaskBase}/${EndpointsConstant.genericIdParamSelector}`
    },
    VEHICLE_ORDER: {
      LIST: {
        url: `${TaskBoardEndpoints.ordersPath}/${TaskBoardEndpoints.taskboardPath}`
      },
      ITEM: {
        url: `${TaskBoardEndpoints.ordersPath}/${TaskBoardEndpoints.taskboardPath}/${EndpointsConstant.genericIdParamSelector}`
      }
    },
    REQUEST_TYPE_LIST: {
      url: 'REQUEST_TYPE_LIST',
      mock: [
        {
          value: TaskBoardRequestType.NEW_DISTRIBUTOR,
          label: 'TASK_BOARD.DISTRIBUTORS.REQUEST_TYPE_LIST.NEW_DISTRIBUTOR'
        },
        {
          value: TaskBoardRequestType.UPDATE_DISTRIBUTOR,
          label: 'TASK_BOARD.DISTRIBUTORS.REQUEST_TYPE_LIST.UPDATE_DISTRIBUTOR'
        },
        { value: TaskBoardRequestType.NEW_GROUP, label: 'TASK_BOARD.DISTRIBUTORS.REQUEST_TYPE_LIST.NEW_GROUP' },
        { value: TaskBoardRequestType.UPDATE_GROUP, label: 'TASK_BOARD.DISTRIBUTORS.REQUEST_TYPE_LIST.UPDATE_GROUP' }
      ]
    },
    REQUEST_TYPE_GM_LIST: {
      url: 'REQUEST_TYPE_GM_LIST',
      mock: [
        {
          value: TaskBoardRequestType.COUNTRY_RATING_UPDATE,
          label: 'TASK_BOARD.GENERAL_MAINTENANCE.REQUEST_TYPE_LIST.COUNTRY_RATING_UPDATE'
        },
        {
          value: TaskBoardRequestType.BASE_RATE_UPDATE,
          label: 'TASK_BOARD.GENERAL_MAINTENANCE.REQUEST_TYPE_LIST.BASE_RATE_UPDATE'
        }
      ]
    }
  };
}
