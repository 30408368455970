import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import {
  FilterToggleProps,
  NextTableFilterProps
} from '@components/organisms/next-table-filter/enums/next-table.filter.enum';
import { GenericBaseComponent } from '@components/atoms/abstract-base/components/generic-base/generic-base.component';
import { NextObservableHelper } from '@utils/core/next-observable.helper';
import { merge } from 'rxjs';
import { FilterToggleControlConfig } from '@components/organisms/next-table-filter/types/next-table-filter.type';
import { NextValueHelper } from '@utils/core/next-value.helper';
import { NextTableFilterConstant } from '@components/organisms/next-table-filter/constants/next-table-filter.constant';
import { IconSizeModifier, NextIcon } from '@components/atoms/visual/enums/icon.enum';
import { GenericObject } from '@utils/models/Types';
import { ColorType } from '../../../shared/enums/color.enum';
import { NextObjectHelper } from '@utils/core/next-object.helper';


@Component({
  selector: 'next-table-filter',
  templateUrl: './next-table-filter.component.html',
  styleUrl: './next-table-filter.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NextTableFilterComponent extends GenericBaseComponent implements OnInit {
  @Input() toggleConfig: FilterToggleControlConfig[] = [];
  @Input() toggleTargetColumn?: any;
  @Input() extraFormGroup: FormGroup;
  @Input() extraFormGroupInitialValues: any = [];
  @Input() filterValues: GenericObject = {};
  @Input() searchFilter: boolean = true;
  @Input() placeholderInputSearch: string = 'FILTER.SEARCH_PLACEHOLDER';
  @Input() hasExtraFilter: boolean = false;
  @Output() filterChange = new EventEmitter<any>();
  filterGroup: FormGroup;
  isExtraFilterVisible = false;
  toggleAllControl = new FormControl();
  hasExtraFilters: boolean = false;

  protected readonly NextTableFilterProps = NextTableFilterProps;
  protected readonly FilterToggleProps = FilterToggleProps;
  protected readonly NextIcon = NextIcon;
  protected readonly IconSizeModifier = IconSizeModifier;
  protected readonly ColorType = ColorType;
  
  constructor(private _fb: FormBuilder) {
    super();
  }
  ngOnInit(): void {
    this._createFormFilter();
    this._createFilterAutoEmit();
  }
  applyExtraFilter() {
    this._emitFormValues();
    this.dismissFilter();
  }

  resetExtraFilter() {
    this.extraFormGroup.reset(this.extraFormGroupInitialValues);
    document.querySelectorAll('.p-calendar-clear-icon').forEach((element, index) => {
      element.parentElement?.setAttribute('id', 'close-calendar-' + index);
      document.getElementById('close-calendar-' + index)?.click();
    });
    this.filterValues = {};
  }

  dismissFilter() {
    this.isExtraFilterVisible = false;
  }

  handleToggleAll(checked: boolean | undefined) {
    const toggleGroup = this.filterGroup.get(NextTableFilterProps.TOGGLE_FILTER) as FormGroup;
    this.toggleConfig.forEach(({ controlKey }) => {
      toggleGroup.get(controlKey)?.setValue(checked);
    });
  }

  private _createFormFilter() {
    const toggleControlConfig = this._createToggleControlConfig();
    const filterGroupConfig = {
      [NextTableFilterProps.TOGGLE_FILTER]: this._fb.group(toggleControlConfig),
      [NextTableFilterProps.SEARCH_FILTER]: this._fb.group({
        [NextTableFilterProps.NAME_SEARCH]: this.filterValues[NextTableFilterProps.NAME_SEARCH]
      }),
      [NextTableFilterProps.EXTRA_FILTER]: NextValueHelper.defaultValue(this.extraFormGroup)
    };
    this.filterGroup = this._fb.group(filterGroupConfig);
  }

  private _createToggleControlConfig() {
    let toggleAllInitialValue = true;
    let columnFilterValues;
    const filteredToggle: GenericObject = {};
    const controlConfig = {};

    if (this.toggleTargetColumn && this.filterValues[this.toggleTargetColumn]) {
      if (typeof this.filterValues[this.toggleTargetColumn] === 'string') {
        columnFilterValues = [this.filterValues[this.toggleTargetColumn]];
      } else {
        columnFilterValues = NextObjectHelper.getPropertyFromObject(
          this.filterValues,
          [this.toggleTargetColumn],
          []
        ) as Array<any>;
      }

      columnFilterValues.forEach(key => {
        filteredToggle[key] = true;
      });
    }
    this.toggleConfig.forEach((config: FilterToggleControlConfig) => {
      const controlKey = config[FilterToggleProps.CONTROL_KEY];
      const filterValue = filteredToggle[controlKey];
      const toggleValue = NextValueHelper.defaultValue(filterValue, config[FilterToggleProps.DEFAULT_VALUE]);

      Object.assign(controlConfig, {
        [controlKey]: NextValueHelper.defaultValue(toggleValue, NextTableFilterConstant.defaultToggleValue)
      });
      toggleAllInitialValue = toggleAllInitialValue && toggleValue;
    });
    this.toggleAllControl.setValue(toggleAllInitialValue);

    return controlConfig;
  }

  private _createFilterAutoEmit() {
    const filterChanges = merge(
      NextObservableHelper.pipeFormValueChanges(this.filterGroup.controls[NextTableFilterProps.SEARCH_FILTER]),
      NextObservableHelper.pipeFormValueChanges(this.filterGroup.controls[NextTableFilterProps.TOGGLE_FILTER], 0)
    ).subscribe(() => {
      this._emitFormValues();
    });

    this.subs.push(filterChanges);
  }

  private _emitFormValues() {
    const filterValues = this.filterGroup.getRawValue();
    
    const toggleValues = this._buildToggleValues(filterValues[NextTableFilterProps.TOGGLE_FILTER]);
    const finalFilter = {
      ...filterValues[NextTableFilterProps.SEARCH_FILTER],
      ...filterValues[NextTableFilterProps.EXTRA_FILTER]
    };

    if (this.toggleTargetColumn) {
      Object.assign(finalFilter, { [this.toggleTargetColumn]: toggleValues });
    }
    this.filterChange.emit(finalFilter);
  }

  private _buildToggleValues(toggleValues: any) {
    let toggleAllInitialValue = true;
    const filteredToggleValues: any[] = [];
    Object.keys(toggleValues).forEach(key => {
      if (toggleValues[key]) {
        filteredToggleValues.push(key);
      } else {
        toggleAllInitialValue = !toggleAllInitialValue;
      }
    });
    this.toggleAllControl.setValue(toggleAllInitialValue);

    return filteredToggleValues;
  }
}
