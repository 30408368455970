import { Pipe, PipeTransform } from '@angular/core';
import { NextValueHelper } from '@utils/core/next-value.helper';
import { formatNumber } from '@angular/common';
import { AppSettingsConstant } from '@constants/app-settings.constant';

@Pipe({
  name: 'nextFormatCurrency',
  standalone: true
})
export class NextFormatCurrencyPipe implements PipeTransform {
  transform(value: any, currencyCode: string = '', defaultToZero = false): unknown {
    let amountValue = value;
    const isDefined = NextValueHelper.isValueDefined(amountValue);

    if (!isDefined) {
      if (!defaultToZero) {
        return '';
      }

      amountValue = 0;
    }

    //Locale to achieve currency format 12.345,66
    const currencyLocale = AppSettingsConstant.defaultLocale;
    const formatedNumber = formatNumber(amountValue, currencyLocale);
    return `${formatedNumber} ${currencyCode}`;
  }
}
