<p-dialog [visible]="visible" [draggable]="draggable"
          [resizable]="resizable" [modal]="modal" [style]="{ width: dialogSize+'vw' }"
          (visibleChange)="visibleChange.emit()">

  <ng-template pTemplate="header">
    <div class="next-dialog__header">
      {{titleKey | translate}}
    </div>
  </ng-template>
  <ng-content select="[dialogContent]"></ng-content>
  <ng-template pTemplate="footer">
    <div class="next-dialog__footer">
      <link-button *ngIf="!hideDismiss" [label]=" 'FILTER.DISMISS'| translate"
                   (clickEvt)="dismissClick.emit()"></link-button>
      <div>
        <ng-content select="[dialogFooterExtraControls]"></ng-content>
      </div>
      <submit-cancel
        [submitLabel]="submitLabelKey"
        [cancelLabel]="cancelLabelKey"
        [isSubmitDisabled]="isSubmitDisabled!"
        [hideSubmitButton]="hideSubmitButton"
        [hideCancelButton]="hideCancelButton"
        (submitClick)="submitClick.emit()"
        (cancelClick)="cancelClick.emit()"
      ></submit-cancel>
    </div>
  </ng-template>
</p-dialog>
