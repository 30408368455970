<div class="details-observations">
  <ng-container *ngIf="isEdition; else viewTemplate">
    <input-container *ngIf="formGroup! | deepControl: [DistributorProps.OBSERVATIONS_COMMENTS] as observationsControl"
                     [control]="observationsControl"
                     [labelKey]="'DISTRIBUTOR.MANAGER.DETAILS.TABS.OBSERVATIONS.FIELDS.COMMENTS'"
    >
      <next-textarea [control]="observationsControl" [maxLength]="commentsMaxLength"
                     [placeholder]="'DISTRIBUTOR.MANAGER.DETAILS.TABS.OBSERVATIONS.COMMENTS_PLACEHOLDER'"
                     inputElement></next-textarea>
      <div
        inputFooter> {{'FORM.INFO.CHARACTERS_LEFT' | translate: {amount: commentsMaxLength - (formGroup! | deepControlValue: [DistributorProps.OBSERVATIONS_COMMENTS] | async)?.length} }}</div>
    </input-container>
  </ng-container>
</div>
<ng-template #viewTemplate>
  <next-card [hasBorder]="hasBorder" [hasNoneBorderRadius]="hasNoneBorderRadius">
    {{ data }}
  </next-card>
</ng-template>
