<div class="product-lines-and-blankets">
  <next-card [hasInnerBorder]="!isEdition"
             [headerKey]="LABEL_KEY_PREFIX+'.TITLES.PRODUCT_LINES'">
    <div *ngIf="isEdition" class="group-details__table-control">
      <next-button
        [labelKey]="LABEL_KEY_PREFIX+'.ADD_NEW_PRODUCT'"
        (clickEvent)="enablePopup(DistributorProps.PRODUCT_LINES)"></next-button>
    </div>
    <div class="group-details__table">
      <next-table
        [dataKey]="ProductLineProps.PL_ID"
        [data]="dataProductLines"
        [dataRef]="dataRefProductLines"
        [columns]="isEdition ? ProductLinesBlanketsConstant.tableListProductLinesConfigWithActions : ProductLinesBlanketsConstant.tableListProductLinesConfigWithView"
        (clickViewActionEvt)="viewDetails(DistributorProps.PRODUCT_LINES, $event)"
      ></next-table>
    </div>
  </next-card>
  <next-card [hasInnerBorder]="!isEdition"
             [headerKey]="LABEL_KEY_PREFIX+'.TITLES.BLANKETS'">
    <div *ngIf="isEdition" class="group-details__table-control">
      <next-button
        [labelKey]="LABEL_KEY_PREFIX+'.ADD_NEW_BLANKET'"
        (clickEvent)="enablePopup(DistributorProps.BLANKETS)"></next-button>
    </div>
    <div class="group-details__table">
      <next-table
        [dataKey]="BlanketsProps.ID"
        [data]="dataBlankets"
        [dataRef]="dataRefBlankets"
        [columns]="isEdition ? BlanketsConstant.blanketsTableConfigWithActions : BlanketsConstant.blanketsTableConfigWithView"
        (clickViewActionEvt)="viewDetails(DistributorProps.BLANKETS, $event)"
      ></next-table>
    </div>
  </next-card>
  <ng-container [ngSwitch]="activePopup">

    <blankets-popup *ngSwitchCase="DistributorProps.BLANKETS" [isOpen]="true"
                    [isEdition]="isEdition && !forceViewMode" [blankets]="currentEntity"
                    (submitBlanket)="handleAdd(DistributorProps.BLANKETS, $event)"
                    (cancel)="dismissPopup()"></blankets-popup>

    <product-lines-popup *ngSwitchCase="DistributorProps.PRODUCT_LINES" [isOpen]="true"
                         [isEdition]="isEdition && !forceViewMode"
                         [productLine]="currentEntity"
                         (addEntity)="handleAdd(DistributorProps.PRODUCT_LINES, $event)"
                         (cancel)="dismissPopup()"></product-lines-popup>
  </ng-container>

</div>

