<div class="next-filter" *ngIf="filterGroup" [formGroup]="filterGroup">
  <div class="next-filter__criteria-info">
    <ng-content select="[panelCriteriaContent]"></ng-content>
  </div>
  <div *ngIf="filterGroup | deepControl: [NextTableFilterProps.TOGGLE_FILTER] as toggleFilterControl" class="next-filter__quick-actions">
    <next-toggle-button *ngIf="toggleConfig.length" [control]="toggleAllControl" [onLabel]="'FILTER.ALL' | translate"
                        [colorType]="ColorType.NEUTRAL" (changeToggle)="handleToggleAll($event)"></next-toggle-button>
    <next-toggle-button *ngFor="let controlConfig of toggleConfig"
                        [control]="toggleFilterControl | deepControl: [controlConfig[FilterToggleProps.CONTROL_KEY]]"
                        [onLabel]="controlConfig[FilterToggleProps.LABEL_KEY] | translate"
                        [colorType]="controlConfig[FilterToggleProps.COLOR_TYPE]"
                        [testIdDataAttr]="controlConfig[FilterToggleProps.CONTROL_KEY]"
    ></next-toggle-button>
  </div>
  <div *ngIf="searchFilter">
    <div *ngIf="filterGroup | deepControl: [NextTableFilterProps.SEARCH_FILTER] as searchFilterControl" class="next-filter__search">
      <next-input [control]="searchFilterControl | deepControl:[NextTableFilterProps.NAME_SEARCH]"
                  [placeholder]="placeholderInputSearch |translate"
                  [testIdDataAttr]="NextTableFilterProps.NAME_SEARCH"></next-input>
    </div>
  </div>
  
  <div *ngIf="extraFormGroup" class="next-filter__extra-content">
      <filter-button [hasExtraFilter]="hasExtraFilter" (clickEvt)="isExtraFilterVisible = true" [testIdDataAttr]="NextTableFilterProps.EXTRA_FILTER_BUTTON"></filter-button>
  </div>
</div>

<next-dialog [visible]="isExtraFilterVisible" [titleKey]="'FILTER.TITLE'" [submitLabelKey]="'FILTER.APPLY'"
             [cancelLabelKey]="'FILTER.RESET'"
             (visibleChange)="dismissFilter()" (submitClick)="applyExtraFilter()"
             (cancelClick)="resetExtraFilter()" (dismissClick)="dismissFilter()">
  <div class="next-filter__content" dialogContent>
    <ng-content select="[extraFilterContent]"></ng-content>
  </div>
  <div class="next-filter__content" dialogFooterExtraControls>
    <ng-content select="[dialogFooterExtraControls]"></ng-content>
  </div>
</next-dialog>
