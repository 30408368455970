export enum ProductLinesActions {
  VIEW = 'VIEW',
  MODIFY = 'MODIFY',
  DELETE = 'DELETE',
  ARCHIVE = 'ARCHIVE'
}

export enum ProductLineStatus {
  ACTIVE = 'ACTIVE',
  CLOSED = 'CLOSED',
  PENDING = 'PENDING'
}

export enum BlanketStatus {
  ACTIVE = 'ACTIVE',
  SUSPENDED = 'SUSPENDED'
}
export enum ProductLinesTypesProps {
  PRODUCT_LINE_TYPE_CODE = 'productLineTypeCode',
  PRODUCT_LINE_TYPE_DESCRIPTION = 'productLineTypeDescription'
}

export enum ProductLineProps {
  PL_ID = 'productLineId',
  DISTRIBUTOR_ID = 'distributorId',
  PL_NAME = 'productLineName',
  PT_CODE = 'productTypeCode',
  SL_CODE = 'sourceLocationCode',
  PL_STATUS_CODE = 'productLineStatusCode',
  TERM_DAYS = 'termDays',
  DISTRIBUTOR_BILLING_CURRENCY_CODE = 'distributorBillingCurrencyCode',
  SRC_LOCATION_INV_CURRENCY_CODE = 'srcLocationInvCurrencyCode',
  PAYEE_BANK_CODE = 'payeeBankCode',
  FUNDING_BANK_CODE = 'fundingBankCode',
  DISTRIBUTOR_INSURER_ID = 'distributorInsurerId',
  DISTRIBUTOR_SECURITY_ID = 'distributorSecurityId',
  ENCLAUSED_ID = 'enclausedIn',
  BYLLING_TYPE_CODE = 'billingTypeCode',
  PAYMENT_METHOD_CODE = 'paymentMethodCode',
  EFFECTIVE_TIMESTAMP = 'effectiveTimestamp',
  EXPIRY_TIMESTAMP = 'expiryTimestamp',
  FLAT_CHARGE_PERCENTAGE = 'flatChargePercentage',
  FLAT_DISCOUNT_PERCENTAGE = 'flatDiscountPercentage',
  INTEREST_CHARGE_PERCENTAGE = 'intChargePercentage',
  INTEREST_DISCOUNT_PERCENTAGE = 'intDiscountPercentage',
  WH_FLAT_CHARGE_PERCENTAGE = 'withholdingFlatChargePercentage',
  WH_INT_CHARGE_PERCENTAGE = 'withholdingIntChargePercentage',
  WH_LATE_CHARGE_PMT_METHOD_CODE = 'withholdingLateChargePmtMethodCode',
  WH_LATE_CHARGE_PERCENTAGE = 'withholdingLateChargePercentage',
  WH_GRACE_PERDAYS = 'withholdingGracePerDays',
  WH_INT_CHARGEABLE_DAYS = 'withholdingIntChargeableDays',
  CREATED_TIMESTAMP = 'crtTimestamp',
  CREATED_USER_ID_CODE = 'crtUserIdCode',
  LAST_UPDATE_TIMESTAMP = 'lastupdateTimestamp',
  LAST_UPDATE_USER_ID_CODE = 'lastupdateUserIdCode',
  BLANKET_STATUS_CODE = 'blanketStatusCode',
  BLANKET_AMOUNT = 'blanketAmount',
  DOC_LOCATION_TX = 'docLocationTx',
  TYPE_CODE = 'productTypeCode',
  SOURCE_LOCATION_CODE = 'sourceLocationCode',
  SOURCE_LOCATION_ALIAS = 'sourceLocationAlias',
  SECURITY_CAPITAL = 'securityCapital',
  TERM = 'term',
  PRODUCT_LINES_CURRENCY = 'productLinesCurrency',
  STATUS = 'productLineStatusCode',
  PL_INVOICE_CURRENCY = 'invoiceCurrency',
  BILLING_CURRENCY = 'distributorBillingCurrencyCode',
  PL_ACTIVATION_DATE = 'plActivationDate',
  PL_EXPIRY_DATE = 'plExpiryDate',
  ENCLAUSED = 'enclausedIn',
  PL_BILLING_TYPE = 'billingTypeCode',
  FLAT_CHARGE = 'flatCharge',
  FLAT_DISCOUNT = 'flatDiscount',
  INTEREST_CHARGE = 'interestCharge',
  INTEREST_DISCOUNT = 'interestDiscount',
  RATE_ON_FLAT_CHARGE = 'rateOnFlatCharge',
  RATE_ON_INTEREST = 'rateOnInterest',
  LATE_CHARGES_METHODS = 'lateChargesMethod',
  LATE_CHARGES_RATE = 'lateChargesRate',
  GRACE_PERIOD = 'gracePeriod',
  INT_CHARGEABLE_DAYS = 'intChargeableDays',
  PL_FUNDING = 'plFunding',
  PL_INSURER = 'insurer',
  PL_PAYEE_BANK = 'payeeBank',
  PL_DOCUMENTATION_IN = 'documentationIn',
  PL_PAYMENT_METHOD = 'paymentMethod',
  PRODUCT_LINE_ID = 'productLineId',
  BILLING_TYPE_CODE = 'billingTypeCode'
}
