export enum CountryProps {
  CODE = 'code',
  NAME = 'name',
  OPERATIONAL_REGION = 'operationalRegion',
  EURO_INDICATOR = 'euroIndicator',
  FINISH_VEHICLE_ACCOUNTING = 'finishVehicleAccounting',
  RISK_CODE = 'riskCode',
  INSURANCE_COMPANY_ENDORESED = 'insuranceCmpnyEndoresed',
  INSURANCE_EXCEPTION_AMOUNT = 'insuranceExceptionAmount',
  INSURANCE_EXCEPTION_CURRENCY = 'insuranceExceptionCurrency',
  INSURANCE_EXCEPTION_EFFECTIVE_TIMESTAMP = 'insuranceExceptionEffectiveTimestamp',
  INSURANCE_EXCEPTION_EXPIRY_TIMESTAMP = 'insuranceExceptionExpiryTimestamp',
  TASK = 'maintenanceTask',
  MEMBERSHIP = 'membership',
  // Extra Fields
  OPTIONS = 'options',
  RISK_RATING = 'riskRating',
  FDI_COUNTRY_LIMIT = 'fdiCountryLimit',
  FDI_COUNTRY_CURRENCY = 'fdiCountryCurrency',
  STATUS = 'status',
  RISK_RATING_DESCRIPTION = 'riskRatingDescription'
}

export enum CountryEditProps {
  CODE = 'code',
  RISK = 'risk',
  INSURANCE_EXCEPTION_AMOUNT = 'insuranceExceptionAmount',
  INSURANCE_EXCEPTION_EFFECTIVE_TIMESTAMP = 'insuranceExceptionEffectiveTimestamp',
  INSURANCE_EXCEPTION_EXPIRY_TIMESTAMP = 'insuranceExceptionExpiryTimestamp',
  ID = 'id',
  OWNER_CDSID = 'ownerCdsid',
  TYPE = 'type',
  AFFECTED_RESOURCE_ID = 'affectedResourceId',
  AFFECTED_RESOURCE_TYPE = 'affectedResourceType',
  STATUS = 'status',
  LATEST_EXECUTED_STEP = 'latestExecutedStep',
  AFFECTED_RESOURCE_NAME = 'affectedResourceName',
  CREATED_TIMESTAMP = 'createdTimestamp',
  INITIAROR_CDSID = 'initiatorCdsid'
}

export enum DistributionBodiesProps {
  COUNTRIES = 'countries',
  ALL_RATINGS = 'allRatings',
  COUNTRY = 'country',
  COUNTRY_RISK_RATING = 'countryRiskRating',
  TASK = 'task'
}

export enum CountryFilterProps {
  CODE = 'code',
  OPERATIONAL_REGION = 'operationalRegion',
  EURO_INDICATOR = 'euroIndicator',
  FINISH_VEHICLE_ACCOUNTING = 'finishVehicleAccounting',
  RISK_CODE = 'riskCode',
  INSURANCE_EXCEPTION_EFFECTIVE_TIMESTAMP_FROM = 'insuranceExceptionEffectiveTimestampFrom',
  INSURANCE_EXCEPTION_EFFECTIVE_TIMESTAMP_TO = 'insuranceExceptionEffectiveTimestampTo',
  INSURANCE_EXCEPTION_EXPIRY_TIMESTAMP_FROM = 'insuranceExceptionExpiryTimestampFrom',
  INSURANCE_EXCEPTION_EXPIRY_TIMESTAMP_TO = 'insuranceExceptionExpiryTimestampTo',
  RISK_RATING = 'riskRating'
}

export enum CountryAction {
  MODIFY = 'modify',
  ARCHIVE = 'archive',
  GO_TO_TASK_BOARD = 'goToTaskBoard'
}

export enum CountryApiProps {
  COUNTRY = 'country'
}

export enum TaskboardStatus {
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
  WAITING_APPROVAL = 'WAITING_APPROVAL'
}
