import { Injectable } from '@angular/core';
import { BackendService } from '@services/backend.service';
import { DistributionBodies, DistributorManager } from '@pages/distributor/types/distributor-manager.type';
import { map, Observable } from 'rxjs';
import { DistributorManagerEndpoints } from '@pages/distributor/constant/distributor-manager.endpoints.constant';
import { DistributionBodiesProps } from '@pages/distributor/enums/distributor-manager.enum';
import { ApiResponse } from '../../../core/types/api.type';
import { ApiProps } from '../../../core/enums/api.enum';
import { NextObjectHelper } from '@utils/core/next-object.helper';
import { DropdownService } from '@components/atoms/form-inputs/components/next-dropdown/services/dropdown.service';
import { DistributorManagerConstant } from '@pages/distributor/constant/distributor-manager.constant';
import { SecuritiesConstant } from '@pages/distributor/components/securities-form/securities.constant';
import { StatusConstant } from '../../../core/constants/status.constant';
import { NextValueHelper } from '@utils/core/next-value.helper';
import { ProductLinesTypesProps } from '../enums/product-lines.enum';

@Injectable({
  providedIn: 'root'
})
export class DistributorManagerService {
  constructor(
    private _backendService: BackendService,
    private _dropdownService: DropdownService
  ) {}

  getDistributionBodies(): Observable<DistributorManager[]> {
    return this._backendService.get(DistributorManagerEndpoints.config.LIST).pipe(
      map((result: ApiResponse<DistributionBodies>) => {
        return NextObjectHelper.getPropertyFromObject(result, [ApiProps.RESULT, DistributionBodiesProps.BODIES], []);
      })
    );
  }

  getRiskStatusDropdownList() {
    return this._dropdownService.getDropdownTranslationList(
      DistributorManagerEndpoints.config.RISK_STATUS,
      {
        labelMapping: DistributorManagerConstant.riskLabelMappingConfig,
        hasTranslation: true,
        keepSorting: true
      },
      { resultWithinProp: ['result', 'statuses'] }
    );
  }

  getOperationalStatusDropdownList() {
    return this._dropdownService.getDropdownTranslationList(
      DistributorManagerEndpoints.config.OPERATIONAL_STATUS,
      {
        labelMapping: DistributorManagerConstant.operationalStatusLabelMappingConfig,
        hasTranslation: true
      },
      { resultWithinProp: ['result', 'statuses'] }
    );
  }

  getAccountStatusDropdownList() {
    return this._dropdownService.getDropdownTranslationList(
      DistributorManagerEndpoints.config.ACCOUNT_STATUS,
      {
        labelMapping: DistributorManagerConstant.accountStatusLabelMappingConfig,
        hasTranslation: true
      },
      { resultWithinProp: ['result', 'statuses'] }
    );
  }

  getCreditLimitStatusDropdownList() {
    return this._dropdownService.getDropdownTranslationList(
      DistributorManagerEndpoints.config.CREDIT_LIMIT_STATUS,
      {
        labelMapping: DistributorManagerConstant.creditLimitStatusLabelMappingConfig,
        hasTranslation: true
      },
      { resultWithinProp: ['result', 'statuses'] }
    );
  }

  getProductLinesStatusesDropdownList() {
    return this._dropdownService.getDropdownTranslationList(
      DistributorManagerEndpoints.config.PRODUCT_LINES_STATUSES,
      {
        labelMapping: StatusConstant.productLineStatusLabelMapping,
        hasTranslation: true
      },
      { resultWithinProp: ['result', 'statuses'] }
    );
  }

  getProductLinesTypesDropdownList() {
    return this._dropdownService.getDropdownTranslationList(
      DistributorManagerEndpoints.config.PRODUCT_LINES_TYPES,
      {
      },
      { resultWithinProp: ['result', 'allProductLineTypes'] }
    );
  }
  getproductLinesTypes() {
    return this._backendService.get(DistributorManagerEndpoints.config.PRODUCT_LINES_TYPES).pipe(
      map((response: ApiResponse<any>) => {
        return NextObjectHelper.getPropertyFromObject(response, [ApiProps.RESULT,'allProductLineTypes'], []);
    }))
  }
  createDropdownProductLinesTypes(productLinesTypes: any) {
    const listProductLinesTypes = NextValueHelper.defaultValue(productLinesTypes, []);
        const countriesRiskRating = new Map();

      listProductLinesTypes.map((plType: any) => {
          const label = plType[ProductLinesTypesProps.PRODUCT_LINE_TYPE_CODE] + ' - ' + plType[ProductLinesTypesProps.PRODUCT_LINE_TYPE_DESCRIPTION];
          countriesRiskRating.set(plType[ProductLinesTypesProps.PRODUCT_LINE_TYPE_CODE], this._dropdownService.getDropdownItem(label,plType[ProductLinesTypesProps.PRODUCT_LINE_TYPE_CODE]));
      });
      return Array.from(countriesRiskRating.values());
  }
  getSecurityTypeDropdownList() {
    return this._dropdownService.getDropdownTranslationList(
      DistributorManagerEndpoints.config.SECURITY_TYPES,
      {},
      { resultWithinProp: ['result', 'securities'] }
    );
  }

  getSecurityStatusDropdownList() {
    return this._dropdownService.getDropdownTranslationList(
      DistributorManagerEndpoints.config.SECURITY_STATUSES,
      {
        labelMapping: SecuritiesConstant.securityStatusLabelMapping,
        hasTranslation: true
      },
      { resultWithinProp: ['result', 'statuses'] }
    );
  }

  getBillingTypeDropdownList() {
    return this._dropdownService.getDropdownTranslationList(
      DistributorManagerEndpoints.config.BILLING_TYPE,
      {},
      { resultWithinProp: ['result', 'billingType'] }
    );
  }

  getLateChargeMethodsDropdownList() {
    return this._dropdownService.getDropdownTranslationList(
      DistributorManagerEndpoints.config.LATE_CHARGE_METHODS,
      {},
      { resultWithinProp: ['result', 'lateChargesMethod'] }
    );
  }

  getDocumentationDropdownList() {
    return this._dropdownService.getDropdownTranslationList(
      DistributorManagerEndpoints.config.DOCUMENTATION,
      {},
      { resultWithinProp: ['result', 'documentation'] }
    );
  }

  getPaymentMethodsDropdownList() {
    return this._dropdownService.getDropdownTranslationList(
      DistributorManagerEndpoints.config.PAYMENT_METHODS,
      {},
      { resultWithinProp: ['result', 'methods'] }
    );
  }

  getFinancialBlanketStatusesDropdownList() {
    return this._dropdownService.getDropdownTranslationList(
      DistributorManagerEndpoints.config.FINANCIAL_BLANKETS,
      {
        labelMapping: StatusConstant.financialBlanketsStatusLabelMapping,
        hasTranslation: true
      },
      { resultWithinProp: ['result', 'statuses'] }
    );
  }
}
