<p-table
  #primeTable
  [dataKey]="dataKey"
  [value]="data"
  [paginator]="true"
  [totalRecords]="totalRecords"
  [first]="paginatorFirst"
  [rows]="paginatorRows"
  [rowsPerPageOptions]="rowsPerPage"
  [showPageLinks]="true"
  [showFirstLastIcon]="true"
  [lazy]="isBackEndControlled"
  [globalFilterFields]="globalFilterFields"
  [selection]="selectedRows"
  (selectionChange)="selectedRows = $event.forceUpdate"
  (onPage)="handlePageChange($event)"
  (onSort)="sortPageChange.emit($event)"
  (onFilter)="onFilter($event)"
  (selectionChange)="handleSelectionChange($event)"
  [sortField]="sortField"
  [sortOrder]="sortOrder"
  [autoLayout]="true"
  class="nextTable"
  [showCurrentPageReport]="true"
  [currentPageReportTemplate]="'TABLE.PAGINATOR.TITLE' | translate"
>
  <ng-template pTemplate="header">
    <tr>
      <ng-container *ngIf="withSelection">
        <th class="next-table__checkbox"><p-tableHeaderCheckbox /></th>
      </ng-container>
      <ng-container *ngFor="let column of columns">
        <ng-container *ngIf="!column[TableFieldProps.IS_HIDDEN]">
          <th
            [ngStyle]="{
            'text-align': column.align || align.LEFT
          }"
            [ngClass]="column.width"
            *ngIf="column.isSortable; else normalTd"
            [pSortableColumn]="column.sortableField ? column.sortableField : column.field"
          >
            <div class="next-table__th-container">
              <span>{{ column.header! | translate }}</span>
              <p-sortIcon [field]="column.field"></p-sortIcon>
            </div>
          </th>
        </ng-container>
        <ng-template #normalTd>
          <th
            [ngStyle]="{
              'text-align': column.align || align.LEFT
            }"
            [ngClass]="[column.width] | appendText: ' '"
            [pSortableColumn]="column.field"
          >
            {{ column.header! | translate }}
          </th>
        </ng-template>
      </ng-container>
    </tr>
  </ng-template>
  <ng-template let-object pTemplate="body" let-expanded="expanded">
    <tr>
      <ng-container *ngIf="withSelection">
        <td>
          <p-tableCheckbox [value]="object"/>
        </td>
      </ng-container>
      <ng-container *ngFor="let column of columns">
        <ng-container *ngIf="!column[TableFieldProps.IS_HIDDEN]">
          <td
            [testIdDataAttr]="column.field"
            [ngStyle]="{
            'text-align': column.align || align.LEFT
          }"
          >
            <ng-container [ngSwitch]="column[TableFieldProps.TEMPLATE_TYPE]">
              <div *ngSwitchCase="TemplateType.ACTION" class="next-table__action-column">
                <link-button
                  [iconType]="actionIconType"
                  [iconColor]="expanded ? IconColor.BRAND_ACTION : IconColor.BRAND_PRIMARY"
                  [pRowTogglerDisabled]="!parsedExpandedTemplate"
                  [iconSize]="IconSizeModifier.REGULAR"
                  [pRowToggler]="object"
                  [testIdDataAttr]="TableTestId.SHOW_ACTION_BUTTON"
                  [iconPosition]="column.alignButton || LinkButtonPosition.LEFT"
                  (clickEvt)="clickViewActionEvt.emit(object)"
                ></link-button>
              </div>
              <div *ngSwitchCase="TemplateType.STATUS">
                <tag-status [status]="object[column.field!]" [statusMap]="column[TableFieldProps.STATUS_MAP]!">
                </tag-status>
              </div>
              <div *ngSwitchCase="TemplateType.MENU_ACTION">
                <options-menu [options]="object[column.field!]"></options-menu>
              </div>
              <div *ngSwitchCase="TemplateType.COLUMN_TEMPLATE">
                <ng-container
                  *ngIf="columnTemplateRef; else noColumnTemplate"
                  [ngTemplateOutlet]="columnTemplateRef"
                  [ngTemplateOutletContext]="{ data: object, columnConfig: column, index: data | getIndexOf: object }"
                >
                </ng-container>
                <ng-template #noColumnTemplate> noTemplate</ng-template>
              </div>
              <div *ngSwitchCase="TemplateType.TASK">
                <next-badge *ngIf="object[column.field]"
                  [value]="object[column.field!]"
                  [statusMap]="column[TableFieldProps.STATUS_MAP]!"
                ></next-badge>
              </div>
              <div *ngSwitchDefault>
                <ng-container *ngIf="diffData; else defaultTpl">
                  <span
                    [compareData]="object | getValue: column"
                    [refData]="mappedRefByDataKey | safeGuardObjectProperty: [object[dataKey]] | getValue: column"
                    [title]="column.tooltip ? object[column.tooltip!] : ''"
                  >{{ object | getValue: column }}</span>
                </ng-container>
                <ng-template #defaultTpl>
                  <span [title]="column.tooltip ? object[column.tooltip!] : ''">
                    {{ object | getValue: column }}
                  </span>
                </ng-template>
              </div>
            </ng-container>
          </td>
        </ng-container>
      </ng-container>
    </tr>
  </ng-template>

  <ng-template pTemplate="footer" let-object let-column>
    <tr class="next-table__footer" *ngIf="footerTemplateRef">
      <td [attr.colspan]="withSelection ? this.columns.length + 1 : this.columns.length">
        <ng-container
          [ngTemplateOutlet]="footerTemplateRef"
          [ngTemplateOutletContext]="{ data: object, columnConfig: column, index: data | getIndexOf: object }"
        >
        </ng-container>
      </td>
    </tr>
  </ng-template>

  <ng-template pTemplate="paginatorleft">
    <p-button type="button" icon="pi pi-plus" styleClass="p-button-text" />
    <ng-container *ngIf="showDownloadButton">
      <next-button
        [label]="'BUTTONS.DOWNLOAD_FILTERED_LIST' | translate"
        [type]="ButtonType.SECONDARY"
        (clickEvent)="downloadExcel()"
      ></next-button>
    </ng-container>
  </ng-template>
  
  <ng-template pTemplate="rowexpansion" let-expandedObject>
    <tr>
      <td [attr.colspan]="this.columns.length">
        <ng-container
          [ngTemplateOutlet]="parsedExpandedTemplate"
          [ngTemplateOutletContext]="{ $implicit: expandedObject, index: data | getIndexOf: expandedObject }"
        ></ng-container>
      </td>
    </tr>
  </ng-template>

  <ng-template pTemplate="emptymessage">
    <tr>
      <td [attr.colspan]="this.columns.length">
        <div class="next-table__table-empty">No data</div>
      </td>
    </tr>
  </ng-template>
</p-table>
