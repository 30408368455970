import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { GenericObject } from '@utils/models/Types';

@Component({
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormArrayAdminAbstractComponent<EntityType extends { index?: number }, PopupType = any> {
  @Input() formArray?: FormArray;

  isEdition: boolean;
  editionIndex: number | undefined;
  currentEntity: EntityType | GenericObject;
  entityList: EntityType[];
  activePopup: PopupType | undefined;
  forceViewMode: boolean = false;

  handleAdd(formArray: FormArray, formGroup: FormGroup) {
    if (this.editionIndex) {
      formArray.removeAt(this.editionIndex);
      formArray.insert(this.editionIndex, formGroup);
      this.editionIndex = undefined;
    } else {
      formArray.push(formGroup);
    }

    this.dismissPopup();
  }

  enablePopup(popup: PopupType, entity: EntityType) {
    this.activePopup = popup;
    this.currentEntity = entity;
    this.editionIndex = entity?.index;
    this.forceViewMode = false;
  }

  viewDetails(popupType: PopupType, entity: EntityType) {
    this.activePopup = popupType;
    this.forceViewMode = true;
    this.currentEntity = entity;
  }

  handleRemove(formArray: FormArray, index: number) {
    formArray.removeAt(index);
  }

  dismissPopup() {
    this.currentEntity = {};
    this.activePopup = undefined;
    this.forceViewMode = false;
  }
}
