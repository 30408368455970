import { Injectable } from '@angular/core';
import * as XLSX from 'xlsx';
import { CountryConstant } from '@pages/general-maintenance/pages/country/constant/country.constant';
import { DataTitleProps } from '@components/atoms/visual/components/data-title/data-title.enum';
import { TranslateService } from '@ngx-translate/core';
import { catchError, forkJoin, of } from 'rxjs';
import { DistributorManagerConstant } from '@pages/distributor/constant/distributor-manager.constant';
import { NextNumberHelper } from '@utils/core/next-number.helper';
import { NextDateHelper } from '@utils/core/next-date.helper';
import { CountryProps } from '@pages/general-maintenance/pages/country/enums/country.enum';

@Injectable({
  providedIn: 'root'
})
export class DownloadService {

  headers: any[] = [];
  data: any[][] = [];
  fileName: string = '';
  excelType: string = '';

  constructor(private _translateService: TranslateService) {}

  generateExcel() {
    let dataFiltered: any[][] = [];
    switch (this.excelType) {
      case CountryConstant.fileType:
        dataFiltered = this.getDownloadCountryData();
        break;
      case DistributorManagerConstant.fileType:
        dataFiltered = this.getDownloadDistributorManagerData();
        break;
      default:
        break;
    }
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet([this.headers, ...dataFiltered]);
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Page-1');
    XLSX.writeFile(workbook, `${this.fileName}.xlsx`);
  }

  private getDownloadCountryData(): any[] {
    this.fileName = CountryConstant.fileExcelName;
    const titles = CountryConstant.countryDownloadConfig.map(config => config[DataTitleProps.TITLE_KEY]);
    this.getHeadersTranslations(titles);
    const keys = CountryConstant.countryDownloadConfig.flatMap(config => config[DataTitleProps.PATH]);
    return this.filterProperties(this.data, keys, CountryProps.INSURANCE_EXCEPTION_CURRENCY);
  }

  private getDownloadDistributorManagerData(): any[] {
    this.fileName = DistributorManagerConstant.fileExcelName;
    const titles = DistributorManagerConstant.distributorManagerDownloadConfig.map(config => config[DataTitleProps.TITLE_KEY]);
    this.getHeadersTranslations(titles);
    const keys = DistributorManagerConstant.distributorManagerDownloadConfig.flatMap(config => config[DataTitleProps.PATH]);
    return this.filterProperties(this.data, keys);
  }

  private getHeadersTranslations(titles: any[]) {
    const translationObservables = titles.map(title =>
      this._translateService.get(title).pipe(
        catchError(error => {
          return of(`Error: ${title} :: ${error.message}`);
        })
      )
    );
    forkJoin(translationObservables).subscribe(translatedHeaders => {
      this.setHeaders(translatedHeaders);
    });
  }

  private filterProperties(array: any[], keys: any[], FildCurrency: string = ''): any[] {
    return array.map(item => {
      return keys.map(key => {
        let data = item[key];
        if ( NextDateHelper.isDate(data)) {
          data = NextDateHelper.convertDate(data);
        }
        if ( NextNumberHelper.isConvertibleToNumber(data)) {
          data = NextNumberHelper.currencyFormat(data, item[FildCurrency])
        }
        return data;
      })
    });
  }

  setExcelType(excelType: string) {
    this.excelType = excelType;
  }

  setHeaders(headers: any[]) {
    this.headers = headers;
  }

  setData(data: any)  {
    this.data = data;
  }

}
