import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { NextDateHelper } from '@utils/core/next-date.helper';
import { DateFormat } from '../../../core/enums/date.enum';

@Pipe({
  name: 'nextFormatDate',
  standalone: true
})
export class NextFormatDatePipe implements PipeTransform {
  constructor(private _datePipe: DatePipe) {}

  transform(value: any, hideTime = true): any {
    const format = hideTime ? DateFormat.DATE : DateFormat.DATE_TIME;
    const isDate = NextDateHelper.isValidDate(value);

    if (isDate) {
      return this._datePipe.transform(value, format);
    }
  }
}
