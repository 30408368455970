import { GenericObject } from '@utils/models/Types';
import { FormSection } from '@components/organisms/layout/types/form-section.type';
import {
  DistributorGroupSection,
  DistributorGroupSectionPath
} from '@pages/distributor/enums/distributor-manager.enum';
import { FormSectionProps } from '@components/organisms/layout/enum/form-section.enum';
import { DistributorGroupProps } from '@pages/distributor/enums/distributor-group.enum';
import { DistributorManagerEndpoints } from '@pages/distributor/constant/distributor-manager.endpoints.constant';
import { SecuritiesCreditLimitConstant } from '@pages/distributor/constant/securities-credit-limit.constant';
import { AddressProps } from '../../../core/enums/address.enum';
import { ContactProps } from '../../../core/enums/contact.enum';
import { SecurityType } from '@pages/distributor/enums/securities.enum';
import { CommonBooleanLabelPipe } from '@pipes/boolean-label.pipe';
import { NextFormatDatePipe } from '@pipes/common/custom-date.pipe';
import { CountryProps } from '@pages/general-maintenance/pages/country/enums/country.enum';
import { TableField } from '@components/organisms/next-table/next-table.type';
import { TableFieldProps } from '@components/organisms/next-table/enums/next-table.enum';
import { SCLProps } from '@pages/distributor/constant/securities-credit-limits.enum';

export class DistributorGroupConstant {
  static generalInformationKeyPrefix = 'DISTRIBUTOR.MANAGER.DETAILS.TABS.GENERAL_INFORMATION';

  static formSectionConfig: GenericObject<FormSection> = {
    [DistributorGroupSection.GENERAL_INFORMATION]: {
      [FormSectionProps.ID]: DistributorGroupSection.GENERAL_INFORMATION,
      [FormSectionProps.LABEL_KEY]: 'DISTRIBUTOR.GROUP.GENERAL_INFORMATION.FORM_TITLE',
      [FormSectionProps.PATH]: DistributorGroupSectionPath.GENERAL_INFORMATION
    },
    [DistributorGroupSection.SECURITIES_AND_CREDIT_LIMITS]: {
      [FormSectionProps.ID]: DistributorGroupSection.SECURITIES_AND_CREDIT_LIMITS,
      [FormSectionProps.LABEL_KEY]: 'DISTRIBUTOR.GROUP.SECURITIES_AND_CREDIT_LIMITS.FORM_TITLE',
      [FormSectionProps.PATH]: DistributorGroupSectionPath.SECURITIES_AND_CREDIT_LIMITS
    },
    [DistributorGroupSection.DISTRIBUTOR_CREDIT_LIMITS]: {
      [FormSectionProps.ID]: DistributorGroupSection.DISTRIBUTOR_CREDIT_LIMITS,
      [FormSectionProps.LABEL_KEY]: 'DISTRIBUTOR.GROUP.DISTRIBUTOR_CREDIT_LIMITS.FORM_TITLE',
      [FormSectionProps.PATH]: DistributorGroupSectionPath.DISTRIBUTOR_CREDIT_LIMITS
    }
  };

  static formSectionList = [
    DistributorGroupSection.GENERAL_INFORMATION,
    DistributorGroupSection.SECURITIES_AND_CREDIT_LIMITS,
    DistributorGroupSection.DISTRIBUTOR_CREDIT_LIMITS
  ];

  static formMaxLength = {
    [DistributorGroupProps.NAME]: 75,
    [DistributorGroupProps.ID_NUMBER]: 20,
    [DistributorGroupProps.VAT_NUMBER]: 16,
    [DistributorGroupProps.POSTAL_CODE]: 10,
    [DistributorGroupProps.TOWN]: 35,
    [DistributorGroupProps.WORK_TELEPHONE]: 30,
    [DistributorGroupProps.CONTACT_NAME]: 35
  };

  static endpointItemBySection = {
    [DistributorGroupSection.GENERAL_INFORMATION]: DistributorManagerEndpoints.config.GROUP.GENERAL_INFO,
    [DistributorGroupSection.SECURITIES_AND_CREDIT_LIMITS]:
      DistributorManagerEndpoints.config.GROUP.SECURITIES_AND_CREDIT_LIMITS,
    [DistributorGroupSection.DISTRIBUTOR_CREDIT_LIMITS]:
      DistributorManagerEndpoints.config.GROUP.DISTRIBUTOR_CREDIT_LIMITS
  };

  static SCLGroupCreditLimitsAmount = {
    ...SecuritiesCreditLimitConstant.SCLBaseCreditLimitsAmount,
    titleKey: `${SecuritiesCreditLimitConstant.securitiesCreditLimitsKeyPrefix}.FIELDS.GROUP_CREDIT_LIMIT_AMOUNT`
  };

  static generalInformationConfigMapperI = [
    {
      titleKey: `${DistributorGroupConstant.generalInformationKeyPrefix}.COUNTRY_CODE`,
      path: [DistributorGroupProps.COUNTRY, CountryProps.CODE]
    },
    {
      titleKey: `${DistributorGroupConstant.generalInformationKeyPrefix}.COUNTRY_MEMBERSHIP`,
      path: [DistributorGroupProps.COUNTRY, CountryProps.MEMBERSHIP]
    },
    {
      titleKey: `${DistributorGroupConstant.generalInformationKeyPrefix}.CREATION_DATE`,
      path: [DistributorGroupProps.CREATION_DATE],
      pipeList: [{ pipe: NextFormatDatePipe }]
    },
    {
      titleKey: `${DistributorGroupConstant.generalInformationKeyPrefix}.FISCAL_ID`,
      path: [DistributorGroupProps.ID_NUMBER]
    },
    {
      titleKey: `${DistributorGroupConstant.generalInformationKeyPrefix}.DELETION_SUSPENSION_ORDER`,
      path: [DistributorGroupProps.DELETION_SUSPENSION_ORDER],
      pipeList: [{ pipe: CommonBooleanLabelPipe }]
    }
  ];
  static generalInformationConfigMapperII = [
    {
      titleKey: `${DistributorGroupConstant.generalInformationKeyPrefix}.COMPANY_ADDRESS`,
      path: [DistributorGroupProps.ADDRESS, AddressProps.ADDRESS]
    },
    {
      titleKey: `${DistributorGroupConstant.generalInformationKeyPrefix}.POSTAL_CODE`,
      path: [DistributorGroupProps.ADDRESS, AddressProps.POSTAL_CODE]
    },
    {
      titleKey: `${DistributorGroupConstant.generalInformationKeyPrefix}.TOWN`,
      path: [DistributorGroupProps.ADDRESS, AddressProps.TOWN]
    },
    {
      titleKey: `${DistributorGroupConstant.generalInformationKeyPrefix}.CONTACT_NAME`,
      path: [DistributorGroupProps.CONTACT, ContactProps.NAME]
    },
    {
      titleKey: `${DistributorGroupConstant.generalInformationKeyPrefix}.WORK_TELEPHONE`,
      path: [DistributorGroupProps.CONTACT, ContactProps.PHONE]
    }
  ];

  static compareDataMapper = [
    {
      titleKey: `${DistributorGroupConstant.generalInformationKeyPrefix}`,
      childrenKey: [DistributorGroupSection.GENERAL_INFORMATION],
      children: [
        ...DistributorGroupConstant.generalInformationConfigMapperI,
        ...DistributorGroupConstant.generalInformationConfigMapperI
      ]
    },
    {
      titleKey: `${SecuritiesCreditLimitConstant.securitiesCreditLimitsKeyPrefix}.NAME`,
      childrenKey: [DistributorGroupSection.SECURITIES_AND_CREDIT_LIMITS],
      children: [
        {
          titleKey: `${SecuritiesCreditLimitConstant.securitiesCreditLimitsKeyPrefix}.TITLES.CREDIT_LIMITS`,
          children: [
            SecuritiesCreditLimitConstant.SCLDistributorCreditLimitsAmount,
            SecuritiesCreditLimitConstant.SCLDistributorGroupCreditLimitAmount,
            SecuritiesCreditLimitConstant.SCLDistributorBankGuarantee,
            SecuritiesCreditLimitConstant.SCLDistributorStandbyLetter,
            SecuritiesCreditLimitConstant.SCLDistributorFDI,
            ...SecuritiesCreditLimitConstant.SCLDistributorCreditLimits
          ]
        },
        {
          titleKey: `${SecuritiesCreditLimitConstant.securitiesCreditLimitsKeyPrefix}.TITLES.FUNDING_BANK`,
          isArray: true,
          childrenKey: [SCLProps.CREDIT_BANKS],
          children: [...SecuritiesCreditLimitConstant.SCLDistributorFundingBanks]
        },
        {
          titleKey: SecuritiesCreditLimitConstant.SCLSecuritiesGuaranteeTitle,
          childrenKey: [SCLProps.SECURITIES, SecurityType.GUARANTORS],
          children: SecuritiesCreditLimitConstant.SCLSecurities,
          isArray: true
        },
        {
          titleKey: SecuritiesCreditLimitConstant.SCLSecuritiesInsurerTitle,
          childrenKey: [SCLProps.SECURITIES, SecurityType.INSURERS],
          children: SecuritiesCreditLimitConstant.SCLSecurities,
          isArray: true
        }
      ]
    },
    { titleKey: ``, childrenKey: DistributorGroupSection.DISTRIBUTOR_CREDIT_LIMITS }
  ];

  static distributorLimitsTable: TableField[] = [
    {
      [TableFieldProps.FIELD]: DistributorGroupProps.ID,
      [TableFieldProps.HEADER]: 'DISTRIBUTOR.FIELDS.DISTRIBUTOR_ID'
    },
    {
      [TableFieldProps.FIELD]: DistributorGroupProps.NAME,
      [TableFieldProps.HEADER]: 'DISTRIBUTOR.FIELDS.DISTRIBUTOR_NAME'
    },
    {
      [TableFieldProps.FIELD]: DistributorGroupProps.BANK_GUARANTEE_AMOUNT,
      [TableFieldProps.HEADER]: 'DISTRIBUTOR.FIELDS.BANK_GUARANTEE'
    },
    {
      [TableFieldProps.FIELD]: DistributorGroupProps.FDI_AMOUNT,
      [TableFieldProps.HEADER]: 'DISTRIBUTOR.FIELDS.FDI'
    },
    {
      [TableFieldProps.FIELD]: DistributorGroupProps.SBLC,
      [TableFieldProps.HEADER]: 'DISTRIBUTOR.FIELDS.SBLC'
    },
    {
      [TableFieldProps.FIELD]: DistributorGroupProps.COMPANY_SEC,
      [TableFieldProps.HEADER]: 'DISTRIBUTOR.FIELDS.COMPANY_SEC'
    }
  ];
}
