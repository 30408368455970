import { Injectable } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { NextFormHelper } from '@utils/core/next-form.helper';
import { FormConfig } from '@utils/models/Form';
import { NextInputValidators } from '../../../shared/validators/input.validator';
import { SourceLocationProps } from '@pages/distributor/enums/source-location-mapping.enum';

@Injectable({
  providedIn: 'root'
})
export class SourceLocationMappingAdminService {
  constructor(private _fb: FormBuilder) {}

  buildSourceLocationMappingFormArray(sourceLocationMappingList: any = [], isEdit?: boolean) {
    const groups = sourceLocationMappingList.map((sourceLocationMapping: any) =>
      this.buildSourceLocationMappingFormGroup(sourceLocationMapping, isEdit)
    );

    return this._fb.array(groups, [NextInputValidators.requiredNoBlank]);
  }

  buildSourceLocationMappingFormGroup(sourceLocationMapping: any = {}, isEdit: boolean = false) {
    const { props, config } = this._buildSourceLocationMappingConfigValues(sourceLocationMapping, isEdit);
    const formGroupState = NextFormHelper.buildGroupValuesWithConfig(props, sourceLocationMapping, config);
    return this._fb.group(formGroupState);
  }

  private _buildSourceLocationMappingConfigValues(sourceLocationMapping: any = {}, isEdit: boolean = false) {
    const props = [
      SourceLocationProps.SOURCE_LOCATION_NAME,
      SourceLocationProps.SOURCE_LOCATION_CODE,
      SourceLocationProps.FTP_PROCESSING_CODE
    ];

    const config: FormConfig = {
      [SourceLocationProps.SOURCE_LOCATION_NAME]: {
        isRequired: true,
        isDisabled: !isEdit
      },
      [SourceLocationProps.SOURCE_LOCATION_CODE]: {
        isRequired: !!sourceLocationMapping['isFTPProcessing'],
        isDisabled: !isEdit
      },
      [SourceLocationProps.FTP_PROCESSING_CODE]: {
        isRequired: !!sourceLocationMapping['isFTPProcessing'],
        isDisabled: !isEdit
      }
    };

    return { props, config };
  }
}
