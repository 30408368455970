export enum SourceLocationMappingActions {
  VIEW = 'VIEW',
  MODIFY = 'MODIFY',
  DELETE = 'DELETE'
}

export enum SourceLocationProps {
  SOURCE_LOCATION_CODE = 'sourceLocationCode',
  SOURCE_LOCATION_NAME = 'sourceLocationName',
  FTP_PROCESSING_CODE = 'ftpBatchCode',
  BENEFICIARY_NAME = 'beneficiaryName',
  CREATE_PTSL_FILE = 'createPTSLfile',
  GL_ACCOUNT_NO = 'glAccountNo'
}
