import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'commonBooleanLabel',
  standalone: true
})
export class CommonBooleanLabelPipe implements PipeTransform {
  static readonly defaultTrueValue = 'Yes';
  static readonly defaultFalseValue = 'No';
  transform(
    value: boolean,
    trueKey = CommonBooleanLabelPipe.defaultTrueValue,
    falseKey = CommonBooleanLabelPipe.defaultFalseValue
  ): string {
    return value ? trueKey : falseKey;
  }
}
