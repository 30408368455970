import { NextRouteItem } from 'app/core/types/route.type';
import { NextRouteProps, RouteParams } from 'app/core/enums/route.enum';
import { NavItemId } from 'app/shared/enums/navigation.enum';
import { NextPermissionId } from 'app/core/enums/permission.enum';
import { GeneralMaintenanceRoutingConfig } from '@pages/general-maintenance/general-maintenance.route.config';

export class CountryRouteConfig {
  static readonly mainPath = 'country';
  static readonly listConfig: NextRouteItem = {
    [NextRouteProps.ID]: NavItemId.COUNTRY,
    label: 'COUNTRY.TITLE',
    [NextRouteProps.PATH]: CountryRouteConfig.mainPath,
    [NextRouteProps.PARENT]: GeneralMaintenanceRoutingConfig.mainRoute,
    [NextRouteProps.PERMISSION]: [NextPermissionId.COUNTRIES]
  };

  static readonly countryFormId: NextRouteItem = {
    id: 'countryFormId',
    path: `:${RouteParams.ID}`,
    pathParam: `${RouteParams.ID}`,
    fromUrlParamPath: true,
    parent: CountryRouteConfig.listConfig,
    disabledBreadcrumbItem: true
  };
}
