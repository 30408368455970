<div class="securities-credit-limits">
  <next-card [hasBackgroundColor]="hasBackgroundColor" [isHorizontalContent]="isHorizontalContent">
    <header-detail
      [header]="(TITLE_SECURITIES_PREFIX + (isGroup ? '.TITLES.GROUP_CREDIT_LIMITS' : '.TITLES.CREDIT_LIMITS')) | translate"
      headerTemplate></header-detail
    >
    <ng-container>
      <ng-container *ngIf="isGroup; else distributorCreditLimitTpl">
        <data-card
          [dataObject]="data"
          [dataObjectRef]="dataRef"
          [config]="SecuritiesCreditLimitConstant.SCLDistributorCreditLimitsAmount"
          class="group-details__item" [hasBorderCard]="true"
        ></data-card>
      </ng-container>
    </ng-container>
    <ng-template #distributorCreditLimitTpl>
      <data-card
        *ngIf="data | safeGuardObjectProperty: [DistributorProps.BELONGS_TO_GROUP]"
        [dataObject]="data"
        [dataObjectRef]="dataRef"
        [config]="SecuritiesCreditLimitConstant.SCLDistributorGroupCreditLimitAmount "
        class="group-details__item" [hasBorderCard]="true"
      ></data-card>
      <data-card
        [dataObject]="data"
        [dataObjectRef]="dataRef"
        [config]="SecuritiesCreditLimitConstant.SCLDistributorCreditLimitsAmount"
        class="group-details__item" [hasBorderCard]="true"
      ></data-card>
    </ng-template>
  </next-card>
  <next-card *ngIf="!isGroup" [isHorizontalContent]="isHorizontalContent" [hasBackgroundColor]="hasBackgroundColor">
    <ng-container>
      <data-card
        [dataObject]="data"
        [dataObjectRef]="dataRef"
        [config]="SecuritiesCreditLimitConstant.SCLDistributorBankGuarantee"
        class="group-details__item" [hasBorderCard]="true"
      ></data-card>
      <data-card
        [dataObject]="data"
        [dataObjectRef]="dataRef"
        [config]="SecuritiesCreditLimitConstant.SCLDistributorStandbyLetter"
        class="group-details__item" [hasBorderCard]="true"
      ></data-card>
      <data-card
        [dataObject]="data"
        [dataObjectRef]="dataRef"
        [config]="SecuritiesCreditLimitConstant.SCLDistributorFDI"
        class="group-details__item" [hasBorderCard]="true"
      ></data-card>
    </ng-container>
  </next-card>
  <next-card [hasInnerBorder]="true" [isHorizontalContent]="isHorizontalContent">
    <ng-container>
      <data-card
        [dataObject]="data"
        [configList]="SecuritiesCreditLimitConstant.SCLDistributorCreditLimits"
        class="group-details__item" [isHorizontalContent]="true"
      ></data-card>
    </ng-container>
  </next-card>
  <next-card [hasInnerBorder]="true" [isHorizontalContent]="isHorizontalContent">
    <header-detail
      [header]="(TITLE_SECURITIES_PREFIX + '.TITLES.FUNDING_BANK') | translate" headerTemplate></header-detail
    >
    <ng-container>
      <data-card
        *ngFor="let bank of (data | safeGuardObjectProperty: [SCLProps.CREDIT_BANKS]); index as i"
        [dataObject]="bank"
        [dataObjectRef]="dataRef | safeGuardObjectProperty: [i, SCLProps.CREDIT_BANKS]"
        [configList]="SecuritiesCreditLimitConstant.SCLDistributorFundingBanks"
        class="group-details__items" [isHorizontalData]="true"
      ></data-card>
    </ng-container>
  </next-card>
  <next-card [hasInnerBorder]="true">
    <header-detail
      [header]="SecuritiesCreditLimitConstant.SCLSecuritiesGuaranteeTitle | translate" headerTemplate
    ></header-detail>
    <div class="group-details__table">
      <next-table
        [dataKey]="GuarantorProps.ID"
        [data]="data | safeGuardObjectProperty: [SCLProps.SECURITIES, SecuritiesProps.GUARANTORS]"
        [dataRef]="dataRef | safeGuardObjectProperty: [SCLProps.SECURITIES, SecuritiesProps.GUARANTORS]"
        [columns]="SecuritiesCreditLimitConstant.tableListSecuritiesGuaranteeConfig"
        (clickViewActionEvt)="viewPopup( DistributorDetailsSecuritiesSection.SECURITIES_GUARANTEE, $event)"
      ></next-table>
    </div>
  </next-card>
  <next-card [hasInnerBorder]="true">
    <header-detail
      [header]="SecuritiesCreditLimitConstant.SCLSecuritiesInsurerTitle | translate" headerTemplate
    ></header-detail>
    <div class="group-details__table">
      <next-table
        [dataKey]="InsurerProps.ID"
        [data]="data | safeGuardObjectProperty: [SCLProps.SECURITIES, SecuritiesProps.INSURERS]"
        [dataRef]="dataRef | safeGuardObjectProperty: [SCLProps.SECURITIES, SecuritiesProps.INSURERS]"
        [columns]="SecuritiesCreditLimitConstant.tableListSecuritiesInsurerConfig"
        (clickViewActionEvt)="viewPopup(DistributorDetailsSecuritiesSection.SECURITIES_INSURER, $event)"
      ></next-table>
    </div>
  </next-card>

  <next-dialog *ngIf="selectedSecurities"
               [visible]="activePopup === DistributorDetailsSecuritiesSection.SECURITIES_GUARANTEE"
               [titleKey]="(TITLE_SECURITIES_PREFIX + (isGroup ? '.TITLES.SECURITIES_GUARANTEE_GROUP' : '.TITLES.SECURITIES_GUARANTEE'))"
               [submitLabelKey]="'BUTTONS.CLOSE'"
               [hideDismiss]="hideDismiss"
               [hideCancelButton]="hideCancelButton"
               [dialogSize]="dialogSize"
               (visibleChange)="dismissModals()" (submitClick)="dismissModals()">
    <div class="details-dialog__content" dialogContent>
      <next-card>
        <data-card *ngIf="!isGroup"
                   [dataObject]="selectedSecurities"
                   [configList]="[{titleKey: (TITLE_SECURITIES_PREFIX + '.FIELDS.INSURER_NAME'), path: [InsurerProps.NAME]},
                    {titleKey: (TITLE_SECURITIES_PREFIX + '.FIELDS.SECURITY_STATUS'), path: [GuarantorProps.STATUS]}]"
                   class="group-details-modal__item"
                   [isHorizontalContent]="true"
        ></data-card>
        <data-card *ngIf="isGroup"
                   [dataObject]="selectedSecurities"
                   [configList]="[{titleKey: (TITLE_SECURITIES_PREFIX + '.FIELDS.GUARANTOR_NAME'), path: [GuarantorProps.NAME]},
                    {titleKey: (TITLE_SECURITIES_PREFIX + '.FIELDS.SECURITY_STATUS'), path: [GuarantorProps.STATUS]}]"
                   class="group-details-modal__item"
                   [isHorizontalContent]="true"
        ></data-card>
        <data-card *ngIf="isGroup"
                   [dataObject]="selectedSecurities"
                   [configList]="[{titleKey: (TITLE_SECURITIES_PREFIX + '.FIELDS.MOODYS_RATING'), path: [InsurerProps.MOODYS_RATING]},
                    {titleKey: (TITLE_SECURITIES_PREFIX + '.FIELDS.SPS_RATING'), path: [InsurerProps.SPS_RATING]}]"
                   class="group-details-modal__item"
                   [isHorizontalContent]="true"
        ></data-card>
      </next-card>
    </div>
  </next-dialog>

  <next-dialog *ngIf="selectedSecurities"
               [visible]="activePopup === DistributorDetailsSecuritiesSection.SECURITIES_INSURER"
               [titleKey]="(TITLE_SECURITIES_PREFIX + (isGroup ? '.TITLES.SECURITIES_INSURER_GROUP' : '.TITLES.SECURITIES_INSURER'))"
               [submitLabelKey]="'BUTTONS.CLOSE'"
               [hideDismiss]="hideDismiss"
               [hideCancelButton]="hideCancelButton"
               [dialogSize]="dialogSize"
               (visibleChange)="dismissModals()" (submitClick)="dismissModals()">
    <div class="details-dialog__content" dialogContent>
      <next-card>
        <data-card *ngIf="!isGroup"
                   [dataObject]="selectedSecurities"
                   [config]="{titleKey: (TITLE_SECURITIES_PREFIX + '.FIELDS.COUNTRY_RYSK_DEBTOR'), path: [InsurerProps.COUNTRY_RISK_DEBTOR]}"
                   class="group-details-modal__item"
        ></data-card>
        <data-card *ngIf="isGroup"
                   [dataObject]="selectedSecurities"
                   [configList]="[{titleKey: (TITLE_SECURITIES_PREFIX + '.FIELDS.INSURER_NAME'), path: [InsurerProps.NAME]},
                    {titleKey: (TITLE_SECURITIES_PREFIX + '.FIELDS.COUNTRY_RYSK_DEBTOR'), path: [InsurerProps.COUNTRY_RISK_DEBTOR]}]"
                   class="group-details-modal__item"
                   [isHorizontalContent]="true"
        ></data-card>
        <data-card
          [dataObject]="selectedSecurities"
          [configList]="[{titleKey: (TITLE_SECURITIES_PREFIX + '.FIELDS.INSURANCE_COUNTRY_RYSK'), path: [InsurerProps.INSURANCE_COUNTRY_RYSK]},
                    {titleKey: (TITLE_SECURITIES_PREFIX + '.FIELDS.COUNTRY_RYSK_RATING'), path: [InsurerProps.COUNTRY_RISK_RATING]}]"
          class="group-details-modal__item"
          [isHorizontalContent]="true"
        ></data-card>
        <data-card
          [dataObject]="selectedSecurities"
          [configList]="[{titleKey: (TITLE_SECURITIES_PREFIX + '.FIELDS.STANDARD_LIMIT'), path: [InsurerProps.STANDARD_LIMIT]},
                    {titleKey: (TITLE_SECURITIES_PREFIX + '.FIELDS.EXCEPTION_LIMIT'), path: [InsurerProps.EXCEPTION_LIMIT]}]"
          class="group-details-modal__item"
          [isHorizontalContent]="true"
        ></data-card>
      </next-card>
    </div>
  </next-dialog>
</div>
