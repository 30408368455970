export enum DistributorGroupProps {
  ID = 'id',
  CODE = 'code',
  NAME = 'name',
  STATUS = 'status',
  GROUP_OPERATES_WITH_SWING_LINES = 'linesSwingEnabled',
  COUNTRY = 'country',
  COUNTRY_MEMBERSHIP = 'countryMembership',
  ID_NUMBER = 'fiscalId',
  CREATION_DATE = 'createdTimestamp',
  VAT_NUMBER = 'vatId',
  CONTACT = 'contact',
  ADDRESS = 'address',
  TOWN = 'town',
  POSTAL_CODE = 'postalCode',
  CONTACT_NAME = 'contactName',
  WORK_TELEPHONE = 'workTelephone',
  DELETION_SUSPENSION_ORDER = 'boundConceptsDeletionDisabled',
  LAST_MODIFIED_DATE = 'lastModifiedDate',
  // Securities & Credit Limits
  BELONGS_TO_GROUP = 'belongsToGroup',
  CREDIT_LIMIT_AMOUNT = 'creditLimitAmount',
  GROUP = 'distributorGroup',
  CURRENCY = 'currency',
  EFFECTIVE_DATE = 'effectiveDate',
  EXPIRY_DATE = 'expiryDate',
  PACK_APPROVAL_DATE = 'packApprovalDate',
  CREDIT_LIMIT_STATUS = 'creditLimitStatus',
  BANK_GUARANTEE_AMOUNT = 'bankGuaranteeAmount',
  BANK_GUARANTEE_CURRENCY = 'bankGuaranteeCurrency',
  FDI_AMOUNT = 'fdiAmount',
  FDI_CURRENCY = 'fdiCurrency',
  SBLC = 'sblc',
  COMPANY_SEC = 'companySec',
  // Distributor Credit Limits
  DISTRIBUTOR_CREDIT_LIMIT_AMOUNT = 'distributorCreditLimitAmount',
  DISTRIBUTOR_NAME = 'distributorName',
  DISTRIBUTOR_CODE = 'distributorCode',
  DISTRIBUTOR_CURRENCY = 'distributorCurrency',
  TOTAL_GROUP_CREDIT_LIMIT = 'totalGroupCreditLimit'
}

export enum GroupSecuritiesProps {}
