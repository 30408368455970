import { NextRouteItem } from '../../core/types/route.type';
import { NextRouteProps, RouteParams } from '../../core/enums/route.enum';
import { BaseRouteConfig } from '../../core/constants/base.route.config';
import { NavItemId } from '../../shared/enums/navigation.enum';
import { NextPermissionId } from '../../core/enums/permission.enum';
import { TaskBoardRouteConfig } from '@pages/task-board/task-board.route.config';

export class DistributorManagerRouteConfig {
  static readonly mainPath = 'distributor-manager';
  static readonly detailPath = 'detail';
  static readonly distributorPath = 'distributor';
  static readonly distributorGroupPath = 'distributor-group';

  // Distributor Manager
  static readonly mainRoute = {
    id: NavItemId.DISTRIBUTOR_MANAGER,
    label: 'DISTRIBUTOR.MANAGER.TITLE',
    path: DistributorManagerRouteConfig.mainPath,
    [NextRouteProps.PERMISSION]: [NextPermissionId.DISTRIBUTORS, NextPermissionId.DISTRIBUTOR_GROUPS]
  };

  // Distributor
  static readonly distributorConfig: NextRouteItem = {
    id: 'distributorForm',
    label: 'DISTRIBUTOR.TITLE',
    path: DistributorManagerRouteConfig.distributorPath,
    parent: [DistributorManagerRouteConfig.mainRoute, TaskBoardRouteConfig.distributors],
    disabledBreadcrumbItem: true
  };

  static readonly distributorFormId: NextRouteItem = {
    id: 'distributorFormId',
    path: `:${RouteParams.ID}`,
    pathParam: `${RouteParams.ID}`,
    fromUrlParamPath: true,
    parent: DistributorManagerRouteConfig.distributorConfig,
    disabledBreadcrumbItem: true
  };

  static readonly distributorFormStep: NextRouteItem = {
    ...BaseRouteConfig.sectionBase,
    id: 'formStep',
    parent: DistributorManagerRouteConfig.distributorFormId
  };

  static readonly distributorDetailConfig: NextRouteItem = {
    id: 'distributorDetail',
    label: 'detail',
    path: DistributorManagerRouteConfig.detailPath,
    parent: DistributorManagerRouteConfig.distributorConfig,
    disabledBreadcrumbItem: true,
    fromUrlParamPath: false
  };

  static readonly distributorDetail: NextRouteItem = {
    id: 'distributorDetailId',
    label: 'view Distributor',
    path: `:${RouteParams.ID}`,
    pathParam: `${RouteParams.ID}`,
    fromUrlParamPath: true,
    disabledBreadcrumbItem: true,
    parent: DistributorManagerRouteConfig.distributorDetailConfig
  };

  static readonly distributorDetailTabStep: NextRouteItem = {
    ...BaseRouteConfig.sectionBase,
    parent: DistributorManagerRouteConfig.distributorDetail
  };

  // Distributor Group
  static readonly groupForm: NextRouteItem = {
    id: 'groupAddEdit',
    label: 'DISTRIBUTOR.GROUP.BREADCRUMB_FORM_TITLE',
    path: DistributorManagerRouteConfig.distributorGroupPath,
    parent: DistributorManagerRouteConfig.mainRoute,
    disabledBreadcrumbItem: true
  };

  static readonly groupFormId: NextRouteItem = {
    id: 'groupFormId',
    path: `:${RouteParams.ID}`,
    pathParam: `${RouteParams.ID}`,
    fromUrlParamPath: true,
    parent: DistributorManagerRouteConfig.groupForm,
    disabledBreadcrumbItem: true
  };

  static readonly groupFormStep: NextRouteItem = {
    ...BaseRouteConfig.sectionBase,
    id: 'formStep',
    label: 'FORM',
    parent: DistributorManagerRouteConfig.groupFormId
  };

  static readonly groupDetailConfig: NextRouteItem = {
    id: 'distributorGroupDetail',
    label: 'detail',
    path: DistributorManagerRouteConfig.detailPath,
    parent: DistributorManagerRouteConfig.groupForm,
    disabledBreadcrumbItem: true,
    fromUrlParamPath: false
  };

  static readonly groupDetail: NextRouteItem = {
    id: 'distributorGroupDetailId',
    label: 'View Distributor Group',
    path: `:${RouteParams.ID}`,
    pathParam: `${RouteParams.ID}`,
    fromUrlParamPath: true,
    disabledBreadcrumbItem: true,
    parent: DistributorManagerRouteConfig.groupDetailConfig
  };

  static readonly groupDetailTabStep: NextRouteItem = {
    ...BaseRouteConfig.sectionBase,
    id: 'tabStepDistributorGroup',
    parent: DistributorManagerRouteConfig.groupDetail
  };
}
