export enum NextTableFilterProps {
  TOGGLE_FILTER = 'toggleFilter',
  SEARCH_FILTER = 'searchFilter',
  EXTRA_FILTER = 'extraFilter',
  EXTRA_FILTER_BUTTON = 'extraFilterButton',
  NAME_SEARCH = 'nameSearch',
  TOGGLE_ALL = 'all',
  TOGGLE_ACTIVE = 'active',
  TOGGLE_SUSPENDED = 'suspended',
  TOGGLE_CLOSED = 'closed'
}

export enum FilterToggleProps {
  CONTROL_KEY = 'controlKey',
  LABEL_KEY = 'labelKey',
  COLOR_TYPE = 'colorType',
  DEFAULT_VALUE = 'defaultValue'
}
