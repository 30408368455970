import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ButtonType } from '@components/atoms/buttons/components/button/enums/buttons.enum';
import { SubmitCancelTestIds } from '@components/molecules/buttons-molecule/components/submit-cancel/submit-cancel.enum';

@Component({
  selector: 'submit-cancel',
  templateUrl: './submit-cancel.component.html',
  styleUrl: './submit-cancel.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubmitCancelComponent {
  @Input() submitLabel: string = 'BUTTONS.SUBMIT';
  @Input() cancelLabel: string = 'BUTTONS.CANCEL';
  @Input() isCancelDisabled: boolean = false;
  @Input() isSubmitDisabled: boolean = false;
  @Input() hideSubmitButton: boolean = false;
  @Input() hideCancelButton: boolean = false;
  
  @Output() submitClick = new EventEmitter();
  @Output() cancelClick = new EventEmitter();

  protected readonly ButtonType = ButtonType;
  protected readonly SubmitCancelTestIds = SubmitCancelTestIds;
}
