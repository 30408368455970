import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { DialogSize } from '@components/organisms/next-dialog/next-dialog.enum';
import { DistributorProps } from '@pages/distributor/enums/distributor-manager.enum';
import { FormArray, FormGroup } from '@angular/forms';
import { NextObjectHelper } from '@utils/core/next-object.helper';
import { DistributorGroupConstant } from '@pages/distributor/constant/distributor-group.constant';
import { DistributorGroupProps } from '@pages/distributor/enums/distributor-group.enum';
import { InputNumberType } from '@components/atoms/form-inputs/enums/input.enum';
import { ProductLinesBlanketsAdminService } from '@pages/distributor/services/product-lines-blankets-admin.service';
import { TableFieldProps } from '@components/organisms/next-table/enums/next-table.enum';
import { MenuItemService } from '../../../../core/services/menu-item.service';
import { ProductLineProps, ProductLinesActions } from '@pages/distributor/enums/product-lines.enum';
import { NextIcon } from '@components/atoms/visual/enums/icon.enum';
import { Blanket, pbFormType, ProductLine } from '@pages/distributor/types/product-line-blankets.type';
import { ProductLinesConstant } from '@pages/distributor/constant/product-lines.constant';
import { DistributorManagerService } from '@pages/distributor/services/distributor-manager.service';
import { BlanketsConstant } from '@pages/distributor/constant/blankets.constant';
import { DProductLinesBlankets } from '@pages/distributor/types/distributor.type';
import { BlanketsProps } from '@pages/distributor/enums/blankets.enum';

@Component({
  selector: 'product-lines-and-blankets',
  templateUrl: './product-lines-and-blankets.component.html',
  styleUrl: './product-lines-and-blankets.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductLinesAndBlanketsComponent implements OnInit {
  @Input() data: DProductLinesBlankets;
  @Input() dataRef: DProductLinesBlankets;
  @Input() productLinesAndBlankets: any;
  @Input() formGroup?: FormGroup<any>;
  
  productLinesAndBlanketsData: any;
  isEdition: boolean;
  dataProductLines: any = {};
  dataBlankets: any;
  dataRefProductLines: ProductLine[];
  dataRefBlankets: Blanket[];
  dialogSize: DialogSize = DialogSize.MEDIUM;
  forceViewMode = false;
  editionIndex: number | undefined;
  isHorizontalContent: boolean = true;
  hideCancelButton: boolean = true;

  currentEntity: any = {};
  activePopup: undefined | DistributorProps.PRODUCT_LINES | DistributorProps.BLANKETS;

  protected readonly LABEL_KEY_PREFIX = 'DISTRIBUTOR.MANAGER.DETAILS.TABS.PRODUCT_LINES_AND_BLANKETS';
  protected readonly DistributorProps = DistributorProps;
  protected readonly DistributorGroupConstant = DistributorGroupConstant;
  protected readonly DistributorGroupProps = DistributorGroupProps;
  protected readonly InputNumberType = InputNumberType;
  protected readonly ProductLinesBlanketsConstant = ProductLinesConstant;
  protected readonly BlanketsConstant = BlanketsConstant;
  protected readonly ProductLineProps = ProductLineProps;
  protected readonly BlanketsProps = BlanketsProps;

  constructor(
    private _productLinesBlanketsAdminService: ProductLinesBlanketsAdminService,
    private _distributorManagerService: DistributorManagerService,
    private _menuItemService: MenuItemService
  ) {}

  ngOnInit(): void {
    this._setConfigData();
  }

  viewDetails(type: pbFormType, entity: any) {
    this.activePopup = type;
    this.forceViewMode = true;
    this.currentEntity = entity;
  }

  handleRemove(formType: pbFormType, index: number) {
    const formArray = this.formGroup?.get(formType) as FormArray;
    formArray.removeAt(index);
    this.productLinesAndBlanketsData[formType] = formArray!.getRawValue();
    this._updateList(formType);
  }

  handleAdd(formType: pbFormType, formValues: any) {
    const formArray = this.formGroup!.get(formType) as FormArray;
    const formGroup = this._productLinesBlanketsAdminService.formGroupByType(formType, formValues);
    if (this.editionIndex !== undefined) {
      formArray.removeAt(this.editionIndex);
      formArray.insert(this.editionIndex, formGroup);
      this.editionIndex = undefined;
    } else {
      formArray.push(formGroup);
    }

    this.productLinesAndBlanketsData[formType] = formArray!.getRawValue();
    this._updateList(formType);
    this.dismissPopup();
  }

  enablePopup(type: pbFormType, entity: any = {}) {
    this.activePopup = type;
    this.currentEntity = entity;
    this.editionIndex = entity?.index;
    this.forceViewMode = false;
  }

  dismissPopup() {
    this.currentEntity = {};
    this.activePopup = undefined;
    this.forceViewMode = false;
  }

  private _updateList(formType: pbFormType) {
    if (formType === DistributorProps.PRODUCT_LINES) {
      this.dataProductLines = this._mapList(DistributorProps.PRODUCT_LINES);
    } else {
      this.dataBlankets = this._mapList(DistributorProps.BLANKETS);
    }
  }

  private _mapList(formType: pbFormType) {
    return NextObjectHelper.getPropertyFromObject(this.productLinesAndBlanketsData, [formType], []).map(
      (baseEntity: any, index: number) => {
        const statusProp = ProductLinesConstant.statusPropertyByFormType[formType];
        const statusConfig =
          formType === DistributorProps.PRODUCT_LINES
            ? ProductLinesConstant.productLineStatusConfig[baseEntity[statusProp]]
            : BlanketsConstant.blanketStatusConfig[baseEntity[statusProp]];
        const entity = {
          ...baseEntity,
          index,
          statusColorType: statusConfig?.color,
          statusLabelKey: statusConfig?.label
        };

        if (this.isEdition) {
          entity[TableFieldProps.MENU_OPTION_LIST] = this._createTableActions(formType, entity);
        }
        return entity;
      }
    );
  }

  private _createTableActions(formType: pbFormType, entity: any) {
    return this._menuItemService.buildMenuItemList(
      [ProductLinesActions.VIEW, ProductLinesActions.MODIFY, ProductLinesActions.DELETE],
      {
        [ProductLinesActions.VIEW]: {
          label: this.LABEL_KEY_PREFIX + '.ACTIONS.VIEW',
          icon: NextIcon.EYE_OPENED,
          callback: () => {
            this.viewDetails(formType, entity);
          }
        },
        [ProductLinesActions.MODIFY]: {
          label: this.LABEL_KEY_PREFIX + '.ACTIONS.MODIFY',
          icon: NextIcon.EDIT,
          callback: () => {
            this.enablePopup(formType, entity);
          }
        },
        [ProductLinesActions.ARCHIVE]: {
          label: this.LABEL_KEY_PREFIX + '.ACTIONS.ARCHIVE',
          icon: NextIcon.ARCHIVE,
          callback: () => {
            // TODO
          }
        },
        [ProductLinesActions.DELETE]: {
          label: this.LABEL_KEY_PREFIX + '.ACTIONS.DELETE',
          icon: NextIcon.DELETE,
          callback: () => {
            this.handleRemove(formType, entity.index);
          }
        }
      },
      entity
    );
  }

  private _setConfigData() {
    if (this.formGroup) {
      this.productLinesAndBlanketsData = this.formGroup.getRawValue();
      this.isEdition = true;
    } else {
      this.productLinesAndBlanketsData = this.data;
      
      this.dataRefProductLines = NextObjectHelper.getPropertyFromObject(this.dataRef, [DistributorProps.PRODUCT_LINES]);
      this.dataRefBlankets = NextObjectHelper.getPropertyFromObject(this.dataRef, [DistributorProps.BLANKETS]);
    }
    this._updateList(DistributorProps.PRODUCT_LINES);
    this._updateList(DistributorProps.BLANKETS);
  }
}
