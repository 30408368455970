import {
  DistributorDetailsSecuritiesSection,
  DistributorGroupSection,
  DistributorManagerAction,
  DistributorManagerFilterProps,
  DistributorManagerProps,
  DistributorProps,
  DistributorSection,
  DistributorStatus,
  GroupDetailsProps,
  RiskStatus
} from '../enums/distributor-manager.enum';
import { ColorType } from '../../../shared/enums/color.enum';
import { FilterToggleControlConfig } from '@components/organisms/next-table-filter/types/next-table-filter.type';
import { FilterToggleProps } from '@components/organisms/next-table-filter/enums/next-table.filter.enum';
import {
  AlignRow,
  TableField,
  TemplateType,
  ColumnSize,
  FieldType
} from '@components/organisms/next-table/next-table.type';
import {
  GenericTableColumnProps,
  NextFilterType,
  TableFieldProps
} from '@components/organisms/next-table/enums/next-table.enum';
import { NextIcon } from '@components/atoms/visual/enums/icon.enum';
import { NextMenuItem } from '@wwtfTypes/menu.type';
import { FormSection } from '@components/organisms/layout/types/form-section.type';
import { FormSectionProps } from '@components/organisms/layout/enum/form-section.enum';
import { GenericObject } from '@utils/models/Types';
import { NextRouteProps, RouteParams } from '../../../core/enums/route.enum';
import { DistributorDetailsGeneralInformationComponent } from '../pages/distributor-details/components/distributor-details-general-information/distributor-details-general-information.component';
import { DistributorDetailsSecuritiesCreditLimitsComponent } from '../pages/distributor-details/components/distributor-details-securities-credit-limits/distributor-details-securities-credit-limits.component';
import { ProductLinesAndBlanketsComponent } from '@pages/distributor/components/product-lines-blankets/product-lines-and-blankets.component';
import { SourceLocationMappingComponent } from '../components/source-location-mapping/source-location-mapping.component';
import { DistributorObservationsComponent } from '@pages/distributor/components/distributor-observations/distributor-observations.component';
import { DistributorManagerEndpoints } from '@pages/distributor/constant/distributor-manager.endpoints.constant';
import { DistributorManagerRouteConfig } from '@pages/distributor/distributor-manager.route.config';
import { DistributorGroupProps } from '@pages/distributor/enums/distributor-group.enum';
import { StatusMap } from '../../../core/types/status.type';
import { OperationalStatus, StatusConfigProps } from '../../../core/enums/status.enum';
import { DataTitleProps } from '@components/atoms/visual/components/data-title/data-title.enum';
import { DataTitleConfig } from '@components/atoms/visual/components/data-title/data-title.config';
import { NextFormatDatePipe } from '@pipes/common/custom-date.pipe';
import { CommonBooleanLabelPipe } from '@pipes/boolean-label.pipe';

export class DistributorManagerConstant {
  static readonly globalFilter = [DistributorManagerProps.NAME];

  static readonly filterToggleConfig: FilterToggleControlConfig[] = [
    {
      [FilterToggleProps.CONTROL_KEY]: DistributorStatus.ACTIVE,
      [FilterToggleProps.LABEL_KEY]: 'DISTRIBUTOR.MANAGER.FILTER.TOGGLE.ACTIVE',
      [FilterToggleProps.COLOR_TYPE]: ColorType.SUCCESS
    },
    {
      [FilterToggleProps.CONTROL_KEY]: DistributorStatus.SUSPENDED,
      [FilterToggleProps.LABEL_KEY]: 'DISTRIBUTOR.MANAGER.FILTER.TOGGLE.SUSPENDED',
      [FilterToggleProps.COLOR_TYPE]: ColorType.CAUTION
    },
    {
      [FilterToggleProps.CONTROL_KEY]: DistributorStatus.CLOSED,
      [FilterToggleProps.LABEL_KEY]: 'DISTRIBUTOR.MANAGER.FILTER.TOGGLE.CLOSED',
      [FilterToggleProps.COLOR_TYPE]: ColorType.DANGER
    }
  ];

  static readonly extraFilterInitialValues = {
    [DistributorManagerFilterProps.DISTRIBUTOR_GROUP]: '',
    [DistributorManagerFilterProps.RISK_CLASS]: '',
    [DistributorManagerFilterProps.TASK_BOARD_STATUS]: ''
  };

  static readonly statusMapConfig: GenericObject<StatusMap> = {
    [DistributorStatus.ACTIVE]: {
      [StatusConfigProps.COLOR]: ColorType.SUCCESS,
      [StatusConfigProps.KEY]: 'DISTRIBUTOR.STATUS.ACTIVE'
    },
    [DistributorStatus.SUSPENDED]: {
      [StatusConfigProps.COLOR]: ColorType.CAUTION,
      [StatusConfigProps.KEY]: 'DISTRIBUTOR.STATUS.SUSPENDED'
    },
    [DistributorStatus.CLOSED]: {
      [StatusConfigProps.COLOR]: ColorType.DANGER,
      [StatusConfigProps.KEY]: 'DISTRIBUTOR.STATUS.CLOSED'
    },
    [DistributorStatus.WAITING]: {
      [StatusConfigProps.COLOR]: ColorType.CAUTION,
      [StatusConfigProps.KEY]: 'DISTRIBUTOR.STATUS.WAITING_APPROVAL'
    },
    [DistributorStatus.PENDING]: {
      [StatusConfigProps.COLOR]: ColorType.PURPLE,
      [StatusConfigProps.KEY]: 'DISTRIBUTOR.STATUS.PENDING'
    },
    [DistributorStatus.REVIEWED]: {
      [StatusConfigProps.COLOR]: ColorType.BLUE,
      [StatusConfigProps.KEY]: 'DISTRIBUTOR.STATUS.REVIEWED'
    }
  };

  static readonly creditLimitStatusLabelMappingConfig = {
    [RiskStatus.ACTIVE]: 'DISTRIBUTOR.CREDIT_LIMIT_STATUS.ACTIVE',
    [RiskStatus.SUSPENDED]: 'DISTRIBUTOR.ACCOUNT_STATUS.SUSPENDED'
  };

  static readonly operationalStatusLabelMappingConfig = {
    [OperationalStatus.ACTIVE]: 'STATUS.OPERATIONAL_STATUS.ACTIVE',
    [OperationalStatus.CREDIT_CONCERN]: 'STATUS.OPERATIONAL_STATUS.CREDIT_CONCERN',
    [OperationalStatus.CREDIT_WATCH]: 'STATUS.OPERATIONAL_STATUS.CREDIT_WATCH',
    [OperationalStatus.STATUS]: 'STATUS.OPERATIONAL_STATUS.STATUS',
    [OperationalStatus.STOP_SHIPMENT]: 'STATUS.OPERATIONAL_STATUS.STOP_SHIPMENT',
    [OperationalStatus.FULL_FINANCIAL_HOLD]: 'STATUS.OPERATIONAL_STATUS.FULL_FINANCIAL_HOLD',
    [OperationalStatus.EXCEPTION]: 'STATUS.OPERATIONAL_STATUS.EXCEPTION',
    [OperationalStatus.CLOSED]: 'STATUS.OPERATIONAL_STATUS.CLOSED',
    [OperationalStatus.SUSPENDED]: 'STATUS.OPERATIONAL_STATUS.SUSPENDED'
  };

  static readonly accountStatusLabelMappingConfig = {
    [DistributorStatus.ACTIVE]: 'DISTRIBUTOR.ACCOUNT_STATUS.ACTIVE',
    [DistributorStatus.SUSPENDED]: 'DISTRIBUTOR.ACCOUNT_STATUS.SUSPENDED',
    [DistributorStatus.CLOSED]: 'DISTRIBUTOR.ACCOUNT_STATUS.CLOSED',
    [DistributorStatus.ARCHIVED]: 'DISTRIBUTOR.ACCOUNT_STATUS.ARCHIVED'
  };

  static readonly riskLabelMappingConfig = {
    [RiskStatus.ACTIVE]: 'DISTRIBUTOR.RISK_STATUS.ACTIVE',
    [RiskStatus.SUSPENDED]: 'DISTRIBUTOR.RISK_STATUS.SUSPENDED',
    [RiskStatus.CLOSED]: 'DISTRIBUTOR.RISK_STATUS.CLOSED',
    [RiskStatus.REGULAR]: 'DISTRIBUTOR.RISK_STATUS.REGULAR',
    [RiskStatus.CREDIT_WATCH]: 'DISTRIBUTOR.RISK_STATUS.CREDIT_WATCH',
    [RiskStatus.CREDIT_CONCERN]: 'DISTRIBUTOR.RISK_STATUS.CREDIT_CONCERN',
    [RiskStatus.STATUS]: 'DISTRIBUTOR.RISK_STATUS.STATUS'
  };

  static readonly riskStatusMapConfig: GenericObject<StatusMap> = {
    [RiskStatus.ACTIVE]: {
      [StatusConfigProps.COLOR]: ColorType.SUCCESS,
      [StatusConfigProps.KEY]: DistributorManagerConstant.riskLabelMappingConfig[RiskStatus.ACTIVE]
    },
    [RiskStatus.SUSPENDED]: {
      [StatusConfigProps.COLOR]: ColorType.CAUTION,
      [StatusConfigProps.KEY]: DistributorManagerConstant.riskLabelMappingConfig[RiskStatus.SUSPENDED]
    },
    [RiskStatus.CLOSED]: {
      [StatusConfigProps.COLOR]: ColorType.DANGER,
      [StatusConfigProps.KEY]: DistributorManagerConstant.riskLabelMappingConfig[RiskStatus.CLOSED]
    },
    [RiskStatus.REGULAR]: {
      [StatusConfigProps.COLOR]: ColorType.SUCCESS,
      [StatusConfigProps.KEY]: DistributorManagerConstant.riskLabelMappingConfig[RiskStatus.REGULAR]
    },
    [RiskStatus.CREDIT_CONCERN]: {
      [StatusConfigProps.COLOR]: ColorType.CAUTION,
      [StatusConfigProps.KEY]: DistributorManagerConstant.riskLabelMappingConfig[RiskStatus.CREDIT_CONCERN]
    },
    [RiskStatus.CREDIT_WATCH]: {
      [StatusConfigProps.COLOR]: ColorType.CAUTION,
      [StatusConfigProps.KEY]: DistributorManagerConstant.riskLabelMappingConfig[RiskStatus.CREDIT_WATCH]
    },
    [RiskStatus.STATUS]: {
      [StatusConfigProps.COLOR]: ColorType.CAUTION,
      [StatusConfigProps.KEY]: DistributorManagerConstant.riskLabelMappingConfig[RiskStatus.STATUS]
    }
  };

  static readonly tableListConfig: TableField[] = [
    {
      [TableFieldProps.FIELD]: [DistributorManagerProps.ID, DistributorManagerProps.NAME],
      [TableFieldProps.SEPARATOR]: ' - ',
      [TableFieldProps.HEADER]: 'DISTRIBUTOR.MANAGER.FIELDS.CODE_AND_NAME',
      [TableFieldProps.IS_SORTABLE]: true,
      [TableFieldProps.SORTABLE_FIELD]: DistributorManagerProps.ID

    },
    {
      [TableFieldProps.FIELD]: DistributorManagerProps.GROUP_NAME,
      [TableFieldProps.HEADER]: 'DISTRIBUTOR.MANAGER.FIELDS.GROUP',
      [TableFieldProps.IS_SORTABLE]: true,
      [TableFieldProps.IS_FILTER]: true
    },
    {
      [TableFieldProps.FIELD]: DistributorManagerProps.LAST_MODIFIED_TIMESTAMP,
      [TableFieldProps.HEADER]: 'DISTRIBUTOR.MANAGER.FIELDS.LAST_MODIFIED_DATE',
      [TableFieldProps.WIDTH]: ColumnSize.SMALL,
      [TableFieldProps.FIELD_TYPE]: [FieldType.DATE],
      [TableFieldProps.FILTER_TYPE]: NextFilterType.DATE,
      [TableFieldProps.IS_SORTABLE]: true
    },
    {
      [TableFieldProps.FIELD]: DistributorManagerProps.STATUS,
      [TableFieldProps.HEADER]: 'DISTRIBUTOR.MANAGER.FIELDS.ACCOUNT_STATUS',
      [TableFieldProps.TEMPLATE_TYPE]: TemplateType.STATUS,
      [TableFieldProps.WIDTH]: ColumnSize.SMALL,
      [TableFieldProps.STATUS_MAP]: DistributorManagerConstant.statusMapConfig,
      [TableFieldProps.FILTER_TYPE]: NextFilterType.MULTI_SELECTION,
      [TableFieldProps.IS_FILTER]: true,
      [TableFieldProps.IS_SORTABLE]: true
    },
    {
      [TableFieldProps.FIELD]: DistributorManagerProps.RISK_STATUS,
      [TableFieldProps.HEADER]: 'DISTRIBUTOR.MANAGER.FIELDS.RISK_CLASS',
      [TableFieldProps.WIDTH]: ColumnSize.SMALL,
      [TableFieldProps.TEMPLATE_TYPE]: TemplateType.STATUS,
      [TableFieldProps.STATUS_MAP]: DistributorManagerConstant.riskStatusMapConfig,
      [TableFieldProps.IS_SORTABLE]: true,
      [TableFieldProps.IS_FILTER]: true,
      [TableFieldProps.FILTER_TYPE]: NextFilterType.MULTI_SELECTION
    },
    {
      [TableFieldProps.FIELD]: DistributorManagerProps.TASK,
      [TableFieldProps.HEADER]: 'TABLE.FIELDS.TASK',
      [TableFieldProps.WIDTH]: ColumnSize.VERY_SMALL,
      [TableFieldProps.IS_SORTABLE]: false,
      [TableFieldProps.IS_FILTER]: true
    },
    {
      [TableFieldProps.FIELD]: GenericTableColumnProps.ACTIONS,
      [TableFieldProps.HEADER]: 'DISTRIBUTOR.MANAGER.FIELDS.VIEW',
      [TableFieldProps.ACTION]: {},
      [TableFieldProps.TEMPLATE_TYPE]: TemplateType.ACTION,
      [TableFieldProps.ALIGN]: AlignRow.CENTER,
      [TableFieldProps.WIDTH]: ColumnSize.VERY_SMALL
    },
    {
      [TableFieldProps.FIELD]: GenericTableColumnProps.MENU_LIST,
      [TableFieldProps.HEADER]: 'DISTRIBUTOR.MANAGER.FIELDS.OPTIONS',
      [TableFieldProps.TEMPLATE_TYPE]: TemplateType.MENU_ACTION,
      [TableFieldProps.ALIGN]: AlignRow.CENTER,
      [TableFieldProps.WIDTH]: ColumnSize.VERY_SMALL
    }
  ];

  static readonly actions: NextMenuItem[] = [
    {
      labelKey: 'DISTRIBUTOR.MANAGER.ACTIONS.ADD_NEW_DISTRIBUTOR',
      routerLink: [DistributorManagerRouteConfig.distributorConfig[NextRouteProps.PATH]],
      nextIcon: NextIcon.PLUS
    },
    {
      labelKey: 'DISTRIBUTOR.MANAGER.ACTIONS.ADD_NEW_GROUP',
      routerLink: [DistributorManagerRouteConfig.groupForm[NextRouteProps.PATH]],
      nextIcon: NextIcon.PLUS
    }
  ];

  static readonly distributorGeneralDetailsMap: DataTitleConfig[] = [
    {
      [DataTitleProps.PATH]: [
        DistributorSection.SECURITIES_AND_CREDIT_LIMITS,
        DistributorProps.GROUP_CREDIT_LIMITS,
        DistributorGroupProps.NAME
      ],
      [DataTitleProps.TITLE_KEY]: 'DISTRIBUTOR.MANAGER.SECURITIES_AND_CREDIT_LIMITS.DISTRIBUTOR_GROUP_NAME'
    },
    {
      [DataTitleProps.PATH]: [
        DistributorSection.SECURITIES_AND_CREDIT_LIMITS,
        DistributorProps.GROUP_CREDIT_LIMITS,
        DistributorGroupProps.CODE
      ],
      [DataTitleProps.TITLE_KEY]: 'DISTRIBUTOR.MANAGER.SECURITIES_AND_CREDIT_LIMITS.DISTRIBUTOR_GROUP'
    },
    {
      [DataTitleProps.PATH]: [DistributorSection.GENERAL_INFORMATION, DistributorProps.CREATION_DATE],
      [DataTitleProps.TITLE_KEY]: 'DISTRIBUTOR.MANAGER.DETAILS.GENERAL_DETAILS_DISTRIBUTOR.LAST_MODIFIED_DATE',
      [DataTitleProps.PIPE_LIST]: [{ pipe: NextFormatDatePipe }]
    }
  ];

  static readonly distributorGroupGeneralDetails: DataTitleConfig[] = [
    {
      [DataTitleProps.TITLE_KEY]: 'DISTRIBUTOR.MANAGER.DETAILS.GENERAL_DETAILS_GROUP.DISTRIBUTOR_GROUP',
      [DataTitleProps.PATH]: [DistributorGroupSection.GENERAL_INFORMATION, DistributorProps.NAME]
    },
    {
      [DataTitleProps.TITLE_KEY]: 'DISTRIBUTOR.MANAGER.DETAILS.GENERAL_DETAILS_GROUP.LAST_MODIFIED_DATE',
      [DataTitleProps.PATH]: [DistributorGroupSection.GENERAL_INFORMATION, GroupDetailsProps.LAST_MODIFIED_DATE],
      [DataTitleProps.PIPE_LIST]: [{ pipe: NextFormatDatePipe }]
    },
    {
      [DataTitleProps.TITLE_KEY]: 'DISTRIBUTOR.MANAGER.DETAILS.GENERAL_DETAILS_GROUP.OPERATING_WITH_SWING_LINES',
      [DataTitleProps.PATH]: [
        DistributorGroupSection.GENERAL_INFORMATION,
        GroupDetailsProps.OPERATING_WITH_SWING_LINES
      ],
      pipeList: [{ pipe: CommonBooleanLabelPipe }]
    }
  ];

  static readonly distributorGeneralDetailsTabs: FormSection[] = [
    {
      [FormSectionProps.ID]: DistributorSection.GENERAL_INFORMATION,
      [FormSectionProps.LABEL_KEY]: 'DISTRIBUTOR.MANAGER.DETAILS.TABS.GENERAL_INFORMATION.NAME',
      [FormSectionProps.PATH]: DistributorSection.GENERAL_INFORMATION,
      [FormSectionProps.TEMPLATE]: DistributorDetailsGeneralInformationComponent
    },
    {
      [FormSectionProps.ID]: DistributorSection.SECURITIES_AND_CREDIT_LIMITS,
      [FormSectionProps.LABEL_KEY]: 'DISTRIBUTOR.MANAGER.DETAILS.TABS.SECURITIES_AND_CREDIT_LIMITS.NAME',
      [FormSectionProps.PATH]: DistributorSection.SECURITIES_AND_CREDIT_LIMITS,
      [FormSectionProps.TEMPLATE]: DistributorDetailsSecuritiesCreditLimitsComponent
    },
    {
      [FormSectionProps.ID]: DistributorSection.PRODUCT_LINES_AND_BLANKETS,
      [FormSectionProps.LABEL_KEY]: 'DISTRIBUTOR.MANAGER.DETAILS.TABS.PRODUCT_LINES_AND_BLANKETS.NAME',
      [FormSectionProps.PATH]: DistributorSection.PRODUCT_LINES_AND_BLANKETS,
      [FormSectionProps.TEMPLATE]: ProductLinesAndBlanketsComponent
    },
    {
      [FormSectionProps.ID]: DistributorSection.SOURCE_LOCATION_MAPPING,
      [FormSectionProps.LABEL_KEY]: 'DISTRIBUTOR.MANAGER.DETAILS.TABS.SOURCE_LOCATION_MAPPING.NAME',
      [FormSectionProps.PATH]: DistributorSection.SOURCE_LOCATION_MAPPING,
      [FormSectionProps.TEMPLATE]: SourceLocationMappingComponent
    },
    {
      [FormSectionProps.ID]: DistributorSection.OBSERVATIONS,
      [FormSectionProps.LABEL_KEY]: 'DISTRIBUTOR.MANAGER.DETAILS.TABS.OBSERVATIONS.NAME',
      [FormSectionProps.PATH]: DistributorSection.OBSERVATIONS,
      [FormSectionProps.TEMPLATE]: DistributorObservationsComponent
    }
  ];

  static readonly distributorDetailsSectionsSecuritiesCreditLimits: any = {
    [DistributorDetailsSecuritiesSection.CREDIT_LIMITS]:
      'DISTRIBUTOR.MANAGER.DETAILS.TABS.SECURITIES_AND_CREDIT_LIMITS.TITLES.CREDIT_LIMITS',
    [DistributorDetailsSecuritiesSection.GROUP_CREDIT_LIMITS]:
      'DISTRIBUTOR.MANAGER.DETAILS.TABS.SECURITIES_AND_CREDIT_LIMITS.TITLES.CREDIT_LIMITS',
    [DistributorDetailsSecuritiesSection.FUNDING_BANK]:
      'DISTRIBUTOR.MANAGER.DETAILS.TABS.SECURITIES_AND_CREDIT_LIMITS.TITLES.FUNDING_BANK',
    [DistributorDetailsSecuritiesSection.SECURITIES_GUARANTEE]:
      'DISTRIBUTOR.MANAGER.DETAILS.TABS.SECURITIES_AND_CREDIT_LIMITS.TITLES.SECURITIES_GUARANTEE',
    [DistributorDetailsSecuritiesSection.SECURITIES_INSURER]:
      'DISTRIBUTOR.MANAGER.DETAILS.TABS.SECURITIES_AND_CREDIT_LIMITS.TITLES.SECURITIES_INSURER'
  };

  static readonly tableListDistributorCreditLimitsConfig: TableField[] = [
    {
      [TableFieldProps.FIELD]: DistributorProps.NAME,
      [TableFieldProps.HEADER]: 'DISTRIBUTOR.MANAGER.DETAILS.TABS.DISTRIBUTOR_CREDIT_LIMITS.FIELDS.DISTRIBUTOR_NAME',
      [TableFieldProps.IS_SORTABLE]: true
    },
    {
      [TableFieldProps.FIELD]: DistributorProps.CREDIT_LIMIT_AMOUNT,
      [TableFieldProps.HEADER]: 'DISTRIBUTOR.MANAGER.DETAILS.TABS.DISTRIBUTOR_CREDIT_LIMITS.FIELDS.CREDIT_LIMIT_AMOUNT',
      [TableFieldProps.IS_SORTABLE]: false,
      [TableFieldProps.TEMPLATE_TYPE]: TemplateType.COLUMN_TEMPLATE
    }
  ];

  static readonly distributorGroupActionConfig = {
    [DistributorManagerAction.MODIFY]: {
      routeItem: DistributorManagerRouteConfig.groupFormId,
      routeParent: DistributorManagerRouteConfig.mainRoute,
      mapRouteEntityUrlParams: {
        [RouteParams.ID]: DistributorGroupProps.ID
      },
      label: 'DISTRIBUTOR.MANAGER.OPTIONS.MODIFY',
      icon: NextIcon.EDIT
    },
    [DistributorManagerAction.ARCHIVE]: {
      label: 'DISTRIBUTOR.MANAGER.OPTIONS.ARCHIVE',
      icon: NextIcon.ARCHIVE
    }
  };

  static readonly distributorActionConfig = {
    [DistributorManagerAction.MODIFY]: {
      routeItem: DistributorManagerRouteConfig.distributorFormId,
      routeParent: DistributorManagerRouteConfig.mainRoute,
      mapRouteEntityUrlParams: {
        [RouteParams.ID]: DistributorManagerProps.ID
      },
      label: 'DISTRIBUTOR.MANAGER.OPTIONS.MODIFY',
      icon: NextIcon.EDIT
    },
    [DistributorManagerAction.ARCHIVE]: {
      label: 'DISTRIBUTOR.MANAGER.OPTIONS.ARCHIVE',
      icon: NextIcon.ARCHIVE
    }
  };

  static readonly endpointItemBySection: GenericObject = {
    [DistributorSection.GENERAL_INFORMATION]: DistributorManagerEndpoints.config.DISTRIBUTOR.MAIN,
    [DistributorSection.SECURITIES_AND_CREDIT_LIMITS]:
      DistributorManagerEndpoints.config.DISTRIBUTOR.SECURITIES_AND_CREDIT_LIMITS,
    [DistributorSection.PRODUCT_LINES_AND_BLANKETS]:
      DistributorManagerEndpoints.config.DISTRIBUTOR.PRODUCT_LINES_AND_BLANKETS,
    [DistributorSection.SOURCE_LOCATION_MAPPING]:
      DistributorManagerEndpoints.config.DISTRIBUTOR.SOURCE_LOCATION_MAPPING,
    [DistributorSection.OBSERVATIONS]: DistributorManagerEndpoints.config.DISTRIBUTOR.OBSERVATIONS
  };

  static readonly distributorManagerDownloadConfig: DataTitleConfig[] = [
    {
      [DataTitleProps.PATH]: [DistributorManagerProps.ID],
      [DataTitleProps.TITLE_KEY]: 'DISTRIBUTOR.MANAGER.FIELDS.CODE'
    },
    {
      [DataTitleProps.PATH]: [DistributorManagerProps.NAME],
      [DataTitleProps.TITLE_KEY]: 'DISTRIBUTOR.MANAGER.FIELDS.NAME'
    },
    {
      [DataTitleProps.PATH]: [DistributorManagerProps.IS_DISTRIBUTOR_GROUP],
      [DataTitleProps.TITLE_KEY]: 'DISTRIBUTOR.MANAGER.FIELDS.GROUP'
    },
    {
      [DataTitleProps.PATH]: [DistributorManagerProps.CREATED_TIMESTAMP],
      [DataTitleProps.TITLE_KEY]: 'DISTRIBUTOR.MANAGER.FIELDS.LAST_MODIFIED_DATE'
    },
    {
      [DataTitleProps.PATH]: [DistributorManagerProps.STATUS],
      [DataTitleProps.TITLE_KEY]: 'DISTRIBUTOR.MANAGER.FIELDS.ACCOUNT_STATUS'
    },
    {
      [DataTitleProps.PATH]: [DistributorManagerProps.RISK_STATUS],
      [DataTitleProps.TITLE_KEY]: 'DISTRIBUTOR.MANAGER.FIELDS.RISK_CLASS'
    },
    {
      [DataTitleProps.PATH]: [DistributorManagerProps.TASK],
      [DataTitleProps.TITLE_KEY]: 'TABLE.FIELDS.TASK'
    }
  ];

  static readonly fileExcelName: string = 'distributor-manager';
  static readonly fileType: string = 'distributor-manager';
}
