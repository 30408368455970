export enum TaskBoardStatus {
  WAITING_APPROVAL = 'WAITING_APPROVAL',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
  APPROVED = 'APPROVED'
}

export enum TaskBoardRequestType {
  NEW_DISTRIBUTOR = 'NEW_DISTRIBUTOR',
  UPDATE_DISTRIBUTOR = 'UPDATE_DISTRIBUTOR',
  NEW_GROUP = 'NEW_GROUP',
  UPDATE_GROUP = 'UPDATE_GROUP',
  COUNTRY_RATING_UPDATE = 'UPDATE_COUNTRY_RISK_RATING',
  BASE_RATE_UPDATE = 'BASE_RATE_UPDATE'
}

export enum TaskBoardSection {
  GENERAL_MAINTENANCE = 'generalMaintenance',
  DISTRIBUTORS = 'distributors',
  VEHICLE_ORDER = 'vehicleOrder'
}

export enum MaintenanceTaskProps {
  ID = 'id',
  NAME = 'name',
  OWNER_CDSID = 'ownerCdsid',
  TYPE = 'type',
  AFFECTED_RESOURCE_ID = 'affectedResourceId',
  AFFECTED_RESOURCE_NAME = 'affectedResourceName',
  CREATED_TIMESTAMP = 'createdTimestamp',
  STATUS = 'status',
  REJECTION_REASON = 'rejectionReason',
  INITIATOR_CDSID = 'initiatorCdsid',
  REFERENCE = 'reference',
  JSON_PAYLOAD = 'jsonPayload',
  LATEST_EXECUTED_STEP = 'latestExecutedStep'
}

export enum DistributionBodiesProps {
  ORDERS = 'orders',
  COUNTRY = 'country'
 }