import { Injectable } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { IconHelper } from '@components/atoms/visual/helpers/icon.helper';
import { NextValueHelper } from '@utils/core/next-value.helper';
import { GenericObject } from '@utils/models/Types';
import { NavigationService } from '@services/navigation.service';
import { NavigationConfigProps } from '../../shared/enums/navigation.enum';

@Injectable({
  providedIn: 'root'
})
export class MenuItemService {
  constructor(
    private _translateService: TranslateService,
    private _navigationService: NavigationService
  ) {}

  buildMenuItemList(list: any[], actionConfig: any = {}, entity: GenericObject = {}, callbackConfig?: any): MenuItem[] {
    return list.map(id => {
      const itemConfig = actionConfig[id];
      return {
        id,
        automationId: id,
        label: this._translateService.instant(itemConfig.label),
        icon: IconHelper.createIconClass(NextValueHelper.defaultValue(itemConfig.icon)),
        command: () => {
          if (itemConfig.routeItem) {
            if (itemConfig.mapRouteEntityUrlParams) {
              const urlParams: GenericObject = {};
              Object.keys(itemConfig.mapRouteEntityUrlParams).forEach(keyUrlParam => {
                urlParams[keyUrlParam] = entity[itemConfig.mapRouteEntityUrlParams[keyUrlParam]];
              });

              this._navigationService.navigateToRouteConfig(itemConfig.routeItem, {
                [NavigationConfigProps.URL_PARAMS]: urlParams,
                [NavigationConfigProps.ROUTE_PARENT]: itemConfig.routeParent
              });
            }
          } else if (itemConfig.callback) {
            itemConfig.callback(id, entity);
          }
          if (callbackConfig) {
            if (callbackConfig.common) {
              callbackConfig.common(id);
            }

            if (callbackConfig.byId) {
              callbackConfig.byId(id);
            }
          }
        }
      };
    });
  }
}
