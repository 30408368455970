import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SubmitCancelComponent } from './components/submit-cancel/submit-cancel.component';
import { ButtonsModule } from '@components/atoms/buttons/buttons.module';
import { TranslateModule } from '@ngx-translate/core';
import { TestIdDataAttrDirective } from '../../../core/directives/test-id-data-attr.directive';

const exportedComponents = [SubmitCancelComponent];

@NgModule({
  declarations: [...exportedComponents],
  exports: [...exportedComponents],
  imports: [CommonModule, ButtonsModule, TranslateModule.forChild(), TestIdDataAttrDirective]
})
export class ButtonsMoleculeModule {}
