import { NextRouteItem } from '../../core/types/route.type';
import { NextRouteProps, RouteParams } from '../../core/enums/route.enum';
import { BaseRouteConfig } from '../../core/constants/base.route.config';
import { TaskBoardConstant } from '@pages/task-board/constants/task-board.constant';
import { TaskBoardSection } from '@pages/task-board/enums/task-board.enum';
import { FormSectionProps } from '@components/organisms/layout/enum/form-section.enum';
import { CountryRouteConfig } from '@pages/general-maintenance/pages/country/contry.route.config';
import { GenericObject } from '@utils/models/Types';

export class TaskBoardRouteConfig {
  static readonly mainPath = 'task-board';
  static readonly viewDetailsPath = 'view-details';
  static readonly generalMaintenancePath = 'general-maintenance';

  static readonly mainRoute: NextRouteItem = {
    id: 'taskBoard',
    label: 'TASK_BOARD.TITLE',
    path: TaskBoardRouteConfig.mainPath
  };

  static readonly viewDetailsBase: NextRouteItem = {
    [NextRouteProps.PATH]: `${TaskBoardRouteConfig.viewDetailsPath}/:${RouteParams.ID}`,
    pathParam: `${RouteParams.ID}`,
    [NextRouteProps.LABEL]: 'TASK_BOARD.VIEW_DETAILS.TITLE',
    [NextRouteProps.DISABLED_BREADCRUMB_ITEM]: true
  };

  // General Maintenance
  static readonly generalMaintenance: NextRouteItem = {
    [NextRouteProps.PATH]: TaskBoardConstant.sectionConfig[TaskBoardSection.GENERAL_MAINTENANCE][FormSectionProps.PATH],
    [NextRouteProps.LABEL]:
      TaskBoardConstant.sectionConfig[TaskBoardSection.GENERAL_MAINTENANCE][FormSectionProps.LABEL_KEY],
    [NextRouteProps.PARENT]: TaskBoardRouteConfig.mainRoute
  };

  static readonly generalMaintenanceConfig: NextRouteItem = {
    [NextRouteProps.PATH]: CountryRouteConfig.mainPath,
    [NextRouteProps.LABEL]: 'TASK_BOARD.COUNTRIES_BREADCRUMB',
    [NextRouteProps.PARENT]: TaskBoardRouteConfig.generalMaintenance,
    [NextRouteProps.DISABLED_BREADCRUMB_ITEM]: true
  };

  static readonly generalMaintenanceViewDetails: NextRouteItem = {
    ...TaskBoardRouteConfig.viewDetailsBase,
    [NextRouteProps.PARENT]: TaskBoardRouteConfig.generalMaintenanceConfig
  };

  static readonly generalMaintenanceTaskBoardEdition: GenericObject = {
    CREATE_DIST_GRL_INFO: CountryRouteConfig.countryFormId
  }
  // Vehicle Orders
  static readonly vehicleOrder: NextRouteItem = {
    [NextRouteProps.PATH]: TaskBoardConstant.sectionConfig[TaskBoardSection.VEHICLE_ORDER][FormSectionProps.PATH],
    [NextRouteProps.LABEL]: TaskBoardConstant.sectionConfig[TaskBoardSection.VEHICLE_ORDER][FormSectionProps.LABEL_KEY],
    [NextRouteProps.PARENT]: TaskBoardRouteConfig.mainRoute
  };

  static readonly vehicleOrderConfig: NextRouteItem = {
    [NextRouteProps.PATH]: TaskBoardRouteConfig.viewDetailsPath,
    [NextRouteProps.LABEL]: 'TASK_BOARD.VEHICLE_ORDER_BREADCRUMB',
    [NextRouteProps.PARENT]: TaskBoardRouteConfig.vehicleOrder,
    [NextRouteProps.DISABLED_BREADCRUMB_ITEM]: true
  };

  static readonly vehicleOrderEdit: NextRouteItem = {
    id: 'taskboardVehicleOrderFormId',
    path: `:${RouteParams.ID}`,
    pathParam: `${RouteParams.ID}`,
    fromUrlParamPath: true,
    [NextRouteProps.PARENT]: TaskBoardRouteConfig.vehicleOrderConfig,
    disabledBreadcrumbItem: true
  }
  
  // Steps Tabs
  static readonly taskBoardTabStep: NextRouteItem = {
    ...BaseRouteConfig.sectionBase,
    [NextRouteProps.PARENT]: TaskBoardRouteConfig.generalMaintenanceConfig
  };

  // Distributors
  static readonly distributors: NextRouteItem = {
    [NextRouteProps.PATH]: TaskBoardConstant.sectionConfig[TaskBoardSection.DISTRIBUTORS][FormSectionProps.PATH],
    [NextRouteProps.LABEL]: TaskBoardConstant.sectionConfig[TaskBoardSection.DISTRIBUTORS][FormSectionProps.LABEL_KEY],
    [NextRouteProps.PARENT]: TaskBoardRouteConfig.mainRoute
  };

  static readonly distributorConfig: NextRouteItem = {
    [NextRouteProps.PATH]: 'distributor',
    [NextRouteProps.LABEL]: 'TASK_BOARD.DISTRIBUTOR_BREADCRUMB',
    [NextRouteProps.PARENT]: TaskBoardRouteConfig.distributors,
    [NextRouteProps.DISABLED_BREADCRUMB_ITEM]: true
  };
}
