import { AlignRow, ColumnSize, TableField, TemplateType } from '@components/organisms/next-table/next-table.type';
import { TableFieldProps } from '@components/organisms/next-table/enums/next-table.enum';
import { SourceLocationProps } from '@pages/distributor/enums/source-location-mapping.enum';
import { DistributorSection } from '@pages/distributor/enums/distributor-manager.enum';

export class SourceLocationMappingConstant {
  static titleKeyPrefix = 'DISTRIBUTOR.MANAGER.DETAILS.TABS.SOURCE_LOCATION_MAPPING';
  static tableListSourceLocationConfig: TableField[] = [
    {
      [TableFieldProps.FIELD]: SourceLocationProps.SOURCE_LOCATION_NAME,
      [TableFieldProps.HEADER]: `${SourceLocationMappingConstant.titleKeyPrefix}.FIELDS.SOURCE_LOCATION_ALIAS`,
      [TableFieldProps.IS_SORTABLE]: true
    },
    {
      [TableFieldProps.FIELD]: SourceLocationProps.SOURCE_LOCATION_CODE,
      [TableFieldProps.HEADER]: `${SourceLocationMappingConstant.titleKeyPrefix}.FIELDS.SOURCE_LOCATION_CODE`,
      [TableFieldProps.IS_SORTABLE]: true
    },
    {
      [TableFieldProps.FIELD]: SourceLocationProps.FTP_PROCESSING_CODE,
      [TableFieldProps.HEADER]: `${SourceLocationMappingConstant.titleKeyPrefix}.FIELDS.FTP_PROCESSING_CODE`,
      [TableFieldProps.IS_SORTABLE]: true
    }
  ];

  static tableListSourceLocationConfigWithActions: TableField[] = [
    ...SourceLocationMappingConstant.tableListSourceLocationConfig,
    {
      [TableFieldProps.FIELD]: TableFieldProps.MENU_OPTION_LIST,
      [TableFieldProps.HEADER]: 'DISTRIBUTOR.MANAGER.FIELDS.OPTIONS',
      [TableFieldProps.TEMPLATE_TYPE]: TemplateType.MENU_ACTION,
      [TableFieldProps.ALIGN]: AlignRow.CENTER,
      [TableFieldProps.WIDTH]: ColumnSize.VERY_SMALL
    }
  ];

  static sourceLocationChildrenMapper = [
    {
      titleKey: `${SourceLocationMappingConstant.titleKeyPrefix}.FIELDS.SOURCE_LOCATION_ALIAS`,
      path: [SourceLocationProps.SOURCE_LOCATION_NAME]
    },
    {
      titleKey: `${SourceLocationMappingConstant.titleKeyPrefix}.FIELDS.SOURCE_LOCATION_CODE`,
      path: [SourceLocationProps.SOURCE_LOCATION_CODE]
    },
    {
      titleKey: `${SourceLocationMappingConstant.titleKeyPrefix}.FIELDS.FTP_PROCESSING_CODE`,
      path: [SourceLocationProps.FTP_PROCESSING_CODE]
    }
  ];

  static sourceLocationConfigMapper = {
    titleKey: `${SourceLocationMappingConstant.titleKeyPrefix}.NAME`,
    childrenKey: [DistributorSection.SOURCE_LOCATION_MAPPING],
    isArray: true,
    children: SourceLocationMappingConstant.sourceLocationChildrenMapper
  };
}
