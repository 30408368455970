export enum DistributorStatus {
  ACTIVE = 'ACTIVE',
  SUSPENDED = 'SUSPENDED',
  CLOSED = 'CLOSED',
  WAITING = 'WAITING_APPROVAL',
  PENDING = 'PENDING',
  REVIEWED = 'REVIEWED',
  ARCHIVED = 'ARCHIVED'
}

export enum RiskStatus {
  ACTIVE = 'ACTIVE',
  SUSPENDED = 'SUSPENDED',
  CLOSED = 'CLOSED',
  REGULAR = 'REGULAR',
  CREDIT_CONCERN = 'CREDIT_CONCERN',
  CREDIT_WATCH = 'CREDIT_WATCH',
  STATUS = 'STATUS'
}

export enum DistributorManagerType {
  DISTRIBUTOR = 'distributor',
  GROUP = 'group'
}

export enum DistributionBodiesProps {
  BODIES = 'bodies'
}

export enum DistributorManagerProps {
  ID = 'id',
  NAME = 'name',
  IS_DISTRIBUTOR_GROUP = 'isDistributorGroup',
  IS_INDIVIDUAL_DISTRIBUTOR = 'isIndividualDistributor',
  GROUP_NAME = 'groupName',
  STATUS = 'status',
  RISK_STATUS = 'riskStatus',
  TASK = 'task',
  FISCAL_ID = 'fiscalId',
  CREATED_TIMESTAMP = 'createdTimestamp',
  LAST_MODIFIED_TIMESTAMP = 'lastModifiedTimestamp'
}

export enum DistributorManagerFilterProps {
  DISTRIBUTOR_GROUP = 'group',
  RISK_CLASS = 'riskStatus',
  TASK_BOARD_STATUS = 'taskBoardStatus'
}

export enum DistributorSection {
  GENERAL_INFORMATION = 'generalInformation',
  SECURITIES_AND_CREDIT_LIMITS = 'securitiesAndCreditLimits',
  PRODUCT_LINES_AND_BLANKETS = 'productLinesAndBlankets',
  SOURCE_LOCATION_MAPPING = 'sourceLocations',
  OBSERVATIONS = 'observations',
  DISTRIBUTOR_CREDIT_LIMITS = 'distributorCreditLimits'
}

export enum DistributorGroupSection {
  GENERAL_INFORMATION = 'generalInformation',
  SECURITIES_AND_CREDIT_LIMITS = 'securitiesAndCreditLimits',
  DISTRIBUTOR_CREDIT_LIMITS = 'distributorCreditLimits'
}

export enum DistributorGroupSectionPath {
  GENERAL_INFORMATION = 'general-information',
  SECURITIES_AND_CREDIT_LIMITS = 'group-securities-and-credit-limits',
  DISTRIBUTOR_CREDIT_LIMITS = 'distributor-credit-limits'
}

export enum DistributorDetailsSecuritiesSection {
  CREDIT_LIMITS = 'creditLimits',
  FUNDING_BANK = 'fundingBank',
  SECURITIES_GUARANTEE = 'securitiesGuarantee',
  SECURITIES_INSURER = 'securitiesInsurer',
  GROUP_CREDIT_LIMITS = 'groupCreditLimits'
}

export enum DistributorDetailsProductLinesSection {
  PRODUCT_LINES = 'productLines',
  BLANKETS = 'blankets',
  PRODUCT = 'product',
  RATES = 'rates',
  WHITHOLDING_TAX = 'witholdingTax',
  FUNDS_AND_SECURITIES = 'fundsAndSecurities'
}

export enum DistributorProps {
  ID = 'id',
  PROVO_ID = 'provoId',
  TASK_ID = 'taskId',
  CODE = 'distributorCode',
  NAME = 'name',
  ID_NUMBER = 'fiscalId',
  VAT_NUMBER = 'vatId',
  RISK_STATUS = 'riskStatus',
  COUNTRY = 'country',
  COUNTRY_MEMBERSHIP = 'countryMembership',
  DELETION_SUSPENSION_ORDER = 'boundConceptsDeletionDisabled',
  ADDRESS = 'address',
  COMPANY_ADDRESS = 'companyAddress',
  POSTAL_CODE = 'postalCode',
  TOWN = 'town',
  CONTACT = 'contact',
  CONTACT_NAME = 'contactName',
  WORK_TELEPHONE = 'workTelephone',
  ACCOUNT_STATUS = 'status',
  OPERATION_STATUS = 'operationalStatus',
  CREDIT_LIMIT_AMOUNT = 'creditLimitAmount',
  // Securities & Credit Limits,
  BELONGS_TO_GROUP = 'belongsToGroup',
  GROUP_CREDIT_LIMITS = 'groupCreditLimits',
  GROUP = 'distributorGroup',
  BANK_GUARANTEE_AMOUNT = 'bankGuaranteeAmount',
  CREDIT = 'credit',
  FDI_AMOUNT = 'fdiAmount',
  SBLC = 'sblc',
  COMPANY_SEC = 'companySec',
  TOTAL_GROUP_CREDIT_LIMIT = 'totalGroupCreditLimit',
  BANK_GUARANTEE = 'bankGuarantee',
  AMOUNT = 'amount',
  FUNDING_BANK = 'creditBanks',
  PRODUCT_LINES = 'productLines',
  BLANKETS = 'financialBlankets',
  CREATION_DATE = 'createdTimestamp',
  LAST_MODIFIED_TIMESTAMP = 'lastModifiedTimestamp',

  // Observations section
  OBSERVATIONS_COMMENTS = 'obsComments',
  // Extra
  PARAMETERS = 'parameters',
  SECURITIES = 'securities'
}

export enum GroupSecuritiesCreditLimitsProps {
  TOTAL_CREDIT_LIMIT = 'totalCreditLimit',
  TOTAL_CREDIT_LIMIT_CURRENCY = 'totalCreditLimitCurrency',
  BANK_GUARANTEE_AMOUNT = 'bankGuaranteeAmount',
  BANK_GUARANTEE_CURRENCY = 'bankGuaranteeCurrency',
  FD_INSURANCE_AMOUNT = 'fdInsuranceAmount',
  FD_INSURANCE_CURRENCY = 'fdInsuranceCurrency',
  DISTRIBUTORS = 'distributors'
}

export enum DistributorManagerTestIds {
  HEADER = 'distributor-manager__header',
  FILTER_TOOLBAR = 'distributor-manager__filter-toolbar',
  TABLE = 'distributor-manager__table'
}

export enum DistributorDetailsProps {
  DISTRIBUTOR_GROUP = 'group',
  OPERATIONAL_CL = 'operationalCl',
  AVAILABLE_CL = 'availableCl',
  LAST_MODIFIED_DATE = 'lastModifiedDate'
}

export enum GroupDetailsProps {
  DISTRIBUTOR_GROUP = 'group',
  LAST_MODIFIED_DATE = 'lastModifiedTimestamp',
  OPERATING_WITH_SWING_LINES = 'linesSwingEnabled'
}

export enum DistributorManagerAction {
  MODIFY = 'modify',
  ARCHIVE = 'archive'
}

export enum TagPosition {
  LEFT = 'left',
  RIGHT = 'right'
}
